import React from 'react'

export type MessageType = {
  role: string
  content: string
}

type Props = {
  value: MessageType
  onChange: (value: MessageType) => void
}

const MessageEditor = ({ value, onChange }: Props) => (
  <div>
    <div>Role: {value.role}</div>
    <textarea
      className='w-100 p-2'
      value={value.content}
      onChange={e => onChange({ ...value, content: e.target.value })}
      rows={10}
    />
  </div>
)

export default MessageEditor
