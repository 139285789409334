import React, {
  useRef,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import Tree from 'react-d3-tree'

import { LoadingContainer } from 'components/common/loadingContainer'

import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import Toolbar from 'components/org_chart/toolbar'
import CustomSVGNode from 'components/org_chart/customSVGNode'
import ProfilePreview from 'components/org_chart/profilePreview'
import {
  isOrgChartNodeHighlighted,
  findNodeWithUsername,
  useCenteredTree,
  reorderHighlightedLines
} from 'utils/orgChart'
import ToggleLeftNavButton from 'components/common/toggleLeftNavButton'
import orgChartSlice from 'redux/slices/orgChart'
import { trackEvent } from 'services/tracker'
import MobileShowPreviewButton from 'components/org_chart/mobileShowPreviewButton'
import useWindowResize from 'components/common/hooks/useWindowResize'
import usePrintableOrgChart from './usePrintableOrgChart'

const ZOOM_LEVEL_EXTREMES = { max: 1.8, min: 0.2 }

const OrgChartPage = () => {
  const { username } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const { isLoading: isLoadingOrgChart, isLoadingUsers } = useSelector(orgChartSlice.selectors.getMetaData())
  const originalOrgChart = useSelector(orgChartSlice.selectors.getOrgChart())
  const trimmedOrgChart = useSelector(orgChartSlice.selectors.getOrgChartForUser())
  const [orgChart, setOrgChart] = useState([])
  const [isOrientationVertical, setIsOrientationVertical] = useState(false)
  const [showSelectedUserAsRoot, setShowSelectedUserAsRoot] = useState(false)
  const { isSmallScreen } = useWindowResize()
  const [isProfilePreviewOpen, setIsProfilePreviewOpen] = useState(!isSmallScreen)
  const [translate, dimensions, treeContainerRef] = useCenteredTree()
  const selectedNodeRef = useRef()
  const user = useSelector(orgChartSlice.selectors.getProfilePreviewUser()) ?? {}
  const isLoading = isLoadingOrgChart || isLoadingUsers || _.isEmpty(orgChart)
  const currentCompany = useCurrentCompany()

  // these numbers are based on the org chart node size in the designs, in pixels
  const nodeSizeVertical = { x: 147, y: 147 }
  const nodeSizeHorizontal = { x: 234, y: 74 }
  const updatePrintStyle = usePrintableOrgChart(nodeSizeHorizontal, nodeSizeVertical)

  // aside from the node size, we need to also declare the empty space around it: the footprint.
  const nodeSizeFootprintVertical = { x: nodeSizeVertical.x + 16, y: nodeSizeVertical.y + 70 }
  const nodeSizeFootprintHorizontal = { x: nodeSizeHorizontal.x + 82, y: nodeSizeHorizontal.y + 10 }

  // when rendering a custom node (like we do), we need to tell the library how to draw it
  // using foreignObjectProps
  const nodeSize = isOrientationVertical ? nodeSizeVertical : nodeSizeHorizontal
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: (nodeSize.x / 2) * -1,
    y: (nodeSize.y / 2) * -1,
  }

  const getDynamicPathClass = ({ source, target }, orientation) => (
    isOrgChartNodeHighlighted(target, username) ? 'link-active' : ''
  )

  const getProfile = username => dispatch(orgChartSlice.asyncActions.fetchUserForProfilePreview(username))

  const handleNodeChange = (username) => {
    getProfile(username)
    history.push(`/people/orgchart/${username}`)

    // anytime a node is clicked, we need to update the print style
    // and the timeout refers to the animation duration
    setTimeout(() => {
      updatePrintStyle(isOrientationVertical ? 'vertical' : 'horizontal')
    }, 900)
  }

  const onClickCard = (node) => {
    handleNodeChange(node.data?.u)
  }

  const resetView = () => {
    // hard refresh the page at the main org chart, resetting everything
    location.assign('/people/orgchart/')
  }

  const redirectToRootNode = () => {
    const rootUsername = originalOrgChart[0].u
    handleNodeChange(rootUsername)
    dispatch(orgChartSlice.actions.trimOrgChartForUser(rootUsername))
  }

  // when the orientation changes, we need to update the print style
  // and the timeout refers to the animation duration
  useEffect(() => {
    setTimeout(() => {
      updatePrintStyle(isOrientationVertical ? 'vertical' : 'horizontal')
    }, 900)
  }, [isOrientationVertical])

  useEffect(() => {
    if (username) {
      const fetchData = async () => {
        await dispatch(orgChartSlice.asyncActions.fetchUsersForOrgChart(username))
        await dispatch(orgChartSlice.asyncActions.fetchOrgChart(
          () => dispatch(orgChartSlice.actions.trimOrgChartForUser(username))
        ))
      }
      fetchData()
    } else {
      dispatch(orgChartSlice.asyncActions.fetchOrgChart())
    }

    return () => {
      dispatch(orgChartSlice.actions.resetPreviewUserId())
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(originalOrgChart)) {
      if (_.isEmpty(username)) {
        redirectToRootNode()
      } else {
        handleNodeChange(username)
      }
    }
  }, [originalOrgChart])

  useEffect(() => {
    if (!_.isEmpty(_.compact(trimmedOrgChart))) {
      setOrgChart(trimmedOrgChart)
    } else if (!_.isEmpty(originalOrgChart)) {
      redirectToRootNode()
    }
  }, [trimmedOrgChart])

  useEffect(() => {
    if (showSelectedUserAsRoot) {
      const selectedNode = findNodeWithUsername(orgChart[0], username)
      setOrgChart([selectedNode])
      setShowSelectedUserAsRoot(false)
      setTimeout(() => selectedNodeRef?.current?.click())
    }
  }, [showSelectedUserAsRoot])

  useEffect(() => {
    if (!isLoading && selectedNodeRef.current) {
      // click on the selected node in order to center it
      // this enables us to center the node when clicking "view on org chart"
      selectedNodeRef.current.click()
      reorderHighlightedLines()
    }
  }, [!!selectedNodeRef.current, isLoading])

  useEffect(() => {
    trackEvent('org_chart:view')
  }, [])

  useLayoutEffect(() => {
    reorderHighlightedLines()
  }, [username])

  const addChildrenToOrgChart = (node, callback) => {
    const clone = _.cloneDeep(orgChart)
    const newNode = findNodeWithUsername(clone[0], node.u)
    const oldNode = findNodeWithUsername(originalOrgChart[0], node.u)
    newNode.children = oldNode.children.map(oc => ({ ...oc, children: [] }))
    setOrgChart(clone)
    callback()
  }

  const findNodeChildren = (nodeDatum) => {
    const oldNode = findNodeWithUsername(originalOrgChart[0], nodeDatum.u)

    return oldNode?.children || []
  }

  return (
    <div className='OrgChartPage'>
      <Toolbar
        isOrientationVertical={isOrientationVertical}
        setIsOrientationVertical={setIsOrientationVertical}
        showSelectedUserAsRoot={showSelectedUserAsRoot}
        setShowSelectedUserAsRoot={setShowSelectedUserAsRoot}
        resetView={resetView}
      />
      <main className='position-relative h-100'>
        {!isLoading && (
          <ToggleLeftNavButton
            onClick={() => setIsProfilePreviewOpen(!isProfilePreviewOpen)}
            isOpen={isProfilePreviewOpen}
          />
        )}
        <div className='d-flex flex-nowrap justify-content-center'>
          <LoadingContainer shouldRemount isLoading={isLoading} fadeIn={false} useCirclesLoadingIndicator>
            <>
              <ProfilePreview
                user={user}
                currentCompany={currentCompany}
                isProfilePreviewOpen={isProfilePreviewOpen}
                setIsProfilePreviewOpen={setIsProfilePreviewOpen}
              />
              <div
                ref={treeContainerRef}
                className={classNames('OrgChartContainer d-flex', { isProfilePreviewOpen })}
              >
                <MobileShowPreviewButton
                  setIsProfilePreviewOpen={setIsProfilePreviewOpen}
                  user={user}
                />
                <Tree
                  dataKey={`orgChart-${orgChart[0]?.i}`}
                  data={orgChart}
                  dimensions={dimensions}
                  easingFunc='ease-in-out'
                  enableLegacyTransitions
                  nodeSize={isOrientationVertical ? nodeSizeFootprintVertical : nodeSizeFootprintHorizontal}
                  onNodeClick={onClickCard}
                  orientation={isOrientationVertical ? 'vertical' : 'horizontal'}
                  pathFunc='step'
                  pathClassFunc={getDynamicPathClass}
                  renderCustomNodeElement={rd3tProps => (
                    <CustomSVGNode
                      {...rd3tProps}
                      foreignObjectProps={foreignObjectProps}
                      username={username}
                      isOrientationVertical={isOrientationVertical}
                      nodeRef={selectedNodeRef}
                      addChildrenToOrgChart={addChildrenToOrgChart}
                      findNodeChildren={findNodeChildren}
                    />
                  )}
                  scaleExtent={ZOOM_LEVEL_EXTREMES}
                  transitionDuration={250}
                  translate={translate}
                  initialDepth={1}
                  zoomable
                />
              </div>
            </>
          </LoadingContainer>
        </div>
      </main>
    </div>
  )
}

export default OrgChartPage
