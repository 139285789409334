import React, { useState } from 'react'

import useGlobalStateBucket from 'components/common/hooks/useGlobalStateBucket'
import { Creatable } from 'components/common/react_select'
import API from 'services/api'
import { TicketType } from 'types/ticketing/ticket'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ticketing.view_ticket_sidebar')

interface TicketTagsProps {
  ticket: TicketType
  updateTicket: (params: any) => void
  isAdmin?: boolean
}

const TicketTags = ({
  ticket,
  updateTicket,
  isAdmin = false,
} : TicketTagsProps) => {
  const { data: allTags } = useGlobalStateBucket(API.admin.ticketing.tags.fetchAll, 'ticketingTags')
  const [ticketTags, setTicketTags] = useState(ticket.tags)

  const handleUpdateTicket = () => {
    const currentTags = ticketTags.map(tag => _.pick(tag, ['id', 'name']))
    const originalTags = ticket.tags.map(tag => _.pick(tag, ['id', 'name']))

    if (!_.isEqual(currentTags, originalTags) && updateTicket) {
      updateTicket({ id: ticket.id, tags: ticketTags })
    }
  }

  return (
    <div className='TicketTags'>
      <div className='text-small font-weight-600 mb-3'>{I18N('tags')}</div>
      <Creatable
        isMulti
        isClearable
        value={ticketTags}
        options={allTags}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        onChange={options => setTicketTags(options)}
        onBlur={handleUpdateTicket}
        getNewOptionData={(inputValue, optionLabel) => ({
          name: optionLabel,
          id: `new-${inputValue}`,
          isNew: true,
        })}
        isDisabled={!isAdmin}
      />
    </div>
  )
}

export default TicketTags
