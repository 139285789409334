import FormEditableField from 'components/form_fields/formEditableField'
import FormSelectField from 'components/form_fields/formSelectField'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.ticketing.policy_select')

const POLICY_OPTIONS = [
  { label: I18N('policy_options.always'), value: 'always' },
  { label: I18N('policy_options.matches_rule'), value: 'matches_rule' },
  { label: I18N('policy_options.never'), value: 'never' },
]

type Props = {
  policy: string
  setPolicy: (policy: string) => void
  rule: string
  setRule: (rule: string) => void
}

const TicketEscalationPolicySelect = ({
  policy,
  setPolicy,
  rule,
  setRule,
}: Props) => (
  <>
    <FormSelectField
      label={I18N('policy')}
      options={POLICY_OPTIONS}
      currentValue={POLICY_OPTIONS.find(opt => opt.value === policy)}
      onChange={option => setPolicy(option.value)}
    />

    {policy === 'matches_rule' && (
      <FormEditableField
        fieldName='rule'
        label={I18N('rule')}
        currentValue={rule}
        onChange={e => setRule(e.target.value)}
        textarea
      />
    )}
  </>
)

export default TicketEscalationPolicySelect
