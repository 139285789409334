import React from 'react'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import { SMALL_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'

const SimpleClearyRichTextField = ({ onChange, value }) => (
  <TiptapEditor
    className='SimpleClearyRichTextField bordered'
    configuration={{
      ...SIMPLE_EDITOR_CONFIGURATION,
      placeholder: 'The PDF template text. You can use double curly braces to insert variables. For example, {{variable_name}}',
    }}
    toolbarItems={SMALL_TOOLBAR}
    onChange={onChange}
    html={value}
  />
)

export default SimpleClearyRichTextField
