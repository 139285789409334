import React, { useEffect } from 'react'
import { I18NCommon } from 'utils/i18nHelpers'
import { ButtonNarrow } from 'components/common/buttons'
import fieldFactory from './fieldFactory'

const ArrayField = ({
  onChange, value = [], item,
}: { onChange: (value: any[]) => void, value: any[], item: any }) => {
  const Field = fieldFactory(item.type)

  const buildItem = (item) => {
    if (item.type === 'object') {
      return Object.keys(item.properties).reduce((acc, property) => {
        acc[property] = buildItem(item.properties[property])

        return acc
      }, {})
    } else if (item.type === 'text') {
      return ''
    }

    return null
  }

  useEffect(() => {
    if (value.length === 0) {
      onChange([buildItem(item)])
    }
  }, [])

  return (
    <>
      {value.map((innerValue, index) => (
        <div className='d-flex align-items-center gap-2 mb-2' key={index}>
          <Field
            value={innerValue}
            onChange={innerValue => onChange([...value.slice(0, index), innerValue, ...value.slice(index + 1)])}
            {...item}
          />
          <ButtonNarrow
            variant='secondary'
            onClick={() => onChange([...value.slice(0, index), ...value.slice(index + 1)])}
          >
            {I18NCommon('remove')}
          </ButtonNarrow>
        </div>
      ))}

      <ButtonNarrow
        variant='secondary'
        onClick={() => onChange([...value, buildItem(item)])}
      >
        {I18NCommon('add')}
      </ButtonNarrow>
    </>
  )
}

export default ArrayField
