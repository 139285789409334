import React, { useContext, useState } from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { UserType } from 'types/user'
import DatePicker from 'components/form_fields/datePicker'
import moment from 'moment'
import { TicketOriginEnum, TicketType } from 'types/ticketing/ticket'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { Button, ButtonNarrow } from 'components/common/buttons'
import TicketActivity from 'components/ticketing/view_ticket_sidebar/ticketActivity'
import AssigneeSelect from 'components/admin/ticketing/assigneeSelect'
import TicketDropdown from 'components/ticketing/view_ticket_sidebar/ticketDropdown'
import TicketingContext from 'components/ticketing/ticketingContext'
import AdminTicketStatusSelect from 'components/admin/ticketing/adminTicketStatusSelect'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import UserLink from 'components/common/userLink'
import CdnSvg from 'components/common/cdnSvg'
import ChatUsername from 'components/common/chatUsername'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import InfoTooltip from 'components/common/infoTooltip'
import TicketTags from 'components/ticketing/view_ticket_sidebar/ticketTags'
import UserOrUnassigned from 'components/ticketing/userOrUnassigned'

type Props = {
  ticket: TicketType
}

const I18N = i18nPath('views.ticketing.view_ticket_sidebar')

const UserInfo = ({ user }) => (
  <div className='d-flex gap-3 mb-2'>
    <div>
      <EmployeeThumbnailPhoto employee={user} linkToProfile size='44px' />
    </div>

    <div>
      <UserLink
        user={user}
        hideImage
        customClassName='text-primary'
      />
      <div className='text-secondary text-small'>
        {user?.title}
      </div>
      <a
        className='d-flex gap-1 align-items-center'
        href={`mailto:${user?.email}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        <CdnSvg src='/images/envelopeOutlineIcon.svg' className='MessagingIcon d-flex align-items-center justify-content-center' />
        <span className='font-weight-400 text-small text-primary'>{user?.email}</span>
      </a>

      {user?.primaryChatUsers?.map(chatUser => (
        <div key={chatUser.id}>
          <ChatUsername
            chatUser={chatUser}
            iconClass='MessagingIcon'
            className='text-small font-weight-400'
          />
        </div>
      ))}
    </div>
  </div>
)

const EditableTicketDetailsPanel = ({ ticket }: Props) => {
  const { fetchCounts } = useContext(TicketingContext)
  const [isChangingEmployee, setIsChangingEmployee] = useState(false)

  const [updateTicket, { isLoading: isUpdating }] = useApi(API.admin.ticketing.tickets.update, {
    updateEntitySlice: true,
    toastSuccessMessage: I18N('ticket_successfully_saved'),
    toastErrorMessage: I18NCommon('generic_error'),
  })

  const [closeTicket, { isLoading: isClosing }] = useApi(API.admin.ticketing.tickets.close, {
    onSuccess: fetchCounts,
    updateEntitySlice: true,
    toastSuccessMessage: I18N('ticket_closed'),
    toastErrorMessage: I18NCommon('generic_error'),
  })

  const [reopenTicket, { isLoading: isReopening }] = useApi(API.admin.ticketing.tickets.reopen, {
    onSuccess: fetchCounts,
    updateEntitySlice: true,
    toastSuccessMessage: I18N('ticket_reopened'),
    toastErrorMessage: I18NCommon('generic_error'),
  })

  const [updateEmployee, { isLoading: isUpdatingEmployee }] = useApi(API.admin.ticketing.tickets.updateEmployee, {
    onSuccess: () => setIsChangingEmployee(false),
    updateEntitySlice: true,
    toastSuccessMessage: I18N('ticket_employee_updated'),
    toastErrorMessage: I18NCommon('generic_error'),
  })

  const isClosed = ticket.status === 'closed'
  const user = ticket.user as UserType

  return (
    <>
      <div className='TicketRequester'>
        <div className='text-small font-weight-600 mb-3'>{I18N('requester_details')}</div>
        <div className='d-flex gap-3 mb-2'>
          <div>
            <EmployeeThumbnailPhoto employee={user} linkToProfile size='44px' />
          </div>

          <div>
            <UserLink
              user={user}
              hideImage
              customClassName='text-primary'
              unknownUserText={ticket.requesterDetails?.name || I18NCommon('unknown_user')}
            />
            <div className='text-secondary text-small'>
              {user?.title}
            </div>
            <a
              className='d-flex gap-1 align-items-center'
              href={`mailto:${user?.email || ticket.requesterDetails?.email}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <CdnSvg src='/images/envelopeOutlineIcon.svg' className='MessagingIcon d-flex align-items-center justify-content-center' />
              <span className='font-weight-400 text-small text-primary'>{user?.email || ticket.requesterDetails?.email}</span>
            </a>

            {user?.primaryChatUsers?.map(chatUser => (
              <div key={chatUser.id}>
                <ChatUsername
                  chatUser={chatUser}
                  iconClass='MessagingIcon'
                  className='text-small font-weight-400'
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <hr className='w-100' />
      {!user && (
        <>
          <div className='TicketEmployee'>
            <div className='d-flex align-items-center gap-1 mb-3'>
              <div className='text-small font-weight-600'>{I18N('employee_details')}</div>
              <InfoTooltip text={I18N('employee_details_tooltip')} />
            </div>

            {(!ticket.employee || isChangingEmployee) ? (
              <>
                <label className='text-small text-secondary'>{I18N('employee')}</label>
                <EmployeeSearch
                  selectedEmployee={ticket.employee}
                  onChange={(employee) => {
                    if (employee) {
                      updateEmployee(ticket.id, employee.id)
                    }
                  }}
                  placeholder={I18NCommon('select_employee')}
                  isDisabled={isClosed}
                />
              </>
            ) : (
              <>
                <UserInfo user={ticket.employee} />
                <Button
                  variant='secondary'
                  className='mt-2'
                  size='sm'
                  onClick={() => setIsChangingEmployee(true)}
                >
                  {I18NCommon('change')}
                </Button>
              </>
            )}
          </div>
          <hr className='w-100' />
        </>
      )}
      <div className='overflow-y-auto'>
        <div className='TicketDetails position-relative d-flex flex-column gap-3'>
          <TicketDropdown ticket={ticket} isAdmin className='TicketDropdown position-absolute' />
          <div className='text-small font-weight-600 mb-2'>{I18N('ticket_details')}</div>
          <div>
            <label className='text-small text-secondary'>{I18N('assigned_to')}</label>
            {ticket.origin === TicketOriginEnum.Integration ? (
              <div>
                <UserOrUnassigned ticket={ticket} fontWeight='500' />
              </div>
            ) : (
              <AssigneeSelect ticket={ticket} />
            )}
          </div>
          <div>
            <label className='text-small text-secondary'>{I18N('status')}</label>
            <AdminTicketStatusSelect ticket={ticket} />
          </div>
          <div>
            <label className='text-small text-secondary'>{I18N('id')}</label>
            <div>#{ticket.number}</div>
          </div>
          <div>
            <label className='text-small text-secondary'>{I18N('due_date')}</label>
            {isUpdating ? (
              <CirclesLoadingIndicator />
            ) : (
              <DatePicker
                selected={ticket.dueDate ? moment(ticket.dueDate) : undefined}
                onChange={date => updateTicket({ id: ticket.id, dueDate: date?.toISOString() || null })}
                className='w-100'
                isDisabled={isClosed}
              />
            )}
          </div>
          <div>
            {isClosing || isReopening ? (
              <CirclesLoadingIndicator />
            ) : (
              <ButtonNarrow
                onClick={isClosed ? () => reopenTicket(ticket.id) : () => closeTicket(ticket.id)}
                variant={isClosed ? 'secondary' : 'secondary-danger'}
              >
                {isClosed ? I18N('reopen_ticket') : I18N('close_ticket')}
              </ButtonNarrow>
            )}
          </div>
        </div>
        <hr />
        <TicketTags ticket={ticket} updateTicket={updateTicket} isAdmin />
        <hr />
        <TicketActivity ticket={ticket} isAdmin />
        <hr />
      </div>
    </>
  )
}

export default EditableTicketDetailsPanel
