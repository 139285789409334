/* eslint-disable import/prefer-default-export */

export function present<T>(x: T | false | null | undefined): x is T {
  return !!x
}

export const numberPresent = (x: number | boolean | null | undefined): x is number => typeof x === 'number'

export const randomReactKey = () => Math.random().toString(36).substr(2, 6)

export const extractHighlights = (htmlStrings: any[]) => {
  const searchHighlights = htmlStrings.flatMap(string => extractTagTextFromString('em', string))

  return { searchHighlights }
}

export const extractTagTextFromString = (tag: string, htmlString: string) => {
  if (typeof htmlString !== 'string') {
    return []
  }

  const regex = new RegExp(`<${tag}>(.*?)</${tag}>`, 'gi')
  const matches: any[] = []
  let match

  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(htmlString)) !== null) {
    matches.push(match[1])
  }

  return matches
}

/**
 * @param inputString - any string or html string that has to be highlighted with <span class="searchHighlight"></span>
 * @param occurrences - array of strings that have to be highlighted
 * @returns - string with occurrences highlighted
 *
 * Example:
 * encloseInSearchHighlights('This is a test string', ['test'])
 * returns '<span>This is a <span class="searchHighlight">test</span> string</span>'
 */
export const encloseInSearchHighlights = (inputString: string, occurrences: string[]) => {
  if (!inputString) { return '' }
  if (!occurrences?.length) { return inputString }

  const escapeRegExp = string => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  const replaceNodeOccurrence = (node, occurrence) => {
    if (node.nodeType === Node.TEXT_NODE && node.textContent.toLowerCase().includes(occurrence.toLowerCase())) {
      const text = node.textContent.replace(
        new RegExp(`(${escapeRegExp(occurrence)})`, 'gi'),
        '<span class="searchHighlight">$1</span>'
      )

      const span = document.createElement('span')
      span.innerHTML = text
      node.parentNode.replaceChild(span, node)
    } else {
      node.childNodes.forEach((child) => {
        replaceNodeOccurrence(child, occurrence)
      })
    }
  }

  const div = document.createElement('div')
  div.innerHTML = inputString

  const uniqueOcurrences = _.uniq(occurrences.map(o => o.toLowerCase()))

  _.orderBy(uniqueOcurrences, ['length'], ['desc']).forEach((occurrence) => {
    replaceNodeOccurrence(div, occurrence)
  })

  return div.innerHTML
}

export const formatFloat = (value: number, decimals = 2) => {
  const formatted = value.toFixed(decimals)
  return formatted.replace(/\.?0+$/, '') // remove trailing zeros
}
