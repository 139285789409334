import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { i18nPath } from 'utils/i18nHelpers'
import difference from 'utils/deepDiff'
import transformToCssVariable from 'utils/transformToCssVariable'
import customStylesSlice from 'redux/slices/customStyles'
import { ThemeStyles, DynamicStyles } from 'components/appColorFunctions'
import { present } from 'components/common/utils'
import LoadingSpinner from 'components/common/loadingSpinner'
import { Button } from 'components/common/buttons'
import CustomStyles from 'pages/admin/custom_styles/context'
import NavigationBlocker from 'components/common/navigationBlocker'
import NavigationSection from 'components/admin/customStyles/navigationSection'
import ColorsSection from 'components/admin/customStyles/colorsSection'
import TypographySection from 'components/admin/customStyles/typographySection'
import FeedSection from 'components/admin/customStyles/feedSection'
import ComponentsSection from 'components/admin/customStyles/componentsSection'
import CustomCssSection from 'components/admin/customStyles/customCssSection'
import JsonSection from 'components/admin/customStyles/jsonSection'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import navigationBlockerSlice from 'redux/slices/navigationBlocker'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'


const NAVIGATION = 'navigation'
const COLORS = 'colors'
const TYPOGRAPHY = 'typography'
const FEED = 'feed'
const COMPONENTS = 'components'
const CUSTOM_CSS = 'custom_css'
const JSON_DATA = 'json_data'

const I18N = i18nPath('views.custom_styles')

const CustomStylesPage = () => {
  const dispatch = useDispatch()
  const styles = useSelector(state => state.styles)
  const customStyles = useSelector(state => state.customStyles)
  const isLoading = customStyles.meta.isLoading
  const { section } = useParams()
  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const rootElement = document.documentElement

  const wereChangesMade = () => {
    const { initialValues, workingCopy } = customStyles
    const updatedFields = difference(workingCopy, initialValues)

    return !_.isNull(initialValues)
      && !_.isNull(workingCopy)
      && Object.keys(updatedFields).length > 0
  }

  useEffect(() => {
    dispatch(customStylesSlice.asyncActions.loadCustomStyles())

    return resetWorkingCopy
  }, [])

  useEffect(() => {
    if (wereChangesMade()) resetWorkingCopy()
  }, [section])

  const resetWorkingCopy = () => {
    const { cssOverrides } = styles
    const { initialValues } = customStyles

    const defaultWorkingCopy = {
      variables: initialValues.variables,
      cssOverrides,
    }

    ThemeStyles.init(defaultWorkingCopy.variables).then(DynamicStyles.init)
    dispatch(customStylesSlice.actions.updateWorkingCopy(defaultWorkingCopy))
  }

  const handleRootElPropertyChange = (propertyType, propertyName, value) => {
    const {
      workingCopy: { cssOverrides, variables = {} },
    } = customStyles

    const currentValues = variables[propertyType]

    const newWorkingCopy = {
      variables: {
        ...variables,
        [propertyType]: {
          ...currentValues,
          [propertyName]: value,
        },
      },
      cssOverrides,
    }

    rootElement.style.setProperty(transformToCssVariable(propertyName), value)
    dispatch(customStylesSlice.actions.updateWorkingCopy(newWorkingCopy))
    DynamicStyles.init()
  }

  const handleOnClick = () => {
    const { workingCopy } = customStyles
    const { faviconUrl, ...newCustomStyles } = workingCopy
    const company = { ...currentCompany, customStyles: newCustomStyles, faviconUrl }

    dispatch(customStylesSlice.asyncActions.updateCustomStyles(company)).then(() => {
      dispatch(navigationBlockerSlice.asyncActions.performWithoutBlocking(() => {
        window.location.reload()
      }))
    })
  }

  const isClearyAdmin = !!currentUser.permissions.clearyAdmin

  const tabs = [
    { id: NAVIGATION, text: I18N('navigation') },
    { id: COLORS, text: I18N('colors') },
    { id: TYPOGRAPHY, text: I18N('typography') },
    { id: FEED, text: I18N('feed') },
    { id: COMPONENTS, text: I18N('components') },
    isClearyAdmin && { id: CUSTOM_CSS, text: I18N('custom_css') },
    isClearyAdmin && { id: JSON_DATA, text: I18N('json') },
  ].filter(present)

  const customStylesValue = {
    workingCopy: customStyles.workingCopy,
    handlePropertyChanged: handleRootElPropertyChange,
  }

  const renderSection = () => {
    switch (section) {
    case 'navigation':
      return <NavigationSection />
    case 'colors':
      return <ColorsSection />
    case 'typography':
      return <TypographySection />
    case 'feed':
      return <FeedSection />
    case 'components':
      return <ComponentsSection />
    case 'custom_css':
      return <CustomCssSection />
    case 'json_data':
      return <JsonSection />
    default:
      return null
    }
  }

  return (
    <CustomStyles.Provider value={customStylesValue}>
      <NavigationBlocker isBlocked={wereChangesMade()} />

      {isLoading && <LoadingSpinner />}

      <header className='AdminHeader'>
        <h3>{I18N('branding')}</h3>
      </header>

      <main className='AdminContent CustomStyles'>

        <div className='row'>
          <div className='col-12'>
            {wereChangesMade() && (
              <div className='alert alert-warning text-center'>{I18N('unsaved_changes_warning')}</div>
            )}
          </div>
        </div>

        <HistoryChangingPillTabSelector
          baseUrl='/admin/branding'
          historyKeyToChange='pathname'
          tabs={tabs}
          className='mb-3'
          paramName='section'
        />
        {renderSection()}
        <div className='row my-4'>
          <div className='col-12'>
            <Button
              className='float-right'
              onClick={handleOnClick}
              disabled={isLoading || !wereChangesMade()}
            >
              {I18N('save')}
            </Button>
          </div>
        </div>
      </main>
    </CustomStyles.Provider>
  )
}

export default CustomStylesPage
