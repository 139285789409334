import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

export const NEW_UI_USER_PREVIEW = 'new_ui.user_preview'
export const NEW_UI_TEMP_PREVIEW = 'new_ui.temp_preview'

const useNewUiDesign = (): boolean | null => {
  const currentCompany = useCurrentCompany()
  const currentUser = useCurrentUser()

  const tempPreview = sessionStorage.getItem(NEW_UI_TEMP_PREVIEW) === 'true'
  if (tempPreview) {
    return true
  }

  // If company-wide new UI is enabled, use it for everyone
  if (currentCompany?.settings?.newUi?.enabled) {
    return true
  }

  // If company allows user preview and user has opted in, use new UI
  if (currentCompany?.settings?.newUi?.allowUserPreview && currentUser?.previewNewUiEnabled) {
    return true
  }

  return false
}

export default useNewUiDesign
