import React, { useState } from 'react'

import { ButtonSecondarySmall } from 'components/common/buttons'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import EditableContent from 'components/common/editable_content'
import useApi from 'components/common/hooks/useApi'
import useFetch from 'components/common/hooks/useFetch'
import SmartTable from 'components/common/tables/smartTable'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { TagType } from 'types/article/tag'


const I18N = i18nPath('views.common.tag_settings')

const TagSettings = ({
  baseEndpoint,
}) => {
  const [tags, setTags] = useState<TagType[]>([])
  const [newTagName, setNewTagName] = useState('')

  const { isLoading } = useFetch(() => baseEndpoint.fetchAll(), [], { onSuccess: setTags })

  const [createTag, { isLoading: isCreatingTag }] = useApi(baseEndpoint.create, {
    onSuccess: data => setTags([...tags, data]),
    toastSuccessMessage: I18NCommon('created_successfully'),
  })
  const [updateTag] = useApi(baseEndpoint.update, {
    onSuccess: data => setTags(tags.map(t => (t.id === data.id ? data : t))),
    toastSuccessMessage: I18NCommon('updated_successfully'),
  })
  const [deleteTag] = useApi(baseEndpoint.destroy, {
    toastSuccessMessage: I18NCommon('deleted_successfully'),
  })

  const onCreateTag = () => {
    createTag({ name: newTagName })
    setNewTagName('')
  }

  const onDeleteTag = (tag: TagType) => {
    deleteTag(tag)
    setTags(tags.filter(t => t.id !== tag.id))
  }

  const columns = [
    {
      header: I18N('name'),
      accessor: d => (
        <EditableContent
          canEdit
          saveContent={name => updateTag({ id: d.id, name })}
          displayElement={<span className='text-primary'>{d?.name}</span>}
          valueToEdit={d?.name}
          inputElement={<input type='text' />}
        />
      ),
    },
    {
      header: I18N('delete'),
      accessor: d => (
        <ButtonSecondarySmall onClick={() => onDeleteTag(d)} value={d?.name}>
          {I18N('delete_tag')}
        </ButtonSecondarySmall>
      ),
    },
  ]

  if (isLoading) return <CirclesLoadingIndicator />

  return (
    <div>
      <div className='d-flex align-items-center mb-3'>
        <input
          type='text'
          data-testid='cy_tags_create_input'
          className='mr-2'
          value={newTagName}
          onChange={e => setNewTagName(e.target.value)}
          placeholder={I18N('add_tag_placeholder')}
        />

        <ButtonSecondarySmall
          onClick={onCreateTag}
          disabled={_.isEmpty(newTagName) || isCreatingTag}
          showLoadingSpinner={isCreatingTag}
        >
          {I18N('add_tag')}
        </ButtonSecondarySmall>
      </div>

      <SmartTable
        className='white-bg-table'
        data={tags}
        columns={columns}
      />
    </div>
  )
}

export default TagSettings
