import React from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import useQueryParams from 'components/common/hooks/useQueryParams'
import useSystemDefaultValues from 'pages/admin/user_settings/hooks/useSystemDefaultValues'
import UserSettingsCelebrationsTab from 'pages/admin/user_settings/userSettingsCelebrationsTab'
import UserSettingsEventsTab from 'pages/admin/user_settings/userSettingsEventsTab'
import UserSettingsExternalAlertsTab from 'pages/admin/user_settings/userSettingsExternalAlertsTab'
import UserSettingsPrivacyTab from 'pages/admin/user_settings/userSettingsPrivacyTab'

const I18N = i18nPath('views.settings.default_user_setting')

const CELEBRATIONS = 'celebrations'
const EVENTS = 'events'
const EXTERNAL_ALERTS = 'external_alerts'
const PRIVACY = 'privacy'

const UserSettingsPage = () => {
  const { selectedTab } = useQueryParams()
  const { data: defaultValues, isLoading } = useSystemDefaultValues()

  const tabs = [{
    id: CELEBRATIONS,
    text: I18NCommon('celebrations'),
  }, {
    id: EVENTS,
    text: I18NCommon('events'),
  }, {
    id: EXTERNAL_ALERTS,
    text: I18N('external_alerts'),
  }, {
    id: PRIVACY,
    text: I18N('privacy.title'),
  }]

  return (
    <div className='UserSettingsPage'>
      <header className='AdminHeader'>
        <h3>{I18N('title')}</h3>
      </header>

      {(isLoading || !defaultValues) ? (
        <CirclesLoadingIndicator />
      ) : (
        <main className='AdminContent'>
          <HistoryChangingPillTabSelector
            tabs={tabs}
            className='mb-3'
          />

          {selectedTab === CELEBRATIONS && <UserSettingsCelebrationsTab />}
          {selectedTab === EVENTS && <UserSettingsEventsTab />}
          {selectedTab === EXTERNAL_ALERTS && <UserSettingsExternalAlertsTab />}
          {selectedTab === PRIVACY && <UserSettingsPrivacyTab />}
        </main>
      )}
    </div>
  )
}

export default UserSettingsPage
