import React from 'react'

import API from 'services/api'
import TagSettings from 'components/common/tags/tagSettings'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.ticketing.settings.tags')

const TagsSection = () => (
  <div>
    <header className='AdminHeader bordered-bottom'>
      <h3>{I18N('title')}</h3>
    </header>

    <main className='AdminContent'>
      <TagSettings baseEndpoint={API.admin.ticketing.tags} />
    </main>
  </div>
)

export default TagsSection
