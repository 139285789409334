import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'

import { i18nPath } from 'utils/i18nHelpers'
import getJourneyBlueprintStatusColor from 'utils/admin/journeys/statusColors'
import getJourneyBlueprintAudienceText from 'utils/admin/journeys/audience'
import journeyBlueprintSlice from 'redux/slices/journeyBlueprints'
import { Button } from 'components/common/buttons'

import StatusLabel from 'components/common/status_label/'
import RoundedAvatarsWithAsyncUserFetching from 'components/common/roundedAvatarsWithAsyncUserFetching'
import EditableContent from 'components/common/editable_content'
import EditableContentTrigger from 'components/common/editable_content/editableContentTrigger'
import SettingsLinkButton from 'components/common/buttons/settingsLinkButton'
import { SimpleJourneyType } from 'types/journeys/journey'
import CreateStepTypeSelectModal from 'components/admin/journeys/modals/createStepTypeSelectModal'
import JourneyLaunchSuccessModal from 'components/admin/journeys/modals/journeyLaunchSuccessModal'
import AddEmployeesManuallyModal from 'components/admin/journeys/modals/addEmployeesManuallyModal'
import JourneyBlueprintSettingsModal from 'components/admin/journeys/modals/journeyBlueprintSettingsModal'
import JourneyBlueprintTargetingModal from 'components/admin/journeys/modals/journeyBlueprintTargetingModal'
import RemoveFromJourneyConfirmationModal from 'components/admin/journeys/modals/journeyBlueprintSettingsModal/removeFromJourneyConfirmationModal'
import type { SimpleUserType } from 'types/user'
import BackButton from 'components/common/backButton'

const AUDIENCE_MODAL_OPEN = 'audience_modal'
const NEW_STEP_MODAL_OPEN = 'new_step_modal'
const SUCCESS_MODAL_OPEN = 'success_modal'
const ADD_NEW_HIRES_MODAL_OPEN = 'add_new_hires_modal'
const SETTINGS_MODAL_OPEN = 'settings_modal_open'
const REMOVE_JOURNEY_MODAL_OPEN = 'remove_journey_modal'

const I18N = i18nPath('views.admin.journeys.journey_blueprints')

interface JourneyBlueprintHeaderProps {
  hideSettings: boolean,
  showEditJourneyButton: boolean,
  openModal: string,
  journeyToRemove?: SimpleJourneyType,
  setOpenModal: (newValue: string) => void,
  onCloseModal: () => void,
  onRemoveJourney: (journey: SimpleJourneyType) => void,
  currentUsers: SimpleUserType[],
  hasBackToTableButton: boolean,
}

const JourneyBlueprintHeader = ({
  hideSettings = false,
  showEditJourneyButton = false,
  openModal = '',
  journeyToRemove,
  setOpenModal = (newValue: string) => null,
  onCloseModal = () => null,
  onRemoveJourney = (journey: SimpleJourneyType) => null,
  currentUsers = [],
  hasBackToTableButton = false,
}: JourneyBlueprintHeaderProps) => {
  const dispatch = useDispatch()

  const { journeyBlueprintId } = useParams()
  const journeyBlueprint = useSelector(journeyBlueprintSlice.selectors.getJourneyBlueprint(journeyBlueprintId))
  const { isLoadingSimpleJourneys } = useSelector(journeyBlueprintSlice.selectors.getMetaData())
  const simpleJourneys = useSelector(journeyBlueprintSlice.selectors.getSimpleJourneys(journeyBlueprintId))
  const usersWithJourneys = simpleJourneys.map((item: SimpleJourneyType) => item.userId)

  const isJourneyBlueprintActive = journeyBlueprint?.state === 'active'
  const showProgressGuidance = !isJourneyBlueprintActive
  const howUsersAreAdded = journeyBlueprint?.howUsersAreAdded
  const hasJourneyLogic = howUsersAreAdded !== 'manually'
  const journeyBlueprintType = journeyBlueprint?.type

  const onSaveName = (name: string) => {
    const changes = { id: journeyBlueprint.id, name }

    return dispatch(journeyBlueprintSlice.asyncActions.admin.update(changes))
  }

  useEffect(() => {
    if (journeyBlueprint?.id) {
      dispatch(journeyBlueprintSlice.asyncActions.admin.fetchSimpleJourneys(journeyBlueprint?.id))
    }
  }, [journeyBlueprintId, isJourneyBlueprintActive])

  return (
    <header className='AdminHeader JourneyBlueprintHeader d-flex justify-content-between flex-column'>
      <BackButton
        backToText={hasBackToTableButton ? I18N('dashboard') : I18N('title')}
        url={hasBackToTableButton ? `/admin/journeys/${journeyBlueprintId}/table` : '/admin/journeys'}
        className='link-color mb-2 font-weight-normal'
      />

      <div className='flex-row-responsive-column w-100 mb-2'>
        <div className='d-flex align-items-center w-100'>
          <EditableContent
            canEdit
            displayElement={<h3 className='mb-0'>{journeyBlueprint?.name}</h3>}
            inputElement={<input />}
            valueToEdit={journeyBlueprint?.name}
            saveContent={onSaveName}
          />
          <StatusLabel
            className='ml-2 font-weight-500 text-normal'
            color={getJourneyBlueprintStatusColor(journeyBlueprint?.state)}
          >
            {_.capitalize(journeyBlueprint?.state)}
          </StatusLabel>
        </div>
        {showProgressGuidance && !hideSettings && (
          <SettingsLinkButton onClick={() => setOpenModal(SETTINGS_MODAL_OPEN)} />
        )}
      </div>
      <div className='my-1'>
        <Button
          variant='secondary'
          onClick={() => setOpenModal(ADD_NEW_HIRES_MODAL_OPEN)}
        >
          {I18N(`${journeyBlueprintType}.add_employees`)}
        </Button>
        <Button
          variant='secondary'
          className='ml-2'
          onClick={() => setOpenModal(AUDIENCE_MODAL_OPEN)}
        >
          {hasJourneyLogic
            ? I18N('edit_journey_logic')
            : I18N(`${journeyBlueprintType}.add_logic_based`)}
        </Button>
      </div>
      {hasJourneyLogic && (
        <div className='AudienceSummaryText text-small font-weight-400 mt-1'>
          <span className='mr-2'>{I18N('journey_logic')}</span>
          <EditableContentTrigger
            canEdit
            isEmpty={false}
            onClick={() => setOpenModal(AUDIENCE_MODAL_OPEN)}
            displayElement={(
              <span className='text-primary'>
                {getJourneyBlueprintAudienceText(journeyBlueprint)}
              </span>
            )}
          />
        </div>
      )}
      <div className='flex-row-responsive-column mt-1'>
        {
          isJourneyBlueprintActive && !isLoadingSimpleJourneys && (
            <div className='d-flex justify-content-start flex-row-responsive-column'>
              {usersWithJourneys.length > 0 && (
                <RoundedAvatarsWithAsyncUserFetching userIds={usersWithJourneys} linkTo='journeys' />
              )}
              <span className='hires-in-journey-container text-secondary font-weight-500 text-smallest'>
                {I18N(`${journeyBlueprintType}.employees_currently_on_this_journey`, { count: usersWithJourneys.length })}
              </span>
            </div>
          )
        }
        {showEditJourneyButton && !isLoadingSimpleJourneys && (
          <Link to={`/admin/journeys/${journeyBlueprint?.id}/edit`}>
            <Button>
              {I18N('edit_journey')}
            </Button>
          </Link>
        )}
        {!showProgressGuidance && !hideSettings && !isLoadingSimpleJourneys && (
          <SettingsLinkButton onClick={() => setOpenModal(SETTINGS_MODAL_OPEN)} />
        )}
      </div>
      <CreateStepTypeSelectModal
        visible={openModal === NEW_STEP_MODAL_OPEN}
        toggle={onCloseModal}
        journeyBlueprintId={journeyBlueprint?.id}
        journeyBlueprintType={journeyBlueprint?.type}
      />
      <JourneyLaunchSuccessModal
        toggle={onCloseModal}
        visible={openModal === SUCCESS_MODAL_OPEN}
        currentUsers={currentUsers}
      />
      <AddEmployeesManuallyModal
        journeyBlueprint={journeyBlueprint}
        visible={openModal === ADD_NEW_HIRES_MODAL_OPEN}
        onRemoveJourney={onRemoveJourney}
        toggle={onCloseModal}
      />
      {openModal === AUDIENCE_MODAL_OPEN && (
        <JourneyBlueprintTargetingModal
          journeyBlueprint={journeyBlueprint || {}}
          toggle={onCloseModal}
          visible={openModal === AUDIENCE_MODAL_OPEN}
        />
      )}
      {openModal === SETTINGS_MODAL_OPEN && (
        <JourneyBlueprintSettingsModal
          journeyBlueprint={journeyBlueprint || {}}
          onClose={onCloseModal}
        />
      )}
      <RemoveFromJourneyConfirmationModal
        visible={openModal === REMOVE_JOURNEY_MODAL_OPEN}
        toggle={onCloseModal}
        journeyToRemove={journeyToRemove}
      />
    </header>
  )
}

export default JourneyBlueprintHeader
