import { buildStepTemplatePayload } from 'redux/slices/journeys/stepTemplates'
import generateCrudRoutes from 'services/generateCrudRoutes'

export const MEETING_TEMPLATE_ATTRIBUTES = [
  'rangeStartTime',
  'rangeStartAfterNumDays',
  'rangeStartMilestone',
  'rangeEndTime',
  'rangeEndAfterNumDays',
  'rangeEndMilestone',
  'scheduleSpecificallyTime',
  'scheduleSpecificallyAfterNumDays',
  'scheduleSpecificallyMilestone',
  'meetingDuration',
  'calendarEventTitle',
  'calendarEventDescription',
  'hourWindowStart',
  'hourWindowEnd',
  'fallbackTimezone',
  'scheduleWhenAttendeesAvailable',
  'createVideoConference',
  'weekendScheduleSpecificallyShift',
  'externalCalendarId',
]

const buildMeetingTemplatePayload = (meetingTemplate) => {
  const meetingTemplateAttrs = _.pick(meetingTemplate, [...MEETING_TEMPLATE_ATTRIBUTES, 'id'])

  if (meetingTemplate.stepTemplateAttributes) {
    meetingTemplateAttrs.stepTemplateAttributes = buildStepTemplatePayload(
      meetingTemplate.stepTemplateAttributes
    )
  }

  return meetingTemplateAttrs
}

const meetingTemplates = generateCrudRoutes(
  '/api/admin/journey/meeting_templates',
  'meeting_template',
  {},
  buildMeetingTemplatePayload
)

export default meetingTemplates
