import React, { ReactElement } from 'react'
import { useCustomStyles, useVariables } from 'pages/admin/custom_styles/context'
import { sortArrayBy, getPropertyListWithValues } from 'components/admin/customStyles/utils'
import ColorItem from 'components/admin/customStyles/colorItem'
import BoxShadowSection from 'components/admin/customStyles/boxShadowSection'
import useNewUiDesign, { NEW_UI_TEMP_PREVIEW } from 'components/common/hooks/useNewUiDesign'
import { Button } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import Card from 'components/common/card'
import CompanyLogo from './companyLogo'
import TextTransformSection from './textTransformSection'
import FaviconUrlField from './faviconUrlField'

const I18N = i18nPath('views.custom_styles')

const NavigationSection = (): ReactElement => {
  const { handlePropertyChanged } = useCustomStyles()
  const colors = useVariables('colors')
  const colorVariables = getPropertyListWithValues({ variables: colors, type: 'colors' })

  const navVariables = [
    'navColor',
    'navTextColor',
    'navTextActiveColor',
    'leftNavbarBackgroundColor',
  ]

  const subNavVariables = ['subnavColor', 'subnavTextColor', 'subnavTextActiveColor']

  const newNavVariables = [
    'newUiNavColor',
    'topNavColor',
    'newUiNavTextColor',
    'topNavIconColor',
    'newUiNavTextActiveColor',
    'topNavIconActiveColor']

  const navSectionVariables = colorVariables.filter(([variableName]) => navVariables.includes(variableName))
  const subNavSectionVariables = colorVariables.filter(([variableName]) => subNavVariables.includes(variableName))
  const newNavSectionVariables = colorVariables.filter(([variableName]) => newNavVariables.includes(variableName))

  navSectionVariables.sort(sortArrayBy(navVariables))
  subNavSectionVariables.sort(sortArrayBy(subNavVariables))
  newNavSectionVariables.sort(sortArrayBy(newNavVariables))
  const setTemporaryPreview = (enabled: boolean): void => {
    if (enabled) {
      sessionStorage.setItem(NEW_UI_TEMP_PREVIEW, 'true')
    } else {
      sessionStorage.removeItem(NEW_UI_TEMP_PREVIEW)
    }
  }

  const isNewUi = useNewUiDesign()
  const { settings } = useCurrentCompany()
  const isNewUiEnabledForCompany = settings.newUi.enabled

  return (
    <Card>
      <div className='mb-5'>
        <CompanyLogo />
      </div>

      <div className='d-flex align-items-center justify-content-between'>
        <div className='mb-5 NavbarVariables'>
          <ColorItem
            colorVariables={navSectionVariables}
            handlePropertyChanged={handlePropertyChanged}
          />
        </div>

        <div className='mb-5 SubNavVariables'>
          <ColorItem
            colorVariables={subNavSectionVariables}
            handlePropertyChanged={handlePropertyChanged}
          />
        </div>

      </div>

      <div>
        <h6>{I18N('new_navigation')}</h6>
        <div className='mb-5 NewNavVariables'>
          <ColorItem
            colorVariables={newNavSectionVariables}
            handlePropertyChanged={handlePropertyChanged}
          />

          {!isNewUiEnabledForCompany && (
            <Button
              onClick={() => {
                setTemporaryPreview(!isNewUi)
                window.location.reload()
              }}
            >
              {isNewUi ? I18N('exit_preview') : I18N('preview_new_ui')}
            </Button>
          )}
          <p className='text-small text-secondary mt-1'>{I18N('preview_footer')}</p>
        </div>
      </div>

      <div className='mb-5'><BoxShadowSection type='navBoxShadow' /></div>
      <div className='mb-5'><TextTransformSection /></div>
      <div className='mb-5'><FaviconUrlField /></div>
    </Card>
  )
}

export default NavigationSection
