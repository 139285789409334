import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'
import useNewUiDesign from 'components/common/hooks/useNewUiDesign'
import React, { useState } from 'react'

interface Props {
  children: React.ReactNode
  sidebar?: React.ReactNode
}

const BaseLayout = ({ children, sidebar }: Props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const useNewUi = useNewUiDesign()

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }

  const toggleSidebarComponent = (
    <button className='Sidebar__ToggleButton' onClick={toggleSidebar}>
      <CdnSvg src='/images/sidebarIcon.svg' />
    </button>
  )

  return (
    <div className={classNames('BaseLayout', { useNewUi })}>
      <div className='BaseLayout__Main'>
        {React.cloneElement(children as React.ReactElement<any>, { toggleSidebarComponent, isSidebarOpen })}
      </div>

      {sidebar && (
        <div className={classNames('BaseLayout__Sidebar', { isCollapsed: !isSidebarOpen })}>
          <div className='BaseLayout__Sidebar__Content p-3'>
            {React.cloneElement(sidebar as React.ReactElement<any>, { toggleSidebarComponent, isSidebarOpen })}
          </div>
        </div>
      )}
    </div>
  )
}

export default BaseLayout
