import React, { useState } from 'react'
import { SuggestedMessageEntryType } from 'types/ticketing/ticket'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import API from 'services/api'
import { Button } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import useApi from 'components/common/hooks/useApi'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import Entry from 'components/ticketing/view_ticket_sidebar/entries/entry'
import SmartPdfModal from 'components/smartPdfModal'

const I18N = i18nPath('views.ticketing.entries.suggested_message')

const Attachment = ({ entry, reloadEntries }) => {
  const [isEditingPdfModalOpen, setIsEditingPdfModalOpen] = useState(false)
  const [pdfTemplate, setPdfTemplate] = useState(entry.entryable.pdfFile?.template)

  const [updatePdfFile, { isLoading: isUpdatingPdfFile }] = useApi(
    API.admin.ticketing.suggestedMessages.pdfFile.update,
    {
      toastSuccessMessage: I18N('pdf_file_updated'),
      onSuccess: () => {
        reloadEntries()
        toggleEditingPdfModal()
      },
    }
  )

  const toggleEditingPdfModal = () => {
    setIsEditingPdfModalOpen(!isEditingPdfModalOpen)
  }

  return (
    <>
      <div className='SuggestedMessageEntry-wrapper__attachment_title mt-4'>
        <CdnSvg src='/images/paperclipIcon.svg' />
        <span className='text-small font-weight-400 text-secondary'>{I18NCommon('attachment')}</span>
      </div>
      <div className='d-flex'>
        <div className='SuggestedMessageEntry-wrapper__attachment_content mt-2 d-flex gap-3 bordered align-items-center p-2 pr-4'>
          <CdnSvg src='/images/illustrations/roundedPdfIcon.svg' />
          <span className='text-small font-weight-400'>{entry.entryable.pdfFile.attachmentName}</span>

          <a href={entry.entryable.pdfFile.attachmentUrl} target='_blank' rel='noopener noreferrer'>
            <CdnSvg src='/images/eyeOpenIcon.svg' className='eye-icon' />
          </a>

          <span onClick={toggleEditingPdfModal} className='pointer' data-testid='edit-pdf-file'>
            <CdnSvg src='/images/addPencilIcon.svg' className='edit-icon' />
          </span>
        </div>
      </div>

      {isEditingPdfModalOpen && (
        <SmartPdfModal
          toggleModal={toggleEditingPdfModal}
          filename={entry.entryable.pdfFile.attachmentName}
          handleSave={() => updatePdfFile(entry.entryable.id, { template: pdfTemplate })}
          onChange={setPdfTemplate}
          value={pdfTemplate}
          isLoading={isUpdatingPdfFile}
        />
      )}
    </>
  )
}

type SuggestedMessageEntryProps = {
  entry: SuggestedMessageEntryType
  reloadEntries: () => void
  updateContentMessage: (content: string) => void
}

const SuggestedMessageEntry = ({
  entry,
  reloadEntries,
  updateContentMessage,
}: SuggestedMessageEntryProps) => {
  const [sendReply, { isLoading: isSendingReply }] = useApi(
    API.admin.ticketing.tickets.suggestedMessage.sendReply,
    {
      onSuccess: reloadEntries,
    }
  )

  const [editReply, { isLoading: isEditingReply }] = useApi(
    API.admin.ticketing.tickets.suggestedMessage.editReply,
    {
      onSuccess: () => {
        reloadEntries()
        updateContentMessage(entry.entryable.content)
      },
    }
  )

  const isLoading = isSendingReply || isEditingReply

  return (
    <Entry
      label={I18N('title')}
      createdAt={entry.createdAt}
      howToOpen='replace'
      defaultIsOpen={entry.entryable.messageType === 'current'}
    >
      <div className='SuggestedMessageEntry border-color-ai'>
        <div className='SuggestedMessageEntry-wrapper'>
          <div className='mb-4 d-flex align-items-center'>
            <CdnSvg className='mr-3' src='/images/illustrations/aiGradientIcon.svg' />
            <span className='text-normal font-weight-400'>{I18N('suggested_reply')}</span>
          </div>
          <TipTapRichTextView html={entry.entryable.content} />

          {entry.entryable.pdfFile && <Attachment entry={entry} reloadEntries={reloadEntries} />}

          <div className='mt-3 d-flex gap-3'>
            {entry.entryable.messageType === 'current' && (
              <>
                <Button
                  onClick={() => sendReply(entry.ticketId)}
                  showLoadingSpinner={isLoading}
                  disabled={isLoading}
                  className='btn-ai'
                >
                  {I18N('send_reply')}
                </Button>

                <Button
                  variant='secondary'
                  onClick={() => editReply(entry.ticketId)}
                  showLoadingSpinner={isLoading}
                  disabled={isLoading}
                >
                  {I18N('edit_reply')}
                </Button>
              </>
            )}

            {entry.entryable.messageType === 'previous' && (
              <Button
                variant='secondary'
                onClick={() => updateContentMessage(entry.entryable.content)}
              >
                {I18N('use_reply')}
              </Button>
            )}
          </div>

          <hr className='my-4' />

          <div className='d-flex align-items-center gap-2'>
            <span role='img' aria-label='warning'>⚠️</span>
            <span className='font-weight-400 text-secondary text-small'>{I18N('helper')}</span>
          </div>
        </div>
      </div>
    </Entry>
  )
}

export default SuggestedMessageEntry
