import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import { present } from 'components/common/utils'
import { LeftNavItemType } from 'components/navbar/hooks/useLeftNavItems'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.navbar')

const useConfigureNavItems = () => {
  const { permissions } = useCurrentUser()
  const {
    settings,
    pluralGroupDisplayName,
    hasRestrictedAudienceConfiguration,
    type: companyType,
  } = useCurrentCompany()

  const isAiPlatform = companyType === 'ai_platform'

  const userManagementSubItems = [
    permissions.userManager && { label: I18N('users'), to: '/admin/users' },
    permissions.userManager && { label: I18N('user_imports'), to: '/admin/user_imports' },
    settings.groups.enabled && permissions.groupManager && { label: pluralGroupDisplayName, to: '/admin/teams' },
    settings.managedGroups.enabled && permissions.itManager && { label: I18N('managed_groups'), to: '/admin/managed_groups' },
    permissions.superAdmin && !isAiPlatform && { label: I18N('user_settings'), to: '/admin/user_settings' },
    (permissions.clearyAdmin || (permissions.superAdmin && hasRestrictedAudienceConfiguration)) && {
      label: I18N('restricted_audiences'),
      to: '/admin/restricted_audiences',
    },
    permissions.superAdmin && settings.softLaunchWhitelist.enabled && {
      label: I18N('soft_launch_access'),
      to: '/admin/soft_launch_access',
    },
  ].filter(present)

  const integrationsSubItems = [
    permissions.itManager && !isAiPlatform && { label: I18N('app_integrations'), to: '/admin/app_integrations' },
    permissions.itManager && settings.appLauncher.enabled && { label: I18N('apps'), to: '/admin/apps' },
    permissions.externalCalendarManager && { label: I18N('external_calendars'), to: '/admin/external_calendars' },
  ].filter(present)

  const homepagesSubItems = [
    permissions.superAdmin && !isAiPlatform && { label: I18N('homepage_design'), to: '/admin/homepage_design' },
    settings.carousel.enabled && permissions.carouselManager && { label: I18N('carousel_cards'), to: '/admin/carousel_cards' },
    permissions.superAdmin && !isAiPlatform && { label: I18N('timezones'), to: '/admin/timezones' },
  ].filter(present)

  const appConfigurationsSubItems = [
    permissions.stylesManager && !isAiPlatform && { label: I18N('branding'), to: '/admin/branding' },
    settings.logos.enabled && permissions.logoManager && { label: I18N('logos'), to: '/admin/logos' },
    settings.shoutouts.enabled && permissions.companyValueManager && { label: I18N('company_values'), to: '/admin/company_values' },
    permissions.superAdmin && settings.profile.fields.userSkills && !isAiPlatform && { label: I18N('user_skills'), to: '/admin/user_skills' },
    permissions.superAdmin && !isAiPlatform && { label: I18N('other_settings'), to: '/admin/other_settings' },
  ].filter(present)

  const configureNavItems: LeftNavItemType[] = [
    !_.isEmpty(userManagementSubItems) && {
      label: I18N('user_management'),
      to: userManagementSubItems[0].to,
      activePaths: [
        '/admin/users',
        '/admin/user_imports',
        '/admin/teams',
        '/admin/managed_groups',
        '/admin/user_settings',
        '/admin/restricted_audiences',
        '/admin/billing',
      ],
      subItems: userManagementSubItems,
    },
    !_.isEmpty(integrationsSubItems) && {
      label: I18N('integrations'),
      to: integrationsSubItems[0].to,
      activePaths: ['/admin/app_integrations', '/admin/apps', '/admin/external_calendars'],
      subItems: integrationsSubItems,
    },
    permissions.superAdmin && settings.workflow?.enabled && {
      label: I18N('workflows'),
      to: '/admin/workflows',
      activePaths: ['/admin/workflows'],
    },
    permissions.superAdmin && {
      label: I18N('cleary_ai_settings'),
      to: '/admin/cleary_ai_settings',
      activePaths: ['/admin/cleary_ai_settings'],
    },
    !_.isEmpty(homepagesSubItems) && {
      label: I18N('homepages'),
      to: homepagesSubItems[0].to,
      activePaths: ['/admin/homepage_design', '/admin/carousel_cards', '/admin/timezones'],
      subItems: homepagesSubItems,
    },
    !_.isEmpty(appConfigurationsSubItems) && {
      label: I18N('app_configurations'),
      to: appConfigurationsSubItems[0].to,
      activePaths: [
        '/admin/branding',
        '/admin/logos',
        '/admin/company_values',
        '/admin/user_skills',
        '/admin/other_settings',
      ],
      subItems: appConfigurationsSubItems,
    },
  ].filter(present)

  return { configureNavItems, hasConfigureAccess: !_.isEmpty(configureNavItems) }
}

export default useConfigureNavItems
