import React from 'react'
import useApi from 'components/common/hooks/useApi'
import QuestionForm from 'pages/admin/ai/questions/questionForm'
import API from 'services/api'
import { I18NCommon } from 'utils/i18nHelpers'
import { useHistory } from 'react-router-dom'
import BackButton from 'components/common/backButton'


const AddQuestionPage = () => {
  const history = useHistory()
  const [createQuestion, { isLoading: isSaving }] = useApi(
    API.admin.ai.evaluation.questions.create,
    {
      toastSuccessMessage: 'Question created',
      toastErrorMessage: I18NCommon('generic_error'),
      onSuccess: () => {
        history.push('/admin/ai_evaluation')
      },
    }
  )

  return (
    <>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>Create Question</h3>
      </header>

      <main className='AdminContent'>
        <BackButton url='/admin/ai_evaluation' className='mb-4' />
        <QuestionForm onSubmit={createQuestion} isSaving={isSaving} />
      </main>
    </>
  )
}

export default AddQuestionPage
