import React, { useState } from 'react'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import { Button, ButtonSmallNarrow } from 'components/common/buttons'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import useSSE from 'components/common/hooks/useSSE'
import { useDispatch } from 'react-redux'
import { showToastMessage } from 'redux/slices/toasts'
import API from 'services/api'
import Sources from 'components/search/ai/sources'
import { AiEvaluationQuestionType } from 'types/ai/evaluation'
import camelCaseKeys from 'utils/camelCaseKeys'

type QuestionFormData = Partial<AiEvaluationQuestionType>

type QuestionFormProps = {
  onSubmit: (data: QuestionFormData) => void
  onDelete?: () => void
  isSaving: boolean
  isDeleting?: boolean
  initialWorkingCopy?: QuestionFormData
  showDelete?: boolean
}

const QuestionForm = ({
  onSubmit, onDelete, isSaving, isDeleting, initialWorkingCopy, showDelete,
}: QuestionFormProps) => {
  const dispatch = useDispatch()
  const [workingCopy, setWorkingCopy] = useState<QuestionFormData>(
    initialWorkingCopy || { content: '', expectedAnswer: '', sources: [] }
  )
  const [isGenerating, setIsGenerating] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setWorkingCopy(prev => ({ ...prev, [name]: value }))
  }

  const handleUserChange = (selectedUser: any) => {
    setWorkingCopy(prev => ({ ...prev, user: selectedUser }))
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(workingCopy)
  }

  const isValid = workingCopy.content && workingCopy.content.trim() !== '' && !!workingCopy.user

  const handleRegenerateAnswer = () => {
    setWorkingCopy(prev => ({ ...prev, expectedAnswer: '', sources: [] }))
    setIsGenerating(true)
  }

  const handleClearSources = () => {
    setWorkingCopy(prev => ({ ...prev, sources: [] }))
  }

  useSSE('/api/admin/ai/assistant/llm/run_search', {
    options: {
      headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': API.csrfToken() },
      method: 'POST',
      payload: JSON.stringify({
        query: workingCopy.content,
        user_id: workingCopy.user?.id,
        use_experimental_ai_assistant: false,
      }),
    },
    listeners: {
      'delta-received': (event) => {
        setWorkingCopy(prev => ({ ...prev, expectedAnswer: (prev.expectedAnswer || '') + event.data }))
      },
      'workflow-finished': (event) => {
        const data = JSON.parse(event.data)
        setWorkingCopy(prev => ({
          ...prev,
          expectedAnswer: data.message,
          sources: camelCaseKeys(data.sources),
        }))
        setIsGenerating(false)
      },
      'error': (event) => {
        dispatch(showToastMessage({ message: 'Error generating answer. See console for details.', type: 'error' }))
        console.error(event)
        setIsGenerating(false)
      },
    },
    listen: isGenerating && !!workingCopy.content && !!workingCopy.user?.id,
  })

  return (
    <form onSubmit={handleSubmit} className='QuestionForm'>
      <LabeledFormFieldContainer
        label='Question'
        isRequired
      >
        <textarea
          rows={3}
          name='content'
          value={workingCopy.content}
          onChange={handleChange}
          placeholder='Enter question'
          required
        />
      </LabeledFormFieldContainer>

      <LabeledFormFieldContainer
        label='User'
      >
        <EmployeeSearch
          onChange={handleUserChange}
          selectedEmployee={workingCopy.user}
          isClearable
          searchAcrossCompanies
        />
      </LabeledFormFieldContainer>

      <LabeledFormFieldContainer
        label={(
          <div className='d-flex align-items-center mb-3'>
            <span>Expected Answer</span>
            <ButtonSmallNarrow
              type='button'
              className='ml-3'
              variant='secondary'
              onClick={handleRegenerateAnswer}
              disabled={isGenerating || !workingCopy.content || !workingCopy.user}
              showLoadingSpinner={isGenerating}
            >
              {workingCopy.expectedAnswer ? 'Regenerate' : 'Generate'}
            </ButtonSmallNarrow>
            {workingCopy.sources && workingCopy.sources.length > 0 && (
              <ButtonSmallNarrow
                type='button'
                className='ml-3'
                variant='secondary'
                onClick={handleClearSources}
              >
                Clear Sources
              </ButtonSmallNarrow>
            )}
          </div>
        )}
      >
        <textarea
          rows={5}
          name='expectedAnswer'
          value={workingCopy.expectedAnswer}
          onChange={handleChange}
          placeholder='Enter expected answer'
        />
        {workingCopy.sources && <Sources sources={workingCopy.sources} />}
      </LabeledFormFieldContainer>

      <div className='d-flex gap-3 align-items-center'>
        <Button
          type='submit'
          disabled={isSaving || !isValid}
          showLoadingSpinner={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </Button>

        {showDelete && (
          <Button
            type='button'
            variant='danger'
            onClick={onDelete}
            disabled={isDeleting}
            showLoadingSpinner={isDeleting}
            confirm={{
              title: 'Delete Question',
              description: 'Are you sure you want to delete this question? This action cannot be undone.',
              variant: 'danger',
            }}
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        )}
      </div>
    </form>
  )
}

export default QuestionForm
