import Axios from 'axios'
import generateCrudRoutes from 'services/generateCrudRoutes'
import { WorkflowType } from 'types/workflow'
import actions from './workflows/actions'
import runs from './workflows/runs'

export const buildWorkflowPayload = (workflow: WorkflowType) => _.pick(workflow, [
  'id',
  'name',
  'description',
])

const workflows = generateCrudRoutes(
  '/api/admin/workflows',
  'workflow',
  {},
  buildWorkflowPayload
)

export default {
  ...workflows,
  publish(id: string) {
    return Axios.patch(`/api/admin/workflows/${id}/publish`)
  },
  actions,
  runs,
  availableActions: {
    fetchAll() {
      return Axios.get('/api/admin/available_workflow_actions')
    },
  },
}
