import { useEffect, useRef, useState } from 'react'


const useAnimationState = (visible, timeout = 200) => {
  const [state, setState] = useState('hidden')
  const animatingTimeoutRef = useRef<any>()

  useEffect(() => {
    if (state === 'hidden' && !visible) return () => clearTimeout(animatingTimeoutRef.current)

    clearTimeout(animatingTimeoutRef.current)

    if (visible) {
      setState('before-opening')

      animatingTimeoutRef.current = setTimeout(() => {
        setState('opening')

        animatingTimeoutRef.current = setTimeout(() => {
          setState('visible')
        }, timeout - 25)
      }, 25) // lets the browser paint the before-opening state
    } else {
      setState('closing')

      animatingTimeoutRef.current = setTimeout(() => {
        setState('hidden')
      }, timeout)
    }

    return () => clearTimeout(animatingTimeoutRef.current)
  }, [visible])

  return state
}

export default useAnimationState

