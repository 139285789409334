import React, { ReactElement } from 'react'
import { useVariables, useCustomStyles } from 'pages/admin/custom_styles/context'
import { getPropertyListWithValues, sortArrayBy } from 'components/admin/customStyles/utils'
import ColorItem from 'components/admin/customStyles/colorItem'
import BoxShadowSection from 'components/admin/customStyles/boxShadowSection'
import Card from 'components/common/card'
import BorderRadius from './borderRadius'
import BorderWidth from './borderWidth'

const borderRadiusTypes = ['buttonBorderRadius', 'profileImageBorderRadius', 'squareBorderRadius']

const ComponentsSection = (): ReactElement => {
  const colors = useVariables('colors')
  const { handlePropertyChanged } = useCustomStyles()
  const colorVariables = getPropertyListWithValues({ variables: colors, type: 'colors' })

  const componentVariables = [
    'squareBorderRadius',
    'borderColor',
    'tagBackgroundColor',
    'likeHighlightColor',
    'skillTagHoverBackgroundColor',
    'searchBgColor',
    'timezoneHighlightColor',
  ]

  const variablesInComponentsSection = colorVariables.filter(([name]) => componentVariables.includes(name))
  const bannerVariables = ['bannerBackgroundColor', 'bannerTextColor']
  const bannerVariablesInComponentsSection = colorVariables.filter(([name]) => bannerVariables.includes(name))

  variablesInComponentsSection.sort(sortArrayBy(componentVariables))
  bannerVariablesInComponentsSection.sort(sortArrayBy(bannerVariables))

  return (
    <Card>
      <ColorItem
        colorVariables={variablesInComponentsSection}
        handlePropertyChanged={handlePropertyChanged}
      />
      <div className='mt-5'>
        <ColorItem
          colorVariables={bannerVariablesInComponentsSection}
          handlePropertyChanged={handlePropertyChanged}
        />
      </div>
      <div className='mt-5'>
        <BorderRadius borderTypes={borderRadiusTypes} />
      </div>
      <div className='mt-5'>
        <BorderWidth borderWidthVariable='profileAwardedBadgeBorderWidth' />
      </div>
      <div className='mb-5'><BoxShadowSection type='cardBoxShadow' /></div>
    </Card>
  )
}

export default ComponentsSection
