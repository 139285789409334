import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { Button } from 'components/common/buttons'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CdnSvg from 'components/common/cdnSvg'
import { ActionType } from 'types/workflow'
import ActionStepStateIcon from 'components/workflows/actionStepStateIcon'
import Field from './actionForm/field'
import VariablesSelect from './actionForm/variablesSelect'

type Props = {
  action: ActionType,
  isLoading: boolean,
  onSave: (settings: any, configuration: any, callback?: (action: ActionType) => void) => void,
  onRunTest: (configuration: any) => void,
  isRunningTest: boolean,
}

const I18N = i18nPath('views.admin.workflows.actions')
const camelCaseToHumanReadable = (str: string) => _.startCase(_.camelCase(str))

const ActionForm = ({
  action, isLoading, onSave, onRunTest, isRunningTest,
}: Props) => {
  const [settings, setSettings] = useState(action.settings)
  const [configuration, setConfiguration] = useState(action.configuration)
  const [testConfiguration, setTestConfiguration] = useState<any>(action.testConfiguration)
  const [activeKey, setActiveKey] = useState<string | undefined>('setup')

  const hasConfiguration = !_.isEmpty(action.configurationMetadata)
  const testable = action.testable

  let testOutput

  if (testable && !_.isEmpty(action.testOutput)) {
    try {
      testOutput = JSON.stringify(JSON.parse(action.testOutput), null, 2)
    } catch (e) {
      testOutput = action.testOutput
    }
  }

  return (
    <div className='ActionForm'>
      <Tabs
        activeKey={activeKey}
        className='mb-3'
        onSelect={key => setActiveKey(key as string)}
      >
        <Tab
          eventKey='setup'
          title={(
            <div className='d-flex align-items-center gap-2'>
              <ActionStepStateIcon action={action} step='settings' />
              {I18NCommon('setup')}
            </div>
          )}
        >
          {Object.keys(action.settingsMetadata).map(key => (
            <div className='mb-3' key={key}>
              <label className='text-secondary text-small d-block'>
                {I18N(`${action.name}.setup.${_.snakeCase(key)}`)}
                {action.settingsMetadata[key].required && <span className='ml-1 text-danger'>*</span>}
              </label>

              <Field
                type={action.settingsMetadata[key].type}
                field={key}
                action={action}
                settings={settings}
                configuration={configuration}
                onSave={() => onSave(settings, configuration)}
                value={settings[key]}
                onChange={value => setSettings({ ...settings, [key]: value })}
                {...action.settingsMetadata[key]}
              />
            </div>
          ))}

          <Button
            onClick={() => {
              onSave(settings, configuration, (action) => {
                if (action.settingsReady) {
                  if (hasConfiguration) {
                    setActiveKey('configure')
                  } else if (testable) {
                    setActiveKey('test')
                  }
                }
              })
            }}
            disabled={isLoading}
            showLoadingSpinner={isLoading}
            className='mt-3'
          >
            {hasConfiguration || testable ? (
              <>
                {I18NCommon('continue')}
                <CdnSvg src='/images/rightArrowIcon.svg' className='ml-2' />
              </>
            ) : (
              I18NCommon('save')
            )}
          </Button>
        </Tab>

        {!_.isEmpty(action.configurationMetadata) && (
          <Tab
            eventKey='separator'
            title={<CdnSvg src='/images/chevronRightOutline.svg' />}
            disabled
          />
        )}

        {!_.isEmpty(action.configurationMetadata) && (
          <Tab
            eventKey='configure'
            title={(
              <div className='d-flex align-items-center gap-2'>
                <ActionStepStateIcon action={action} step='configuration' />
                {I18NCommon('configure')}
              </div>
            )}
          >
            {Object.keys(action.configurationMetadata).map(key => (
              <div className='mb-3' key={key}>
                <label className='text-secondary text-small'>
                  {camelCaseToHumanReadable(key)}
                  {action.configurationMetadata[key].required && <span className='ml-1 text-danger'>*</span>}
                </label>

                <VariablesSelect
                  action={action}
                  field={key}
                  value={configuration[key]}
                  onChange={value => setConfiguration({ ...configuration, [key]: value })}
                />
              </div>
            ))}

            <Button
              onClick={() => {
                onSave(settings, configuration, () => {
                  if (testable && action.configurationReady) {
                    setActiveKey('test')
                  }
                })
              }}
              disabled={isLoading}
              showLoadingSpinner={isLoading}
              className='mt-3'
            >
              {testable ? (
                <>
                  {I18NCommon('continue')}
                  <CdnSvg src='/images/rightArrowIcon.svg' className='ml-2' />
                </>
              ) : (
                I18NCommon('save')
              )}
            </Button>
          </Tab>
        )}

        {action.testable && (
          <Tab
            eventKey='separator'
            title={<CdnSvg src='/images/chevronRightOutline.svg' />}
            disabled
          />
        )}

        {action.testable && (
          <Tab
            eventKey='test'
            title={(
              <div className='d-flex align-items-center gap-2'>
                <ActionStepStateIcon action={action} step='test' />
                {I18NCommon('test')}
              </div>
            )}
          >
            {Object.keys(action.configurationMetadata).map(key => (
              <div key={key}>
                <label className='text-secondary text-small'>
                  {camelCaseToHumanReadable(key)}
                  <span className='ml-1 text-danger'>*</span>
                </label>
                <Field
                  value={testConfiguration[key]}
                  onChange={value => setTestConfiguration({ ...testConfiguration, [key]: value })}
                  {...action.configurationMetadata[key]}
                />
              </div>
            ))}

            <Button
              onClick={() => onRunTest(testConfiguration)}
              disabled={isLoading || isRunningTest}
              showLoadingSpinner={isLoading || isRunningTest}
              className='mt-3'
            >
              {I18NCommon('run_test')}
            </Button>

            {_.isEmpty(testOutput) ? (
              <div className='mt-3 text-small text-muted'>
                ⚠ {I18N('test_warning')}
              </div>
            ) : (
              <div className='mt-3'>
                <div className='text-small text-secondary'>{I18NCommon('details')}</div>
                <pre className='mt-2'>
                  <code>
                    {testOutput}
                  </code>
                </pre>
              </div>
            )}
          </Tab>
        )}
      </Tabs>
    </div>
  )
}

export default ActionForm
