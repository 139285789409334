import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SingleStatTile from 'components/analytics/common/singleStatTile'
import newsAnalyticsSlice from 'redux/slices/analytics/news'
import { i18nPath } from 'utils/i18nHelpers'
import { toPercentage } from 'utils/analytics'
import TargetingRulesType from 'types/audience/targetingRules'

const I18N = i18nPath('views.platform_analytics.news')

interface NewsSummaryProps {
  periodStart: string
  periodEnd?: string
  targetingRules?: TargetingRulesType
}

const NewsSummary = ({ periodStart, periodEnd, targetingRules }: NewsSummaryProps) => {
  const dispatch = useDispatch()

  const summary = useSelector(newsAnalyticsSlice.selectors.getSummary())
  const { summary: { isLoading } } = useSelector(newsAnalyticsSlice.selectors.getMetaData())

  const averageAudienceReachPercent = toPercentage(summary.averageAudienceReachPercent)
  const averageAudienceEmailOpensPercent = toPercentage(summary.averageAudienceEmailOpensPercent)
  const usersEngagedWithArticlePercent = toPercentage(summary.usersEngagedWithArticlePercent)

  useEffect(() => {
    dispatch(newsAnalyticsSlice.asyncActions.admin.fetchSummary(
      { periodStart, periodEnd, targetingRules }
    ))
  }, [periodStart, periodEnd, targetingRules])

  return (
    <>
      <SingleStatTile value={summary.articlesPublishedCount} description={I18N('articles_published_description')} isLoading={isLoading} />
      <SingleStatTile value={averageAudienceReachPercent} description={I18N('avg_audience_reach_description')} isLoading={isLoading} />
      <SingleStatTile value={averageAudienceEmailOpensPercent} description={I18N('avg_audience_email_open_description')} isLoading={isLoading} />
      <SingleStatTile value={usersEngagedWithArticlePercent} description={I18N('employee_engaged_with_articles_description')} isLoading={isLoading} />
    </>
  )
}

export default NewsSummary
