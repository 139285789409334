import React from 'react'
import classNames from 'classnames'

import CdnSvg from 'components/common/cdnSvg'
import IconButtonWithPopover from 'components/common/iconButtonWithPopover'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.common.toggle_menu_button')

interface ToggleMenuButtonProps {
  isOpen: boolean
  onClick: () => void
}

const ToggleMenuButton = ({ isOpen, onClick }: ToggleMenuButtonProps) => (
  <IconButtonWithPopover
    text={isOpen ? I18N('hide') : I18N('show')}
    icon={<CdnSvg src='/images/sidebarIcon.svg' />}
    onClick={onClick}
    className={classNames('ToggleMenuButton', { isOpen })}
    textClassName='text-white text-smallest'
  />
)

export default ToggleMenuButton
