import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import { ButtonNarrow } from 'components/common/buttons'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import EmployeeMiniCard from 'components/common/employeeMiniCard'
import { ExternalSyncSource } from 'components/admin/integrations/external_sync/types'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

type Props = {
  source: ExternalSyncSource
  className?: string
  helpText?: string
  onRemove?: (sourceId: string) => void
}

const I18N = i18nPath('views.integrations_page.external_knowledge_section')

const ConnectedExternalSource: React.FC<Props> = ({
  source, className, helpText, onRemove,
}) => (
  <div className={classNames('bordered px-4 py-2 rounded d-flex justify-content-between align-items-center w-100', className)}>
    <div className='d-flex align-items-center'>
      <CdnSvg src={source.iconPath} />

      <span className='ml-3 bold'>{source.humanName}</span>
      {helpText && (
        <span className='ml-3 text-small'>
          <Link to={source.displayPath}>{helpText}</Link>
        </span>
      )}
    </div>

    <div className='d-flex align-items-center'>
      <span className='mr-2 text-small text-muted font-weight-400'>{I18NCommon('connected_by')}</span>
      <EmployeeMiniCard employee={source.user} showEmployeeTitle={false} photoSize='20px' />
      <ButtonNarrow
        variant='danger'
        className='ml-5'
        onClick={() => onRemove?.(source.id)}
        confirm={{
          title: I18N('remove_confirmation_modal.title'),
          description: I18N('remove_confirmation_modal.description'),
          variant: 'danger',
        }}
      >
        {I18NCommon('remove')}
      </ButtonNarrow>
    </div>
  </div>
)

export default ConnectedExternalSource
