import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { i18nPath, I18NCommon } from 'utils/i18nHelpers'
import { useDispatch, useSelector } from 'react-redux'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import DeleteModal from 'components/common/modals/deleteModal'
import AssignRoleModal from 'components/groups/assignRoleModal'
import MoveToGroupModal from 'components/groups/moveToGroupModal'
import { Button } from 'components/common/buttons'
import groupMembershipSlice, { buildMembershipPayload } from 'redux/slices/groupMemberships'
import groupSlice from 'redux/slices/groups'

import classNames from 'classnames'

const I18N = i18nPath('views.groups.group_page')

const MemberCardMenuItems = ({
  className,
  membership,
  group,
}) => {
  const dispatch = useDispatch()
  const [showRemoveFromTeamModal, setShowRemoveFromTeamModal] = useState(false)
  const [showAssignRoleModal, setShowAssignRoleModal] = useState(false)
  const [showMoveToGroupModal, setShowMoveToGroupModal] = useState(false)
  const { queryParams } = useSelector(groupMembershipSlice.selectors.getMetaData())
  const user = membership.user
  const isUserLead = membership.lead
  const useLabelsToCategorizeMembers = group.groupType?.useLabelsToCategorizeMembers || false

  const canAddOrRemoveMembers = group.permittedActions.editMembers
  const canEditMembershipAttributes = group.permittedActions.editMembershipAttributes
  const showMenu = canAddOrRemoveMembers || canEditMembershipAttributes

  const toggleLead = () => {
    const newMembershipPayload = buildMembershipPayload({ ...membership, lead: !isUserLead })
    dispatch(groupMembershipSlice.asyncActions.update(newMembershipPayload))
  }

  const onSaveRole = (newRole) => {
    const newMembershipPayload = buildMembershipPayload({ ...membership, role: newRole })
    dispatch(groupMembershipSlice.asyncActions.update(newMembershipPayload, () => setShowAssignRoleModal(false)))
  }

  const onSaveGroupTypeLabel = (newLabel) => {
    const newMembershipPayload = buildMembershipPayload({ ...membership, groupTypeLabel: newLabel })
    dispatch(groupMembershipSlice.asyncActions.update(newMembershipPayload, () => setShowMoveToGroupModal(false)))
  }

  const removeFromTeam = async () => {
    await dispatch(groupMembershipSlice.asyncActions.removePerson(membership))
    dispatch(groupSlice.asyncActions.fetch(group.id))
    dispatch(groupMembershipSlice.asyncActions.fetchAll(group.id, queryParams))
  }

  return (
    <>
      {showMenu && (
        <DropdownMenuContainer
          className='position-absolute right-0 top-0 mr-2 mt-2'
          menuType='kebab'
          closeMenuOnItemClick
        >
          {canEditMembershipAttributes && (
            <CardDropdownMenuItem
              className='p-3 text-primary'
              width='180px'
              primaryText={isUserLead ? I18N('remove_lead') : I18N('mark_as_lead')}
              onClick={toggleLead}
            />
          )}
          {canAddOrRemoveMembers && (
            <CardDropdownMenuItem
              className='p-3 text-primary'
              width='180px'
              primaryText={I18N('remove_from_team')}
              onClick={() => setShowRemoveFromTeamModal(true)}
            />
          )}
          {!isUserLead && canEditMembershipAttributes && (
            <CardDropdownMenuItem
              className='p-3 text-primary'
              width='180px'
              primaryText={I18N('assign_role')}
              onClick={() => setShowAssignRoleModal(true)}
            />
          )}
          {useLabelsToCategorizeMembers && canEditMembershipAttributes && (
            <CardDropdownMenuItem
              className='p-3 text-primary'
              width='180px'
              primaryText={I18N('move_to_group')}
              onClick={() => setShowMoveToGroupModal(true)}
            />
          )}
        </DropdownMenuContainer>
      )}
      <DeleteModal
        deleteText={I18N('remove_from_team')}
        deleteSecondaryText={I18N('remove_from_team_secondary', { name: user.preferredFullName })}
        deleteConfirmText={I18NCommon('remove')}
        showDeleteModal={showRemoveFromTeamModal}
        closeDeleteModal={() => setShowRemoveFromTeamModal(false)}
        deleteConfirm={removeFromTeam}
      />
      <AssignRoleModal
        showModal={showAssignRoleModal}
        closeModal={() => setShowAssignRoleModal(false)}
        onSave={onSaveRole}
        role={membership.role}
      />
      <MoveToGroupModal
        showModal={showMoveToGroupModal}
        closeModal={() => setShowMoveToGroupModal(false)}
        onSave={onSaveGroupTypeLabel}
        groupTypeLabel={membership.groupTypeLabel}
        groupTypeLabels={group.groupType?.groupTypeLabels}
      />
    </>
  )
}

export default MemberCardMenuItems
