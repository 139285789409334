import React, { useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import { WorkflowRunEntryType } from 'types/ticketing/ticket'
import ActionIcon from 'components/workflows/actionIcon'
import WorkflowRunDetailsModal from 'components/workflows/workflowRunDetailsModal'
import Entry from './entry'

const I18N = i18nPath('views.ticketing.entries')
const I18N_ACTIONS = i18nPath('views.admin.workflows.actions')

const WorkflowRun = ({ entry }: { entry: WorkflowRunEntryType }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)

  return (
    <>
      <Entry
        label={I18N('workflow_run', { workflowName: entry.entryable.workflow.name })}
        createdAt={entry.createdAt}
        howToOpen='expand'
      >
        <div className='Entry__WorkflowRun d-flex flex-column gap-2 align-items-center mt-2'>
          <div className='Entry__WorkflowRun__items'>
            {entry.entryable.actionRuns.map(actionRun => (
              <div
                key={actionRun.id}
                className='Entry__WorkflowRun__items__item d-flex align-items-center gap-2 mb-1 pointer'
                onClick={toggleModal}
              >
                <ActionIcon action={actionRun.action} />
                <span className='text-smallest text-secondary'>
                  {I18N_ACTIONS(`${actionRun.action.name}.label`)}
                </span>
              </div>
            ))}
          </div>
        </div>
      </Entry>

      {isModalOpen && (
        <WorkflowRunDetailsModal
          workflow={entry.entryable.workflow}
          run={entry.entryable}
          toggleModal={toggleModal}
        />
      )}
    </>
  )
}

export default WorkflowRun
