import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import SmartTable from 'components/common/tables/smartTable'
import React, { useState } from 'react'
import API from 'services/api'
import { AiEvaluationRunType } from 'types/ai/evaluation'
import DateCell from 'components/common/tables/dateCell'
import { i18nPath } from 'utils/i18nHelpers'
import { useHistory } from 'react-router-dom'
import ScoreLabel from 'pages/admin/ai/runScoreLabel'
import { formatFloat } from 'components/common/utils'
import { ButtonLink, Button } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.ai_evaluation.runs')

const AiEvaluationRunsTable = () => {
  const history = useHistory()
  const [selectedRuns, setSelectedRuns] = useState<string[]>([])
  const {
    data, isLoading, paginationData, callApi, isLoaded,
  } = useFetch<AiEvaluationRunType[]>(API.admin.ai.evaluation.runs.fetchAll)

  const columns = [
    {
      header: '',
      style: { width: '50px' },
      col: 'col-select',
      accessor: (run: AiEvaluationRunType) => (
        <input
          type='checkbox'
          checked={selectedRuns.includes(run.id)}
          onChange={(e) => {
            e.stopPropagation()
            setSelectedRuns(prev => (e.target.checked
              ? [...prev, run.id]
              : prev.filter(id => id !== run.id))
            )
          }}
          onClick={e => e.stopPropagation()}
        />
      ),
    },
    {
      header: I18N('table_header.created_at'),
      col: 'col-created-at',
      accessor: (run: AiEvaluationRunType) => <DateCell date={run.createdAt} />,
    },
    {
      header: I18N('table_header.description'),
      col: 'col-description',
      accessor: (run: AiEvaluationRunType) => <span>{run.description}</span>,
    },
    {
      header: I18N('table_header.score'),
      col: 'col-score',
      accessor: (run: AiEvaluationRunType) => (
        <span>
          <span className='mr-2 d-inline-block' style={{ width: '90px' }}>Answered:</span>
          <span className='mr-2'>{formatFloat(run.score || 0)}</span>
          <ScoreLabel score={run.score} />
          <br />
          <span className='mr-2 d-inline-block' style={{ width: '90px' }}>Conciseness:</span>
          <span className='mr-2'>{formatFloat(run.concisenessScore || 0)}</span>
          <ScoreLabel score={run.concisenessScore} />
        </span>
      ),
    },
    {
      header: I18N('table_header.cost'),
      col: 'col-cost',
      accessor: (run: AiEvaluationRunType) => <span>${formatFloat(run.totalCost, 6)}</span>,
    },
  ]

  const onRowClick = (run: AiEvaluationRunType) => {
    history.push(`/admin/ai_evaluation/${run.id}`)
  }

  const onCompareClick = () => {
    if (selectedRuns.length < 2) return
    history.push(`/admin/ai_evaluation/runs/compare?runIds=${selectedRuns.join(',')}`)
  }

  if (!isLoaded) return <CirclesLoadingIndicator className='TableLoadingIndicator' />

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h3>{I18N('title')}</h3>

        <div className='d-flex gap-2'>
          {selectedRuns.length >= 2 && (
            <Button onClick={onCompareClick} className='mr-2'>
              Compare Selected ({selectedRuns.length})
            </Button>
          )}
          <ButtonLink to='/admin/ai_evaluation/runs/new'>
            <CdnSvg src='/images/plusIcon.svg' className='mr-2 create-run-icon' style={{ width: '15px' }} />
            Create Run
          </ButtonLink>
        </div>
      </div>

      <SmartTable
        isLoading={isLoading}
        LoadingComponent={<CirclesLoadingIndicator className='TableLoadingIndicator' />}
        columns={columns}
        data={data}
        className='white-bg-table table-with-row-hover'
        showPagination
        page={paginationData.page}
        pages={paginationData.totalPages}
        onPaginationClick={callApi}
        onSortChange={() => {}}
        sortKey='created_at'
        sortedAsc={false}
        onRowClick={onRowClick}
      />
    </div>
  )
}

export default AiEvaluationRunsTable
