import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import TaskableFormSharedFieldsSection from 'components/admin/journeys/taskables/taskableFormSharedFieldsSection'
import TaskableFormScheduleSection from 'components/admin/journeys/taskables/taskableFormScheduleSection'
import classNames from 'classnames'
import StepTemplateFormButtonsContainer from 'components/admin/journeys/common/stepTemplateFormButtonsContainer'
import CalendarMeetingDetailsSection from 'components/admin/journeys/common/calendarMeetingDetailsSection'
import TaskableFormAdvancedSettingsSection from 'components/admin/journeys/taskables/taskableFormAdvancedSettingsSection'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.admin.journeys.introduction_templates')

export const nonRequiredFields = [
  'allowSilenceNotifications',
  'automaticallyScheduleCalendarInvites',
  'createVideoConference',
  'dueAfterNumDays',
  'notifyViaEmail',
  'notifyViaMsTeams',
  'notifyViaSlack',
  'notifyViaSms',
  'useRecipientNotificationPreferences',
  'externalCalendarId',
]

const IntroductionsHelpCard = ({
  className = '',
  journeyBlueprintType = 'onboarding',
}) => (
  <div className={classNames('IntroductionsHelpCard px-5 d-flex justify-content-between', className)}>
    <div className='d-flex flex-column py-3 w-50 justify-content-center'>
      <h3 className='text-white'>{I18N(`${journeyBlueprintType}.help_widget.title`)}</h3>
      <p className='text-white mb-0'>{I18N(`${journeyBlueprintType}.help_widget.description`)}</p>
    </div>
    <div className='confetti-background d-flex justify-content-center align-items-center w-50 py-2 position-relative'>
      <CdnSvg src='/images/introductionsImage.svg' className='IntroductionImage' />
    </div>
  </div>
)

const IntroductionForm = ({
  onSave,
  workingCopy,
  updateWorkingCopy,
  currentState = 'draft',
  areChangesPresent = true,
  forUser = null,
  isStepTemplate = true,
  isDisabled = false,
  showHelpCard = true,
  journeyBlueprint,
  isSaving = false,
}) => (
  <div className='IntroductionForm'>
    {showHelpCard && <IntroductionsHelpCard className='mb-5' journeyBlueprintType={journeyBlueprint?.type} />}
    <TaskableFormSharedFieldsSection
      workingCopy={workingCopy}
      updateWorkingCopy={updateWorkingCopy}
      isDisabled={isDisabled}
      stepType='introduction'
      journeyBlueprint={journeyBlueprint}
    />
    <TaskableFormScheduleSection
      workingCopy={workingCopy}
      updateWorkingCopy={updateWorkingCopy}
      isDisabled={isDisabled}
      isStepTemplate={isStepTemplate}
      journeyBlueprint={journeyBlueprint}
    />
    <CalendarMeetingDetailsSection
      workingCopy={workingCopy}
      updateWorkingCopy={updateWorkingCopy}
      isDisabled={isDisabled}
      journeyBlueprint={journeyBlueprint}
      scheduleMeetingsAutomatically={workingCopy?.automaticallyScheduleCalendarInvites}
    />
    <TaskableFormAdvancedSettingsSection
      workingCopy={workingCopy}
      updateWorkingCopy={updateWorkingCopy}
      isDisabled={isDisabled}
      isStepTemplate={isStepTemplate}
      stepType='introduction'
      journeyBlueprint={journeyBlueprint}
    />
    <StepTemplateFormButtonsContainer
      onSave={onSave}
      workingCopy={workingCopy}
      currentState={currentState}
      areChangesPresent={areChangesPresent}
      forUser={forUser}
      isStepTemplate={isStepTemplate}
      isDisabled={isDisabled}
      isSaving={isSaving}
      stepType='introduction'
      nonRequiredFields={nonRequiredFields}
      postTriggerNames={['due']}
    />
  </div>
)

export default IntroductionForm
