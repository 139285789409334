import React from 'react'

import classNames from 'classnames'
import CdnSvg from 'components/common/cdnSvg'

interface CursorPaginationContainerProps {
  handleNextClick: () => void
  handlePrevClick: () => void
  nextCursor?: string
  prevCursor?: string
}

const CursorPaginationContainer = ({
  handleNextClick,
  handlePrevClick,
  nextCursor,
  prevCursor,
}: CursorPaginationContainerProps) => {
  if (!nextCursor && !prevCursor) return null

  return (
    <div className='PaginationContainer CursorPaginationContainer d-flex mt-3 flex-wrap'>
      <ul className='pagination d-flex justify-content-center align-items-flex-start'>
        {prevCursor && (
          <span onClick={() => handlePrevClick()}>
            <CdnSvg
              src='/images/arrowheadLeftIcon.svg'
              className={classNames('pointer arrow left')}
            />
          </span>
        )}
        {nextCursor && (
          <span onClick={() => handleNextClick()}>
            <CdnSvg
              src='/images/arrowheadLeftIcon.svg'
              className={classNames('pointer arrow right')}
            />
          </span>
        )}
      </ul>
    </div>
  )
}

export default CursorPaginationContainer
