import Axios from 'axios'
import searchURI from 'services/searchURI'

const buildMessagePayload = record => _.pick(record, [
  'content',
])

const messagesApi = {
  create(conversationId, model) {
    return Axios.post(`/api/ai/chat/conversations/${conversationId}/messages`, { message: buildMessagePayload(model) })
  },

  fetchAll(conversationId, opts) {
    const options = { page: 1, ...opts }

    return Axios.get(searchURI(`/api/ai/chat/conversations/${conversationId}/messages`, options))
  },

  createNeedFurtherAssistanceMessage(conversationId) {
    return Axios.post(`/api/ai/chat/conversations/${conversationId}/messages/need_further_assistance`)
  },
}

export default messagesApi
