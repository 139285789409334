import { useAskAiContext } from 'components/ai/chat/ask_ai_modal/askAiContext'
import { AiMessageType, AiMessageTypeEnum } from 'components/ai/chat/ask_ai_modal/types'
import { Button } from 'components/common/buttons'
import CancelButton from 'components/common/cancelButton'
import useApi from 'components/common/hooks/useApi'
import useUpdatableState from 'components/common/hooks/useUpdatableState'
import FormEditableField from 'components/form_fields/formEditableField'
import React, { memo, useMemo, useState } from 'react'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ticketing.ticket_escalation_form')

type Props = {
  aiChatMessage: AiMessageType
}

const getUserMessageBeforeTicketEscalation = (messages) => {
  const indexOfTicketEscalationMessage = _.findLastIndex(messages, (message: AiMessageType) => (
    message.type === AiMessageTypeEnum.OFFER_TICKET_ESCALATION
  ))

  return messages[indexOfTicketEscalationMessage - 1]
}

const TicketEscalationForm = ({ aiChatMessage }: Props) => {
  const { setMessages, messages, currentConversationId } = useAskAiContext()
  const [createTicket, { isLoading, isLoaded: isSaved }] = useApi(API.ticketing.tickets.create, {
    onSuccess: (ticket) => {
      setMessages(prev => [...prev, ticket.aiTicketCreatedMessage])
    },
  })

  const [createNeedFurtherAssistanceMessage, { isLoading: isCanceling }] = useApi(
    API.ai.chats.messages.createNeedFurtherAssistanceMessage,
    {
      onSuccess: (message) => {
        setMessages(prev => [...prev, message])
      },
    }
  )

  const isLastMessage = aiChatMessage.id === messages[messages.length - 1].id
  const isFormDisabled = !!aiChatMessage.ticketingTicket || isSaved || isLoading || !isLastMessage || isCanceling

  const userMessageBeforeTicketEscalation = isFormDisabled ? null : getUserMessageBeforeTicketEscalation(messages)
  const [workingCopy, _setWorkingCopy, updateWorkingCopy] = useUpdatableState({
    title: aiChatMessage.ticketingTicket?.title || userMessageBeforeTicketEscalation?.content || '',
    description: aiChatMessage.ticketingTicket?.description || '',
  })

  return (
    <div className='bordered mt-3 p-3'>
      <div className='text-secondary mb-3 text-uppercase text-small'>{I18N('title')}</div>
      <FormEditableField
        currentValue={workingCopy.title}
        onChange={e => updateWorkingCopy({ title: e.target.value })}
        fieldName={I18N('reason')}
        label={I18N('reason')}
        disabled={isFormDisabled}
        isRequired
      />
      <FormEditableField
        currentValue={workingCopy.description}
        onChange={e => updateWorkingCopy({ description: e.target.value })}
        fieldName={I18N('details')}
        label={I18N('details')}
        textarea
        disabled={isFormDisabled}
      />

      <div className='d-flex align-items-center justify-content-end'>
        <CancelButton
          onClick={() => createNeedFurtherAssistanceMessage(currentConversationId)}
          isDisabled={isFormDisabled}
        />
        <Button
          onClick={() => createTicket({ ...workingCopy, origin: 'chatbot', aiTicketFormMessageId: aiChatMessage.id })}
          disabled={isFormDisabled || !workingCopy.title}
          showLoadingSpinner={isLoading}
        >
          {I18NCommon('submit')}
        </Button>
      </div>
    </div>
  )
}

export default TicketEscalationForm
