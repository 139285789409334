import React from 'react'

import { AvailableExternalSyncSource, ExternalSyncSource } from 'components/admin/integrations/external_sync/types'
import Card from 'components/common/card'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import CdnSvg from 'components/common/cdnSvg'
import ConnectedExternalSource from 'components/admin/integrations/external_sync/connectedExternalSource'
import { sourceHelpText } from 'components/admin/integrations/external_sync/externalKnowledgeSection'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useApi from 'components/common/hooks/useApi'
import { i18nPath } from 'utils/i18nHelpers'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

const I18N = i18nPath('views.integrations_page.external_knowledge_section')

const ConnectExternalSource = ({ availableSource }: { availableSource: AvailableExternalSyncSource }) => (
  <a href={availableSource.installationUrl} rel='noopener noreferrer' className='ConnectExternalSource'>
    <CdnSvg src={availableSource.iconPath} />
    <span className='text-primary text-center mt-1'>{availableSource.humanName}</span>
  </a>
)

const ExternalSourcesPage = () => {
  const currentUser = useCurrentUser()
  const {
    data: connectedSources = [], removeItem,
    isLoading: isLoadingConnectedSources,
  } = useFetch<ExternalSyncSource[]>(API.admin.externalSync.sources.fetchAll)

  const {
    data: availableSources = [],
    callApi: fetchAvailableSources,
    isLoading: isLoadingAvailableSources,
  } = useFetch<AvailableExternalSyncSource[]>(API.admin.externalSync.sources.availableTypes)

  const [destroySource] = useApi(API.admin.externalSync.sources.destroy, {
    toastSuccessMessage: I18N('source_destroyed'),
  })

  const onRemove = async (sourceId: string) => {
    await destroySource(sourceId)
    removeItem(sourceId)
    fetchAvailableSources()
  }

  const isLoading = isLoadingConnectedSources || isLoadingAvailableSources

  return (
    <div className='ExternalSourcesPage'>
      <header className='AdminHeader'>
        <h3>{I18N('external_sources')}</h3>
      </header>
      <main className='AdminContent'>
        {isLoading ? (
          <CirclesLoadingIndicator />
        ) : (
          <>
            <Card>
              <h6>{I18N('available_sources')}</h6>
              <p className='text-small text-secondary font-weight-400'>{I18N('available_sources_description')}</p>

              <div className='AvailableSources'>
                {availableSources?.map(source => (
                  <ConnectExternalSource key={source.type} availableSource={source} />
                ))}
              </div>
            </Card>

            {!_.isEmpty(connectedSources) && (
              <Card className='mt-4'>
                <h6>{I18N('connected_sources')}</h6>
                <p className='text-small text-secondary font-weight-400'>{I18N('connected_sources_description')}</p>

                <div className='ConnectedSources'>
                  {connectedSources?.map(source => (
                    <ConnectedExternalSource
                      key={source.id}
                      source={source}
                      helpText={sourceHelpText(source, currentUser)}
                      onRemove={onRemove}
                    />
                  ))}
                </div>
              </Card>
            )}
          </>
        )}
      </main>
    </div>
  )
}

export default ExternalSourcesPage
