import Axios from 'axios'
import searchURI from 'services/searchURI'

export default {
  fetchAll(workflowId, params) {
    const options = { page: 1, ...params }

    return Axios.get(searchURI(`/api/admin/workflows/${workflowId}/runs`, options))
  },

  fetch(workflowId, runId) {
    return Axios.get(`/api/admin/workflows/${workflowId}/runs/${runId}`)
  },
}
