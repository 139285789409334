import React from 'react'
import useApi from 'components/common/hooks/useApi'
import RunForm from 'pages/admin/ai/runs/runForm'
import API from 'services/api'
import { I18NCommon } from 'utils/i18nHelpers'
import { useHistory } from 'react-router-dom'
import BackButton from 'components/common/backButton'

const AddRunPage = () => {
  const history = useHistory()
  const [createRun, { isLoading: isSaving }] = useApi(
    API.admin.ai.evaluation.runs.create,
    {
      toastSuccessMessage: 'Run created',
      toastErrorMessage: I18NCommon('generic_error'),
      onSuccess: () => {
        history.push('/admin/ai_evaluation')
      },
    }
  )

  return (
    <>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>Create Run</h3>
      </header>

      <main className='AdminContent'>
        <BackButton url='/admin/ai_evaluation' className='mb-4' />
        <RunForm onSubmit={createRun} isSaving={isSaving} />
      </main>
    </>
  )
}

export default AddRunPage
