import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'
import { checkForError } from 'utils/errorHandling'
import { ReduxState } from 'redux/redux'
import entitySlice from 'redux/slices/entities'
import { defaultActions, defaultMeta } from 'redux/slices/utils/commonReducers'
import { DefaultMetaType } from 'redux/slices/utils/commonReducers.types'
import API from 'services/api'
import appSignal from 'services/appSignal'
import normalizeTargetingRules from 'utils/normalizeTargetingRules'

export const AI_ASSISTANT_ANSWERS = 'ai_assistant_answers'
export const AI_CHATBOT = 'ai_chatbot'
export const AI_TEXT_GENERATION = 'ai_text_generation'
export const APP_LAUNCHER = 'app_launcher'
export const ARTICLES = 'articles'
export const BADGES = 'badges'
export const BANNERS = 'banners'
export const BLOOMFIRE_SEARCH = 'bloomfire_search'
export const CAROUSEL = 'carousel'
export const CELEBRATIONS = 'celebrations'
export const COMMENTS = 'comments'
export const CONFLUENCE_SEARCH = 'confluence_search'
export const DEFAULT_USER_PRIVACY = 'default_user_privacy'
export const EDITOR = 'editor'
export const EXTERNAL_ALERTS = 'external_alerts'
export const EXTRA_USER_FIELDS = 'extra_user_fields'
export const FEED = 'feed'
export const GLOBAL_SEARCH = 'global_search'
export const GOLINKS = 'golinks'
export const GOOGLE_DRIVE_BLOCK_LIST = 'google_drive_block_list'
export const GOOGLE_DRIVE_SEARCH = 'google_drive_search'
export const GOOGLE_EMAIL_SYNC = 'google_email_sync'
export const GROUPS = 'groups'
export const HOME = 'home'
export const JOURNEYS = 'journeys'
export const LEGAL_NOTICES = 'legal_notices'
export const LOGOS = 'logos'
export const MANAGED_GROUPS = 'managed_groups'
export const MERGEDEV = 'mergedev'
export const METRICS_DEFAULT_COLUMNS = 'metrics_default_columns'
export const NEW_UI = 'new_ui'
export const NEW_TAB_REDIRECT = 'new_tab_redirect'
export const NEWS = 'news'
export const NOTION_SEARCH = 'notion_search'
export const ONBOARDING_BUDDY_SETTINGS = 'onboarding_buddy_settings'
export const ONE_DRIVE = 'one_drive'
export const ORG_CHART = 'org_chart'
export const PAGES = 'pages'
export const PEOPLE = 'people'
export const PROFILE = 'profile'
export const QNA = 'qna'
export const REACTIONS = 'reactions'
export const SESSION = 'session'
export const SHOUTOUTS = 'shoutouts'
export const SLACK_APP = 'slack_app'
export const SLACK_FEEDBACK = 'slack_feedback'
export const SMS = 'sms'
export const SOCIAL_SHARING = 'social_sharing'
export const SOFT_LAUNCH_WHITELIST = 'soft_launch_whitelist'
export const SURVEYS = 'surveys'
export const TARGETING_OPTIONS = 'targeting_options'
export const TICKETING = 'ticketing'
export const WORKFLOW = 'workflow'
export const USER_IMPORTS = 'user_imports'
export const ZOOM = 'zoom'

export type CompanyConfigurationState = {
  meta: DefaultMetaType
}

export const initialState: CompanyConfigurationState = {
  meta: {
    ...defaultMeta,
  },
}

const buildValuePayload = (name, value) => {
  switch (name) {
  case AI_CHATBOT:
    return _.pick(value, [
      'enabled',
      'rolloutPercentage',
      'title',
      'ticketEscalation',
    ])
  case SESSION:
    return _.pick(value, [
      'expireAfterInHours',
      'maxAgeInHours',
      'mobileExpireAfterInHours',
      'mobileMaxAgeInHours',
      'managedByCustomer',
    ])
  case MANAGED_GROUPS:
    return _.pick(value, [
      'enabled',
      'memberRemovalAllowed',
      'channelTypes',
    ])
  case CELEBRATIONS:
    return _.pick(value, [
      'enabled',
      'socialShare',
      'deliverCardsWithNoSignatures',
      'birthdays',
      'workAnniversaries',
      'newEmployees',
      'manualCelebrations',
    ])
  case USER_IMPORTS:
    return _.pick(value, [
      'syncFromSftp',
      'dateFormat',
      'csvSeparator',
      'inferManager',
    ])
  case DEFAULT_USER_PRIVACY:
    return _.pick(value, [
      'phoneMobile',
      'city',
    ])
  case MERGEDEV:
    return _.pick(value, [
      'enabled',
      'hris',
      'ats',
    ])
  case NEWS:
    return _.pick(value, [
      'enabled',
      'newEmployees',
      'socialSharing',
      'templates',
      'restrictArticlesByDefault',
    ])
  case COMMENTS:
    return _.pick(value, [
      'articles',
      'shoutouts',
      'awardedBadges',
    ])
  case HOME:
    return _.pick(value, [
      'baseUrl',
    ])
  case ONBOARDING_BUDDY_SETTINGS:
    const newValue = _.pick(value, [
      'daysUntilEmployeeEligibleToBeBuddy',
      'daysUntilNewHireNeedsABuddy',
      'automaticallyOptInEmployees',
      'buddyDurationInDays',
      'eligibleBuddiesTargetingRules',
      'eligibleNewHiresTargetingRules',
      'matchingCriteria',
      'marketplace',
      'allowMultipleNewHires',
    ])
    if (newValue.eligibleBuddiesTargetingRules) {
      newValue.eligibleBuddiesTargetingRules = normalizeTargetingRules(newValue.eligibleBuddiesTargetingRules)
    }
    if (newValue.eligibleNewHiresTargetingRules) {
      newValue.eligibleNewHiresTargetingRules = normalizeTargetingRules(newValue.eligibleNewHiresTargetingRules)
    }

    return newValue
  case APP_LAUNCHER:
  case BADGES:
  case BANNERS:
  case CAROUSEL:
  case CONFLUENCE_SEARCH:
  case BLOOMFIRE_SEARCH:
  case GOOGLE_DRIVE_SEARCH:
  case GOOGLE_EMAIL_SYNC:
  case NOTION_SEARCH:
  case NEW_TAB_REDIRECT:
  case LOGOS:
  case ORG_CHART:
  case PEOPLE:
  case SLACK_FEEDBACK:
  case SMS:
  case SURVEYS:
  case TICKETING:
  case WORKFLOW:
  case ZOOM:
    return _.pick(value, [
      'enabled',
    ])
  case ONE_DRIVE:
    return _.pick(value, [
      'userConsentEnabled',
      'adminConsentEnabled',
      'searchEnabled',
    ])
  case PAGES:
    return _.pick(value, [
      'enabled',
      'inlineCommentsEnabled',
      'aiEnabled',
      'faqs',
    ])
  case QNA:
    return _.pick(value, [
      'enabled',
      'commentsOnQuestions',
    ])
  case PROFILE:
    return _.pick(value, [
      'fields',
      'calendarDemo',
    ])
  case GLOBAL_SEARCH:
    return _.pick(value, [
      'userSkills',
      'pages',
      'pageWorkspaces',
      'pageFaqs',
      'golinks',
      'news',
      'groups',
      'qna',
      'apps',
      'attachedFileContents',
    ])
  case GOLINKS:
    return _.pick(value, [
      'enabled',
      'showChromeExtensionBanner',
    ])
  case GROUPS:
    return _.pick(value, [
      'enabled',
      'groupPosts',
    ])
  case SLACK_APP:
    return _.pick(value, [
      'branded',
      'syncComments',
      'syncReactions',
      'userConsentEnabled',
    ])
  case FEED:
    return _.pick(value, [
      'enabled',
      'replaceHomeWithAppname',
      'showCompanyCalendar',
      'celebrationsVisibleToAll',
    ])
  case JOURNEYS:
    return _.pick(value, [
      'enabled',
      'stepTypes',
      'newHiresWindowDaysLength',
      'onboardingBuddies',
      'preboarding',
      'offboarding',
      'customJourneys',
    ])
  case ARTICLES:
    return _.pick(value, [
      'emailHtmlLimit',
      'emailDistributionLists',
    ])
  case EXTERNAL_ALERTS:
    return _.pick(value, [
      'enabled',
      'replyToEmail',
    ])
  case EXTRA_USER_FIELDS:
    return _.pick(value, [
      'fields',
    ])
  case SHOUTOUTS:
    return _.pick(value, [
      'enabled',
      'maxSelectableCompanyValues',
      'minSelectableCompanyValues',
      'suggestions',
      'autoFollow',
    ])
  case SOFT_LAUNCH_WHITELIST:
    return _.pick(value, [
      'enabled',
      'newsEmailsForAllUsers',
      'grantAccessWhenAddedToAJourney',
      'grantAccessWhenMentioned',
      'showInviteToClearyButton',
      'autoSoftLaunchAccessGranting',
      'welcomeEmailEnabled',
    ])
  case TARGETING_OPTIONS:
    return _.pick(value, [
      'cities',
      'jobFamilies',
      'departments',
      'workerTypes',
      'regions',
      'countries',
      'officeLocations',
      'managersOnly',
      'restrictedUsers',
      'reports',
      'titles',
      'tenure',
    ])
  case LEGAL_NOTICES:
    return _.pick(value, [
      'nuxModal',
      'contentFooter',
    ])
  case REACTIONS:
    return _.pick(value, [
      'type',
    ])
  case AI_TEXT_GENERATION:
    return _.pick(value, [
      'enabled',
      'defaultModel',
    ])
  case AI_ASSISTANT_ANSWERS:
    return _.pick(value, [
      'enabled',
      'slackAppEnabled',
      'autoGenerateFaqs',
    ])
  case EDITOR:
    return _.pick(value, [
      'allowedFonts',
    ])
  case GOOGLE_DRIVE_BLOCK_LIST:
    return _.pick(value, [
      'userEmails',
    ])
  case NEW_UI:
    return _.pick(value, [
      'enabled',
      'allowUserPreview',
    ])
  default:
    return {}
  }
}

export const buildCompanyConfigurationPayload = (name, value) => ({
  id: name,
  value: buildValuePayload(name, value),
})

const companyConfigurationSlice = createSlice({
  name: 'companyConfigurations',
  initialState,
  reducers: {
    ...defaultActions,
  },
})

const asyncActions = {
  admin: {
    fetch: (name: string) => async (dispatch) => {
      dispatch(companyConfigurationSlice.actions.isLoading(true))

      try {
        const response = await API.admin.companyConfigurations.fetch(name)
        // using names as ids because many companyConfigurations have a null id
        const companyConfiguration = response.data.data
        companyConfiguration.id = companyConfiguration.attributes.name
        dispatch(entitySlice.actions.add({ data: { data: companyConfiguration } }))
      } catch (e) {
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(companyConfigurationSlice.actions.setError(error))
      } finally {
        dispatch(companyConfigurationSlice.actions.isLoading(false))
      }
    },

    update: (
      name: string,
      value: any,
      { onSuccess, onError }: {onSuccess?: () => void, onError?: (e: any) => void} = {}
    ) => async (dispatch) => {
      dispatch(companyConfigurationSlice.actions.isSaving(true))

      try {
        const response = await API.admin.companyConfigurations.update(buildCompanyConfigurationPayload(name, value))
        // using names as ids because many companyConfigurations have a null id
        const companyConfiguration = response.data.data
        companyConfiguration.id = companyConfiguration.attributes.name
        dispatch(entitySlice.actions.update({ data: { data: companyConfiguration } }))

        if (onSuccess) onSuccess()
      } catch (e) {
        if (onError) onError(e)
        appSignal.sendErrorUnlessClearyBackendError(e)
        const { error } = checkForError(e.response)
        dispatch(companyConfigurationSlice.actions.setError(error))
      } finally {
        dispatch(companyConfigurationSlice.actions.isSaving(false))
      }
    },
  },
}

const selectors = {
  getMetaData: () => (state: ReduxState) => state.companyConfigurations.meta,

  getCompanyConfiguration: (name: string) => (state: ReduxState) => build(state.entities, 'companyConfiguration', name),
}

export default {
  ...companyConfigurationSlice,
  selectors,
  asyncActions,
}
