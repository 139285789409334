import Axios from 'axios'
import searchURI from 'services/searchURI'

const aiEvaluationAnswers = {
  fetchAll(runId, opts) {
    const options = { page: 1, ...opts }

    return Axios.get(searchURI(`/api/admin/ai/evaluation/runs/${runId}/answers`, options))
  },

  fetch(answerId) {
    return Axios.get(`/api/admin/ai/evaluation/answers/${answerId}`)
  },
}

export default aiEvaluationAnswers
