import { useAskAiContext } from 'components/ai/chat/ask_ai_modal/askAiContext'
import { AiMessageTypeEnum, AiMessageType } from 'components/ai/chat/ask_ai_modal/types'
import { ButtonSmallNarrow } from 'components/common/buttons'
import TicketEscalationForm from 'components/ticketing/ticketEscalationForm'
import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'

const AiMessageFooter = ({ message }: { message: AiMessageType }) => {
  if (message.type === AiMessageTypeEnum.OFFER_TICKET_ESCALATION) {
    return <OfferTicketEscalationFooter message={message} />
  }

  if (message.type === AiMessageTypeEnum.TICKET_FORM) {
    return <TicketEscalationForm aiChatMessage={message} />
  }

  return null
}

const OfferTicketEscalationFooter = ({ message }: { message: AiMessageType }) => {
  const { onNewMessage, messages } = useAskAiContext()

  if (message.id !== messages[messages.length - 1].id) {
    return null
  }

  return (
    <div className='d-flex justify-content-end gap-3 mt-3'>
      <ButtonSmallNarrow
        variant='secondary'
        onClick={() => onNewMessage({ content: I18NCommon('no') })}
      >
        {I18NCommon('no')}
      </ButtonSmallNarrow>
      <ButtonSmallNarrow
        variant='secondary'
        onClick={() => onNewMessage({ content: I18NCommon('yes') })}
      >
        {I18NCommon('yes')}
      </ButtonSmallNarrow>
    </div>
  )
}

export default AiMessageFooter
