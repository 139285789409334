import React, {
  useEffect,
  useMemo,
  useState
} from 'react'
import { Background, ReactFlow } from '@xyflow/react'
import { i18nPath } from 'utils/i18nHelpers'
import { RootSidebar } from 'components/common/sidebar'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { ActionType, WorkflowType } from 'types/workflow'
import ActionIcon from 'components/workflows/actionIcon'
import AddActionEdge from './actions/addActionEdge'
import ActionForm from './actions/actionForm'
import CreateActionModal from './actions/createActionModal'
import ActionNode from './actions/actionNode'
import AddActionNode from './actions/addActionNode'

const nodeTypes = {
  action: ActionNode,
  add_action: AddActionNode,
}

const edgeTypes = {
  add_action: AddActionEdge,
}

type Props = {
  workflow: WorkflowType
  onWorkflowUpdate: (workflowId: string) => void
}

const I18N = i18nPath('views.admin.workflows.actions')

const Actions = ({ workflow, onWorkflowUpdate }: Props) => {
  const [selectedAction, setSelectedAction] = useState<ActionType | null>(null)
  const [showCreateActionModal, setShowCreateActionModal] = useState(false)
  const [currentPosition, setCurrentPosition] = useState<number | 'last'>('last')
  const [rawNodes, setRawNodes] = useState<any[]>([])
  const [edges, setEdges] = useState<any[]>([])

  const nodes = useMemo(() => rawNodes.map(node => ({
    ...node,
    selected: node.id === selectedAction?.id,
  })), [rawNodes, selectedAction])

  const [deleteAction] = useApi(API.admin.workflows.actions.destroy, {
    onSuccess: () => {
      onWorkflowUpdate(workflow.id)
      setSelectedAction(null)
    },
  })

  const [updateAction, { isLoading: isUpdatingAction }] = useApi(
    API.admin.workflows.actions.update,
    {
      onSuccess: (action) => {
        onWorkflowUpdate(workflow.id)
        setSelectedAction(action)
      },
    }
  )

  const [runActionTest, { isLoading: isRunningTest }] = useApi(
    API.admin.workflows.actions.runTests,
    {
      onSuccess: (action) => {
        setSelectedAction(action)
        onWorkflowUpdate(workflow.id)
      },
    }
  )

  const handleNodeClick = (_event, node) => {
    if (node.id === 'add-action') {
      openCreateActionModal('last')
    } else {
      setSelectedAction(node.data.workflowAction)
    }
  }

  const handleCreateAction = (action) => {
    setShowCreateActionModal(false)
    onWorkflowUpdate(workflow.id)
    setSelectedAction(action)
  }

  const handleUpdateAction = async (settings, configuration, callback) => {
    const action = await updateAction(workflow.id, selectedAction!.id, { settings, configuration })

    callback?.(action)
  }

  const openCreateActionModal = (position: number | 'last') => {
    setCurrentPosition(position)
    setShowCreateActionModal(true)
  }

  const handleRunTest = (testConfiguration) => {
    if (!selectedAction) return

    runActionTest(workflow.id, selectedAction.id, testConfiguration)
  }

  useEffect(() => {
    if (!workflow) return

    const newNodes: any[] = []
    const newEdges: any[] = []
    setRawNodes([])
    setEdges([])

    workflow.actions.forEach((action, index) => {
      newNodes.push({
        id: action.id,
        type: 'action',
        selectable: false,
        data: {
          index,
          label: action.name,
          workflowAction: action,
          isFirst: index === 0,
          onDelete: () => deleteAction(workflow.id, action.id),
        },
        position: { x: 0, y: index * 175 },
      })
    })

    newNodes.push({
      id: 'add-action',
      type: 'add_action',
      selectable: false,
      position: { x: 0, y: workflow.actions.length * 160 },
      data: {
        isFirst: workflow.actions.length === 0,
      },
    })

    for (let i = 1; i < workflow.actions.length; i += 1) {
      newEdges.push({
        id: `${workflow.actions[i - 1].id}-${workflow.actions[i].id}`,
        source: workflow.actions[i - 1].id,
        target: workflow.actions[i].id,
        selectable: false,
        type: 'add_action',
        data: {
          onEdgeClick: () => openCreateActionModal(i),
        },
        style: {
          stroke: '#5246D7',
          strokeWidth: 2,
        },
      })
    }

    if (workflow.actions.length > 0) {
      newEdges.push({
        id: `${workflow.actions[workflow.actions.length - 1].id}-add-action`,
        source: workflow.actions[workflow.actions.length - 1].id,
        target: 'add-action',
        selectable: false,
        style: {
          stroke: '#5246D7',
          strokeWidth: 2,
        },
      })
    }

    setRawNodes(newNodes)
    setEdges(newEdges)
  }, [workflow])

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodeClick={handleNodeClick}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitView
        fitViewOptions={{
          padding: 0.4,
          maxZoom: 1.0,
          minZoom: 0.1,
        }}
      >
        <Background />
      </ReactFlow>

      {selectedAction && (
        <RootSidebar
          isOpen
          onClose={() => setSelectedAction(null)}
          title={(
            <div className='d-flex align-items-center gap-2'>
              <ActionIcon action={selectedAction} />
              <span className='font-weight-600 text-normal'>{I18N(`${selectedAction.name}.label`)}</span>
            </div>
          )}
          className='WorkflowEditPage__RootSidebar'
          ignoredSelectorsWhenClickOutside={['.WorkflowEditPage__Actions__ActionNode', '.modal-dialog']}
        >
          <ActionForm
            key={selectedAction.id}
            action={selectedAction}
            isLoading={isUpdatingAction}
            onSave={handleUpdateAction}
            onRunTest={handleRunTest}
            isRunningTest={isRunningTest}
          />
        </RootSidebar>
      )}

      {showCreateActionModal && (
        <CreateActionModal
          workflowId={workflow.id}
          onCancel={() => setShowCreateActionModal(false)}
          position={currentPosition}
          onCreate={handleCreateAction}
        />
      )}
    </>
  )
}

export default Actions
