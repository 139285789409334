import React, { ReactElement } from 'react'
import { getPropertyListWithValues } from 'components/admin/customStyles/utils'
import { useCustomStyles, useVariables } from 'pages/admin/custom_styles/context'
import Card from 'components/common/card'
import FontFamilyControl from './fontFamilyControl'
import FontSizeControl from './fontSizeControl'
import FontWeightControl from './fontWeightControl'

const TypographySection = (): ReactElement => {
  const { handlePropertyChanged } = useCustomStyles()
  const { fonts, fontWeight, fontFamily } = useVariables(['fonts', 'fontWeight', 'fontFamily'])

  const fontVariables = getPropertyListWithValues({ variables: fonts, type: 'fonts' })
  const fontWeightVariables = getPropertyListWithValues({ variables: fontWeight, type: 'fontWeight' })
  const fontFamilyVariables = getPropertyListWithValues({ variables: fontFamily, type: 'fontFamily' })

  return (
    <Card className='row'>
      <div className='col-12'>
        <div className='mb-4'>
          {fontFamilyVariables.map(([variable, value]) => (
            <FontFamilyControl
              key={variable}
              variable={variable}
              value={value}
              onChange={handlePropertyChanged}
            />
          ))}
        </div>
        <div>
          {fontVariables.map(([variable, value]) => (
            <FontSizeControl
              key={variable}
              variable={variable}
              value={value}
              onChange={handlePropertyChanged}
            />
          ))}
        </div>
        <div className='mt-4'>
          {fontWeightVariables.map(([variable, value]) => (
            <FontWeightControl
              key={variable}
              variable={variable}
              value={value}
              onChange={handlePropertyChanged}
            />
          ))}
        </div>
      </div>
    </Card>
  )
}

export default TypographySection
