import React from 'react'
import { useDispatch } from 'react-redux'

import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import useUpdatableState from 'components/common/hooks/useUpdatableState'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import currentCompanySlice from 'redux/slices/currentCompany'
import Card from 'components/common/card'
import CheckBox from 'components/common/buttons/checkBox'
import { ButtonSmallNarrow } from 'components/common/buttons'

const I18N = i18nPath('views.settings.default_user_setting.privacy')

const UserSettingsPrivacyTab = () => {
  const dispatch = useDispatch()
  const { settings: { defaultUserPrivacy } } = useCurrentCompany()

  const [workingCopy, setWorkingCopy, updateWorkingCopy] = useUpdatableState(defaultUserPrivacy)

  const hasChanges = _.isEqual(defaultUserPrivacy, workingCopy)

  const onSuccess = (data) => {
    dispatch(currentCompanySlice.actions.updateCurrentCompanySetting({ key: data.name, value: data.value }))
  }

  const [updateConfig, { isLoading: isSaving }] = useApi(API.admin.companyConfigurations.update, {
    onSuccess,
    toastSuccessMessage: I18NCommon('updated_successfully'),
  })

  const handleSave = () => {
    updateConfig({
      id: 'default_user_privacy',
      value: workingCopy,
    })
  }

  return (
    <>
      <Card>
        <h3>{I18N('title')}</h3>
        <p>{I18N('description')}</p>

        <CheckBox
          id='phone_mobile'
          label={I18N('phone')}
          checked={workingCopy.phoneMobile}
          onChange={() => updateWorkingCopy({ phoneMobile: !workingCopy.phoneMobile })}
        />

        <CheckBox
          id='city'
          label={I18N('city')}
          checked={workingCopy.city}
          onChange={() => updateWorkingCopy({ city: !workingCopy.city })}
        />

        <ButtonSmallNarrow
          onClick={handleSave}
          showLoadingSpinner={isSaving}
          className='mt-2'
          disabled={hasChanges || isSaving}
        >
          {I18NCommon('save')}
        </ButtonSmallNarrow>
      </Card>
    </>
  )
}

export default UserSettingsPrivacyTab

