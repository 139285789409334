// this hook is used to make the scrollable element sticky to the bottom

import { useEffect, useRef, useState } from 'react'

// User needs to scroll 20px to the bottom to trigger sticky scroll
const BOTTOM_THRESHOLD = 20
const TOP_THRESHOLD = 20
const DIRECTION_THRESHOLD = 200 // user needs to scroll 200px to change direction

// also "fixes" the scroll when adding new message to the beginning of the list
export const useStickyScroll = () => {
  const scrollableRef = useRef<HTMLDivElement>(null)
  const shouldStickyScrollAtTheBottom = useRef(true)
  const latestScrollHeight = useRef(0)
  const isScrollAtTheTopRef = useRef(false)
  const [isScrollAtTheTop, setIsScrollAtTheTop] = useState(false)
  const [scrollingDirection, setScrollingDirection] = useState<'up' | 'down'>('down')
  const isFixingScrollRef = useRef(false)
  const previousScrollTop = useRef(0)

  useEffect(() => {
    if (!scrollableRef.current) return

    const onScroll = () => {
      // The component might be unmounted while the timeout is running
      if (!scrollableRef.current) return
      if (isFixingScrollRef.current) return

      // check if user is scrolling up to stop sticky scroll
      const {
        scrollTop,
        clientHeight,
        scrollHeight,
      } = scrollableRef.current

      const isScrollAtTheBottom = scrollTop + clientHeight + BOTTOM_THRESHOLD >= scrollHeight

      shouldStickyScrollAtTheBottom.current = isScrollAtTheBottom
      latestScrollHeight.current = scrollHeight
      setIsScrollAtTheTop(scrollTop <= TOP_THRESHOLD)
      isScrollAtTheTopRef.current = scrollTop <= TOP_THRESHOLD

      if (isScrollAtTheBottom) {
        setScrollingDirection('down')
      } else if (Math.abs(scrollTop - previousScrollTop.current) > DIRECTION_THRESHOLD) {
        setScrollingDirection(scrollTop > previousScrollTop.current ? 'down' : 'up')
        previousScrollTop.current = scrollTop
      }
    }

    scrollableRef.current.addEventListener('scroll', onScroll)

    let fixScrollTimeout

    const fixScroll = () => {
      if (!scrollableRef.current) return

      const {
        scrollHeight,
      } = scrollableRef.current

      clearTimeout(fixScrollTimeout)

      isFixingScrollRef.current = true

      if (shouldStickyScrollAtTheBottom.current) {
        // set the scroll to the bottom
        // useful when adding new messages to the end of list
        scrollableRef.current.scrollTop = scrollHeight
      } else if (isScrollAtTheTopRef.current) {
        // make sure to set the scroll "remains at the same place" when adding new messages.
        scrollableRef.current.scrollTop = scrollHeight - latestScrollHeight.current
      }

      // give some time to new elements to render and recalculate the scroll top
      fixScrollTimeout = setTimeout(() => {
        // The component might be unmounted while the timeout is running
        if (!scrollableRef.current) return

        previousScrollTop.current = scrollableRef.current!.scrollTop
        isFixingScrollRef.current = false

        // After the elements are rendered, there can be a case where the scroll still at the top
        // and we need to fetch again more items, ie, we need to recalculate if scroll is at the top to fetch it again.
        setTimeout(onScroll, 500)
      }, 100)
    }

    // add a mutation observer to the scrollable element
    const observer = new MutationObserver(fixScroll)
    fixScroll()

    observer.observe(scrollableRef.current, { childList: true, subtree: true })
  }, [scrollableRef])

  return {
    scrollableRef,
    isScrollAtTheTop,
    scrollingDirection,
  }
}

export default useStickyScroll
