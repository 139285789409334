import Axios from 'axios'
import qs from 'qs'
import snakeCaseKeys from 'utils/snakeCaseKeys'

const buildFeedbackPayload = record => _.pick(record, [
  'comment',
  'options',
])

const assistantApi = {
  answers: {
    markCorrect(answerId) {
      return Axios.patch(`/api/ai/assistant/answers/${answerId}/mark_correct`)
    },

    markIncorrect(answerId) {
      return Axios.patch(`/api/ai/assistant/answers/${answerId}/mark_incorrect`)
    },

    removeFeedback(answerId) {
      return Axios.delete(`/api/ai/assistant/answers/${answerId}/remove_feedback`)
    },
  },

  answerFeedbacks: {
    create(answerId, record) {
      return Axios.post(`/api/ai/assistant/answers/${answerId}/answer_feedbacks`, { answerFeedback: buildFeedbackPayload(record) })
    },
  },

  knowledgeIngestions: {
    fetchAll({ onlyInProgress = false } = {}) {
      const query = onlyInProgress ? '?only_in_progress=true' : ''

      return Axios.get(`/api/ai/assistant/knowledge_ingestions${query}`)
    },

    fetch(knowledgeIngestionId) {
      return Axios.get(`/api/ai/assistant/knowledge_ingestions/${knowledgeIngestionId}`)
    },

    create(record, source = 'wizard') {
      return Axios.post(
        '/api/ai/assistant/knowledge_ingestions',
        { knowledgeIngestion: record, source }
      )
    },

    fetchFAQs(knowledgeIngestionId, { lastId = null }) {
      const params = lastId ? qs.stringify(snakeCaseKeys({ lastId })) : ''

      return Axios.get(`/api/ai/assistant/knowledge_ingestions/${knowledgeIngestionId}/faqs?${params}`)
    },

    fetchTopThreeFAQs(knowledgeIngestionId) {
      return Axios.get(`/api/ai/assistant/knowledge_ingestions/${knowledgeIngestionId}/top_three_faqs`)
    },
  },

  activeChannels: {
    fetchAll() {
      return Axios.get('/api/ai/assistant/active_channels')
    },
  },

  dashboardData: {
    fetch(params = {}) {
      const query = qs.stringify(snakeCaseKeys(params))

      return Axios.get(`/api/admin/ai/assistant/dashboard_data?${query}`)
    },
  },

  channelConfigurations: {
    fetchAll(params = {}) {
      const query = qs.stringify(snakeCaseKeys(params))

      return Axios.get(`/api/admin/ai/assistant/channel_configurations?${query}`)
    },

    update(id, record) {
      const payload = {
        enabled: record.enabled,
        faqCreationEnabled: record.faqCreationEnabled,
        faqCreationMode: record.faqCreationMode,
        ticketEscalationPolicy: record.ticketEscalationPolicy,
        ticketEscalationRule: record.ticketEscalationRule,
        workspaceIds: record.workspaces.map(workspace => workspace.id),
        userIds: record.users.map(user => user.id),
      }

      return Axios.patch(
        `/api/admin/ai/assistant/channel_configurations/${id}`,
        { channel_configuration: payload }
      )
    },
  },
}

export default assistantApi
