import React from 'react'
import { i18nPath, I18NCommon } from 'utils/i18nHelpers'

import CollapsibleCard from 'components/common/collapsibleCard'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import moment from 'moment'
import DatePicker from 'components/form_fields/datePicker'
import FormTimezoneSelectField from 'components/form_fields/formTimezoneSelectField'
import ReactSelect from 'components/common/react_select'
import { Radio, RadioGroup } from 'react-radio-group'
import classNames from 'classnames'
import CalendarEventDetailsTitleAndDescriptionSection from 'components/admin/journeys/common/calendarEventDetailsTitleAndDescriptionSection'
import FormCheck from 'components/form_fields/formCheck'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'

const I18N = i18nPath('views.admin.journeys.introduction_templates.calendar_meeting_details_section')

const MeetingDurationSelector = ({
  workingCopy,
  isDisabled,
  updateWorkingCopy,
}) => (
  <LabeledFormFieldContainer
    isRequired
    className='w-50 pr-2'
    label={I18N('meeting_duration_label')}
  >
    <div className='d-flex justify-content-start align-items-center'>
      <input
        className='minutes-input'
        name='meeting_duration'
        type='number'
        min='1'
        value={workingCopy.meetingDuration}
        disabled={isDisabled}
        onChange={({ target }) => updateWorkingCopy({ meetingDuration: target.value })}
      />
      <span className='ml-2'>{I18N('minutes')}</span>
    </div>
  </LabeledFormFieldContainer>
)

const TimeWindowsSelector = ({
  label = I18N('timeframe'),
  footNote = I18N('timeframe_footnote'),
  workingCopy,
  isDisabled,
  updateWorkingCopy,
}) => (
  <LabeledFormFieldContainer
    isRequired
    label={label}
    footNote={footNote}
    className='w-50 pr-2'
  >
    <div className='d-flex align-items-center'>
      <DatePicker
        id='hourWindowStart'
        selected={workingCopy.hourWindowStart && moment(workingCopy.hourWindowStart).utc()}
        onChange={newTime => updateWorkingCopy({ hourWindowStart: newTime.utc().format() })}
        showTimeSelect
        showTimeSelectOnly
        dateFormat='h:mm a'
        isClearable={false}
        isDisabled={isDisabled}
      />
      <span className='ml-2 mr-2'>{I18N('to')}</span>
      <DatePicker
        id='hourWindowEnd'
        selected={workingCopy.hourWindowEnd && moment(workingCopy.hourWindowEnd).utc()}
        onChange={newTime => updateWorkingCopy({ hourWindowEnd: newTime.utc().format() })}
        showTimeSelect
        showTimeSelectOnly
        dateFormat='h:mm a'
        isClearable={false}
        isDisabled={isDisabled}
      />
    </div>
  </LabeledFormFieldContainer>
)

const ExternalCalendarSelector = ({
  workingCopy,
  updateWorkingCopy,
  isDisabled,
}) => {
  const { data: externalCalendars } = useFetch(API.admin.externalCalendars.fetchAll)

  const options = [{
    value: '',
    label: I18N('organizers_calendar'),
  }, ...(externalCalendars || []).map(calendar => ({
    value: calendar.id,
    label: calendar.displayName,
  }))]

  const selectedExternalCalendarId = workingCopy.externalCalendarId || ''

  return (
    <LabeledFormFieldContainer
      label={I18N('calendar')}
      className='w-50 pr-2'
    >
      <ReactSelect
        name='externalCalendarId'
        getOptionValue={option => option.value}
        getOptionLabel={option => option.label}
        value={options.find(option => option.value === selectedExternalCalendarId)}
        options={options}
        onChange={e => updateWorkingCopy({ externalCalendarId: e.value || '' })}
        isDisabled={isDisabled}
      />
    </LabeledFormFieldContainer>
  )
}

const FallbackTimezoneSelector = ({
  id,
  label,
  footNote,
  workingCopy,
  updateWorkingCopy,
  isDisabled,
}) => (
  <FormTimezoneSelectField
    className='w-50 pl-2'
    isRequired
    label={label}
    id={id}
    footNote={footNote}
    currentValue={workingCopy.fallbackTimezone}
    onChange={timezone => updateWorkingCopy({ fallbackTimezone: timezone?.timezone })}
    isDisabled={isDisabled}
  />
)


const CalendarMeetingDetailsSection = ({
  cardTitle = I18N('calendar_meeting_details'),
  workingCopy,
  updateWorkingCopy,
  journeyBlueprint,
  isDisabled,
  isIntroductionTemplate = true,
  scheduleMeetingsAutomatically = true,
}) => {
  const journeyBlueprintType = journeyBlueprint?.type

  return (
    <CollapsibleCard title={cardTitle} className='mb-4 mt-4'>
      <section className='CalendarMeetingDetailsSection mt-3 mb-3'>
        {isIntroductionTemplate && (
          <LabeledFormFieldContainer
            isRequired
            label={(
              <>
                <label className='required-form-label mb-0'>{I18N('automatically_schedule_label')}</label>
                <p className='form-text text-secondary'>{I18N(`automatically_schedule_description.${journeyBlueprintType}`)}</p>
              </>
            )}
          >
            <div>
              <RadioGroup
                className={classNames('automatically-schedule-radio-container d-flex align-items-center justify-content-start')}
                name='automatically_schedule_calendar_invites'
                selectedValue={scheduleMeetingsAutomatically}
                onChange={value => updateWorkingCopy({ automaticallyScheduleCalendarInvites: value })}
              >
                <Radio className='mr-1' value={true} disabled={isDisabled} />
                {I18NCommon('yes')}
                <Radio className='mr-1 ml-5' value={false} disabled={isDisabled} />
                {I18NCommon('no')}
              </RadioGroup>
            </div>
          </LabeledFormFieldContainer>
        )}
        {scheduleMeetingsAutomatically && (
          <>
            <ExternalCalendarSelector
              workingCopy={workingCopy}
              updateWorkingCopy={updateWorkingCopy}
              isDisabled={isDisabled}
            />
            <div className='d-flex mt-2'>
              <MeetingDurationSelector
                workingCopy={workingCopy}
                isDisabled={isDisabled}
                updateWorkingCopy={updateWorkingCopy}
              />
              {isIntroductionTemplate && (
                <LabeledFormFieldContainer
                  isRequired
                  className='w-50 pl-2'
                  label={I18N('max_introductions_scheduled_per_day')}
                >
                  <div className='d-flex justify-content-start align-items-center'>
                    <input
                      className='max-meetings-per-day-input w-10'
                      name='max_meetings_per_day'
                      type='number'
                      min='1'
                      disabled={isDisabled}
                      value={workingCopy.maxInvitesPerDay}
                      onChange={({ target }) => updateWorkingCopy({ maxInvitesPerDay: target.value })}
                    />
                  </div>
                </LabeledFormFieldContainer>
              )}

            </div>
            <div className='d-flex'>
              <TimeWindowsSelector
                workingCopy={workingCopy}
                isDisabled={isDisabled}
                updateWorkingCopy={updateWorkingCopy}
              />
              <FallbackTimezoneSelector
                workingCopy={workingCopy}
                updateWorkingCopy={updateWorkingCopy}
                isDisabled={isDisabled}
                label={I18N('timezone')}
                id='introduction_form_timezone_select'
                footNote={I18N(`timezone_footnote.${journeyBlueprintType}`)}
              />
            </div>

            <div>
              <FormCheck
                name='create-video-vonference'
                label={I18N('create_video_conference')}
                checked={workingCopy.createVideoConference}
                onChange={e => updateWorkingCopy({ createVideoConference: e.target.checked })}
                id='create-video-vonference'
                className='mb-3'
                disabled={isDisabled}
              />
            </div>
          </>
        )}
        {isIntroductionTemplate && (
          <CalendarEventDetailsTitleAndDescriptionSection
            workingCopy={workingCopy}
            updateWorkingCopy={updateWorkingCopy}
            journeyBlueprint={journeyBlueprint}
            isDisabled={isDisabled}
            scheduleMeetingsAutomatically={scheduleMeetingsAutomatically}
            isIntroductionTemplate
          />
        )}
      </section>
    </CollapsibleCard>
  )
}

export default CalendarMeetingDetailsSection
