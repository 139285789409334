import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'

import Setting from 'pages/admin/user_settings/setting'
import NotifyInput from 'pages/admin/user_settings/inputs/notifyInput'
import EnabledInput from 'pages/admin/user_settings/inputs/enabledInput'
import Card from 'components/common/card'


const UserSettingsEventsTab = () => (
  <Card>
    <h3>{I18NCommon('events')}</h3>

    <Setting
      name='qna.new_questions_notifications'
      inputComponent={NotifyInput}
    />

    <Setting
      name='qna.event_closing_notification'
      inputComponent={EnabledInput}
    />

    <Setting
      name='qna.activity_involving_me'
      inputComponent={NotifyInput}
      showDivider={false}
    />
  </Card>
)

export default UserSettingsEventsTab
