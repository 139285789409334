import React from 'react'
import { Handle, Position } from '@xyflow/react'
import CdnSvg from 'components/common/cdnSvg'
import classNames from 'classnames'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import ClearyTooltip from 'components/common/clearyTooltip'

const I18N = i18nPath('views.admin.workflows.actions')

const AddActionNode = ({ data }) => (
  <div
    className={classNames('WorkflowEditPage__Actions__AddActionNode d-flex align-items-center justify-content-center', {
      'WorkflowEditPage__Actions__AddActionNode--first': data.isFirst,
    })}
  >
    <Handle
      type='target'
      position={Position.Top}
      style={{
        backgroundColor: 'transparent',
        border: '0',
      }}
    />
    <div className='d-flex align-items-center gap-2'>
      <ClearyTooltip
        content={<CdnSvg src='/images/plusIcon.svg' />}
        placement='bottom'
      >
        <span>{I18N('add_action_tooltip')}</span>
        {data.isFirst && <span>{I18NCommon('add_action')}</span>}
      </ClearyTooltip>
    </div>
  </div>
)

export default AddActionNode
