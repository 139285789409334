import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { ChromePicker } from 'react-color'
import { I18NCommon, i18nFormat, i18nPath } from 'utils/i18nHelpers'
import { isAudienceValid } from 'utils/audience'
import FormErrorList from 'components/errors/formErrorList'
import { LoadingContainer } from 'components/common/loadingContainer'
import { Button } from 'components/common/buttons'
import InfoTooltip from 'components/common/infoTooltip'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import ReactSelect from 'components/common/react_select'
import calendarsSlice from 'redux/slices/calendars'
import AudienceSelector from 'components/common/audience/audienceSelector'
import { TARGET_ENTIRE_COMPANY } from 'utils/normalizeTargetingRules'
import useApi from 'components/common/hooks/useApi'
import API, { buildExternalCalendarPayload } from 'services/api'
import useTargetingOptions from 'hooks/audience/useTargetingOptions'
import CheckBox from 'components/form_fields/checkBox'

const I18N = i18nPath('views.admin.external_calendar_editor')
const calendarIdTutorialUrl = 'https://docs.google.com/document/d/1gDo3cfs6u56JmHGkCvJQhnKsZHTeSFVbv91jIWvEUds/'

const defaultWorkingCopy = microsoftAzureAppCalendarsEnabled => ({
  id: '',
  calendarUrl: '',
  calendarId: '',
  calendarOwnerEmail: '',
  color: null,
  displayName: '',
  internalName: '',
  visibleOnHomepage: true,
  calendarType: microsoftAzureAppCalendarsEnabled ? 'microsoft' : 'google',
  targetingRules: TARGET_ENTIRE_COMPANY,
})

const MicrosoftCalendarSelector = ({
  calendarId = '',
  internalName = '',
  handleCalendarIdChange,
}) => {
  const buildCalendarOption = (calendar) => {
    if (!calendar) return ''

    return {
      value: calendar.id,
      label: calendar.name,
    }
  }

  const buildCurrentValue = () => {
    if (!calendarId) return ''
    return { value: calendarId, label: internalName }
  }

  const dispatch = useDispatch()
  const calendars = useSelector(calendarsSlice.selectors.admin.getCalendars())
  const options = calendars.map(calendar => buildCalendarOption(calendar))
  const { isLoading } = useSelector(calendarsSlice.selectors.admin.getMetaData())

  useEffect(() => {
    dispatch(calendarsSlice.asyncActions.admin.fetchCalendars())
  }, [])

  return (

    <div className='MicrosoftCalendarSelector row'>
      <div className='form-group col'>
        <label className='required-form-label'>{I18N('microsoft_outlook_calendar')}</label>
        <ReactSelect
          id='microsoft_calendar_selector'
          key='calendar_select'
          value={buildCurrentValue()}
          onChange={handleCalendarIdChange}
          options={options}
          isClearable
          isSearchable={false}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

const CalendarUrlInput = ({
  value,
  onChange,
}) => (
  <div className='row'>
    <div className='form-group col calendar-tooltip-wrapper'>
      <label className='required-form-label'>{I18N('calendar_id')}</label>
      <InfoTooltip
        text={i18nFormat(I18N('calendar_id_tooltip'),
          <a target='_blank' rel='noopener noreferrer' href={calendarIdTutorialUrl}>{I18N('here')}</a>
        )}
        tooltipClassName='WhiteToolTip'
      />
      <input value={value} onChange={onChange} />
    </div>
  </div>
)

const ExternalCalendarEditorPage = () => {
  const history = useHistory()
  const currentCompany = useCurrentCompany()

  const ismicrosoftAzureAppCalendarsEnabled = currentCompany?.microsoftAzureAppCalendarsEnabled
  const { externalCalendarId } = useParams()

  const [workingCopy, setWorkingCopy] = useState(defaultWorkingCopy(ismicrosoftAzureAppCalendarsEnabled))

  const isMicrosoftCalendar = workingCopy.calendarType === 'microsoft'

  const [createCalendar, {
    error: errorCreate,
    isLoading: isCreating,
  }] = useApi(API.admin.externalCalendars.create, {
    onSuccess: () => history.push('/admin/external_calendars'),
    toastSuccessMessage: I18NCommon('created_successfully'),
  })

  const [updateCalendar, {
    error: errorUpdate,
    isLoading: isUpdating,
  }] = useApi(API.admin.externalCalendars.update, {
    toastSuccessMessage: I18NCommon('updated_successfully'),
  })

  const [destroyCalendar, {
    isLoading: isDeleting,
  }] = useApi(API.admin.externalCalendars.destroy, {
    onSuccess: () => history.push('/admin/external_calendars'),
    toastSuccessMessage: I18NCommon('deleted_successfully'),
  })

  const [
    fetchCalendar, { data: externalCalendar, isLoading: isFetching, isNotFound },
  ] = useApi(API.admin.externalCalendars.fetch)

  const targetingOptions = useTargetingOptions()

  const {
    displayName,
    calendarId,
    calendarUrl,
    internalName,
  } = workingCopy

  const isNewExternalCalendar = externalCalendarId === 'new'
  const changesPresent = !_.isEqual(externalCalendar, workingCopy)
  const isMissingCalendarFields = isMicrosoftCalendar ? !calendarId : !calendarUrl
  const isMissingRequiredFields = !displayName || !internalName || isMissingCalendarFields
  const isSaveButtonDisabled = isMissingRequiredFields || !changesPresent || !isAudienceValid(workingCopy)
  const isLoading = isCreating || isUpdating || isFetching || isDeleting
  const error = errorCreate || errorUpdate

  useEffect(() => {
    if (!isNewExternalCalendar) {
      fetchCalendar(externalCalendarId)
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(externalCalendar)) {
      setWorkingCopy(externalCalendar)
    }
  }, [externalCalendar?.updatedAt])

  const generateChangeHandler = fieldName => (e) => {
    const changedExternalCalendar = { ...workingCopy, [fieldName]: e.target.value }
    setWorkingCopy(changedExternalCalendar)
  }

  const generateCheckboxChangeHandler = fieldName => (e) => {
    const changedExternalCalendar = { ...workingCopy, [fieldName]: e.target.checked }
    setWorkingCopy(changedExternalCalendar)
  }

  const saveExternalCalendar = async () => {
    if (workingCopy.id) {
      updateCalendar(buildExternalCalendarPayload(workingCopy))
    } else {
      createCalendar(buildExternalCalendarPayload(workingCopy))
    }
  }

  const deleteExternalCalendar = () => {
    if (confirm(I18N('delete_confirm'))) {
      destroyCalendar(workingCopy)
    }
  }

  const handleAudienceChange = (values = {}) => {
    setWorkingCopy({ ...workingCopy, ...values })
  }

  const handleColorChange = (color) => {
    setWorkingCopy({ ...workingCopy, color: color.hex })
  }

  const handleCalendarIdChange = (option) => {
    setWorkingCopy({
      ...workingCopy, calendarId: option?.value, internalName: option?.label,
    })
  }

  return (
    <div className='ExternalCalendarEditorPage'>
      <header className='AdminHeader'>
        <h3>{I18N(isNewExternalCalendar ? 'new_title' : 'edit_title')}</h3>
      </header>

      <main className='AdminContent'>
        <LoadingContainer isLoading={isLoading} isNotFound={isNotFound}>
          <div className='container my-4'>

            {error && <FormErrorList error={error} />}
            <div className='row'>
              <div className='col-md-7'>
                <div className='row'>
                  <div className='form-group col'>
                    <label className='required-form-label'>{I18N('display_name')}</label>
                    <input value={workingCopy.displayName} onChange={generateChangeHandler('displayName')} />
                  </div>
                </div>

                {isMicrosoftCalendar && (
                  <MicrosoftCalendarSelector
                    calendarId={calendarId}
                    internalName={internalName}
                    handleCalendarIdChange={handleCalendarIdChange}
                  />
                )}

                {!isMicrosoftCalendar && (
                  <>
                    <div className='row'>
                      <div className='form-group col'>
                        <label className='required-form-label'>{I18N('internal_name')}</label>
                        <input value={workingCopy.internalName} onChange={generateChangeHandler('internalName')} />
                      </div>
                    </div>

                    <CalendarUrlInput
                      value={workingCopy.calendarUrl}
                      onChange={generateChangeHandler('calendarUrl')}
                    />
                  </>
                )}

                <CheckBox
                  fieldName='visibleOnHomepage'
                  attributeValue={workingCopy.visibleOnHomepage}
                  onChangeHandler={generateCheckboxChangeHandler('visibleOnHomepage')}
                  label={I18N('visible_on_homepage')}
                />

                <div className='row'>
                  <div className='form-group col'>
                    <label>{I18N('color')}</label>
                    <ChromePicker color={workingCopy.color || '#fff'} onChange={handleColorChange} />
                  </div>
                </div>

                <div className='row'>
                  <div className='form-group col'>
                    <Button disabled={isSaveButtonDisabled} onClick={saveExternalCalendar}>
                      {I18N('save_external_calendar')}
                    </Button>
                    {!isNewExternalCalendar && (
                      <Button variant='danger' className='ml-2' onClick={deleteExternalCalendar}>
                        {I18N('delete')}
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <div className='col-md-5'>
                <AudienceSelector
                  workingCopy={workingCopy}
                  updateWorkingCopy={handleAudienceChange}
                  targetingOptions={targetingOptions}
                  isLoading={isLoading}
                  moduleName='external_calendars'
                />
              </div>
            </div>
          </div>
        </LoadingContainer>
      </main>
    </div>
  )
}

export default ExternalCalendarEditorPage
