import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { i18nPath, i18nMoment, i18nFormat } from 'utils/i18nHelpers'
import UserLink from 'components/common/userLink'
import StatusLabel from 'components/common/status_label'
import difference from 'utils/deepDiff'

const I18N = i18nPath('views.versions_table')

const Value = ({ change, textStyle }) => {
  const hasValue = change !== '(none)'
  const additionalClass = hasValue ? textStyle : 'text-secondary'

  return <p className={`mx-1 d-inline ${additionalClass}`}>{change}</p>
}

const CompleteDifferences = ({
  changes = [],
  isCreateEvent = false,
}) => {
  const beforeChangeValue = changes[0] || I18N('none')
  const afterChangeValue = changes[1] || I18N('none')

  if (isCreateEvent) {
    return <Value change={afterChangeValue} textStyle='text-inserted' />
  }

  return (
    <>
      {
        i18nFormat(
          I18N('updated_from_to'),
          <Value change={beforeChangeValue} textStyle='text-deleted' />,
          <Value change={afterChangeValue} textStyle='text-inserted' />
        )
      }
    </>
  )
}

export const VersionsList = ({ changeSet, ignoredFields, isCreateEvent = false }) => (
  <ul className='list-group list-unstyled'>
    {changeSet.map(([field, rawChanges]) => {
      let changes = _.clone(rawChanges)

      // isObject returns true for arrays
      // so we need to check if both are arrays
      const bothNotArray = !Array.isArray(changes[0]) && !Array.isArray(changes[1])

      // Jsonb fields are stored as objects
      // objects can be potentially big and sometimes they changes just small
      // part (a deep key for example)
      // this will show only what changed for the 2 objects
      if (bothNotArray && _.isObject(changes[0]) && _.isObject(changes[1])) {
        changes = [
          difference(changes[0], changes[1]),
          difference(changes[1], changes[0]),
        ]
      }

      const diffsParsedToString = changes.map((change) => {
        if (_.isNull(change)) return 'null'
        if (Array.isArray(change)) return change.map(item => item).join(', ')
        if (_.isObject(change)) return JSON.stringify(change)

        return change.toString()
      })

      if (field === 'nid' || field === 'companyId') return null
      if (ignoredFields.includes(field)) return null

      const completeDiff = <CompleteDifferences changes={diffsParsedToString} isCreateEvent={isCreateEvent} />

      return (
        <li key={field} className='mb-2'>
          <>
            <span className='text-secondary font-weight-500 d-inline mr-2 text-capitalize'>
              {field}
:
            </span>
            <span className='Changes_group'>
              {diffsParsedToString.length > 1 && completeDiff}
            </span>
          </>
        </li>
      )
    })}
  </ul>
)

export const WhoDidIt = ({ version }) => {
  if (version?.whodunnitType === 'User') {
    return (
      <UserLink
        user={version.whodidit}
        showLinkColor={false}
        customClassName='font-weight-500 text-normal text-primary'
        avatarSize='32px'
      />
    )
  }

  if (version?.whodunnitType?.includes('UserImports::')) {
    return (
      <Link
        to={`/admin/user_imports/${version.whodidit.id}`}
      >
        <span className='font-weight-500'>{I18N('user_imports_name', { name: version.whodidit.name })}</span>
      </Link>
    )
  }

  return (
    <span className='text-primary font-weight-500'>{version?.whodidit?.name}</span>
  )
}

const VersionsTable = ({
  versions = [],
  className = '',
  title = I18N('version_history'),
  ignoredFields = [],
}) => {
  const orderedVersions = _.orderBy(versions, ['createdAt'], ['desc'])

  if (_.isEmpty(orderedVersions)) { return null }

  return (
    <>
      <h5 className='my-3'>
        {title}
      </h5>
      <table className={classNames('VersionsTable white-bg-table', className)}>
        <thead>
          <tr>
            <th>{I18N('responsible')}</th>
            <th>{I18N('date')}</th>
            <th>{I18N('action')}</th>
            <th>{I18N('changes')}</th>
          </tr>
        </thead>
        <tbody>
          {orderedVersions.filter(version => version.changeset !== undefined).map((version) => {
            const changeSet = Object.entries(version.changeset)
            const itemTypeWithoutNamespace = version.itemType?.split('::')?.pop()

            return (
              <tr key={version.id}>
                <td><WhoDidIt version={version} /></td>
                <td className='text-secondary'>{i18nMoment(version.createdAt).format('lll')}</td>
                <td>
                  <StatusLabel>
                    {`${itemTypeWithoutNamespace} ${version.event}`}
                  </StatusLabel>
                </td>
                <td>
                  <VersionsList changeSet={changeSet} ignoredFields={ignoredFields} isCreateEvent={version.event === 'create'} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default VersionsTable
