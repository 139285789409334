import React, { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { i18nPath } from 'utils/i18nHelpers'
import userSlice from 'redux/slices/users'
import UsersTable from 'components/admin/users/usersTable'
import { Button } from 'components/common/buttons'
import HistoryChangingPillTabSelector from 'components/common/historyChangingPillTabSelector'
import useQueryParams from 'components/common/hooks/useQueryParams'
import ActionNeededCard from 'components/admin/users/actionNeededCard'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import CreateNewUserButton from 'components/admin/users/createNewUserButton'
import ImpersonationHistoryTable from 'components/admin/users/impersonationHistoryTable'

const I18N = i18nPath('views.admin.users')

const BASE_TABS = [
  {
    id: 'users',
    text: I18N('users'),
  },
]

const UserListPage = () => {
  const dispatch = useDispatch()

  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()
  const { queryParams, isLoading } = useSelector(userSlice.selectors.getAdminUserListMetaData())
  const [searchQuery, setSearchQuery] = useState('')
  const { selectedTab } = useQueryParams()

  const showCreateNewUserButton = !currentCompany.hasHrisIntegration
  const showHrisSyncAlert = currentCompany.hasHrisIntegration
  const isPreboardingEnabled = currentCompany.settings?.journeys?.preboarding?.enabled
  const isSearchQueryEmpty = searchQuery === ''
  const canUserManagePermissions = currentUser.permissions.superAdmin

  const isUsersTabSelected = !selectedTab || selectedTab === 'users'
  const isNewHiresPreboardingTabSelected = selectedTab === 'new_hires_preboarding'
  const isActionNeededTabSelected = selectedTab === 'action_needed'
  const isImpersonationHistoryTabSelected = selectedTab === 'impersonation_history'

  const showEmptyState = !isLoading && queryParams.total <= 1 && isSearchQueryEmpty && isUsersTabSelected

  let tabs = [...BASE_TABS]

  if (isPreboardingEnabled) {
    tabs = [
      ...tabs,
      {
        id: 'new_hires_preboarding',
        text: I18N('new_hires_preboarding'),
      },
      {
        id: 'action_needed',
        text: I18N('action_needed'),
      },
    ]
  }

  if (currentUser.permissions.superAdmin) {
    tabs = [
      ...tabs,
      {
        id: 'impersonation_history',
        text: I18N('impersonation_history'),
      },
    ]
  }

  const fetchUsers = (additionalParams = {}) => {
    const params = {
      q: searchQuery,
      ...queryParams,
      ...additionalParams,
    }

    if (isNewHiresPreboardingTabSelected) {
      params.preboarding = true
    } else if (isActionNeededTabSelected) {
      params.action_needed = true
    }

    dispatch(userSlice.asyncActions.admin.fetchUsers(params, selectedTab))
  }

  useEffect(() => {
    if ((isPreboardingEnabled && !selectedTab) || isImpersonationHistoryTabSelected) {
      return
    }

    fetchUsers({ page: 1 })
  }, [searchQuery, selectedTab])

  const handleNameSearch = (value) => {
    setSearchQuery(value)
  }

  if (isPreboardingEnabled && !selectedTab) {
    return <Redirect to='/admin/users?selectedTab=users' />
  }

  return (
    <div className='UserListPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('users')}</h3>
        <div className='AdminButtonContainer col-6'>
          {canUserManagePermissions && (
            <Link to='/admin/permissions_and_alerts'>
              <Button variant='secondary'>
                {I18N('permissions_and_alerts')}
              </Button>
            </Link>
          )}
          {showCreateNewUserButton && <CreateNewUserButton className='ml-2' />}
        </div>
      </header>
      <main className='AdminContent'>
        {showHrisSyncAlert && (
          <div className='alert alert-warning'>
            <div>
              {I18N('banner_hris_sync_alert', { hris: currentCompany.hrisIntegrationName })}
            </div>
          </div>
        )}
        {!showEmptyState && isPreboardingEnabled && (
          <HistoryChangingPillTabSelector
            pillClasses='text-normal'
            className='mb-2'
            tabs={tabs}
          />
        )}
        {isActionNeededTabSelected && <ActionNeededCard />}
        {!isImpersonationHistoryTabSelected && (
          <UsersTable
            hidden={isLoading || showEmptyState}
            fetchUsers={fetchUsers}
            selectedTab={selectedTab}
            handleNameSearch={handleNameSearch}
            showEmptyState={showEmptyState}
            isLoading={isLoading}
            showCreateNewUserButton={showCreateNewUserButton}
          />
        )}
        {isImpersonationHistoryTabSelected && <ImpersonationHistoryTable />}
      </main>
    </div>
  )
}

export default UserListPage
