import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import AiEvaluation from 'components/analytics/ai_answers/aiEvaluation'
import BackButton from 'components/common/backButton'
import ClearyCard from 'components/common/card'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import { formatFloat } from 'components/common/utils'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import Sources from 'components/search/ai/sources'
import AnsweredLabel from 'pages/admin/ai/answeredLabel'
import API from 'services/api'
import { AiEvaluationAnswerType } from 'types/ai/evaluation'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CollapsableItem from 'components/common/collapsableItem'
import UserLink from 'components/common/userLink'
import ScoreLabel from 'pages/admin/ai/runScoreLabel'
import EditableLlmCall from './aiEvaluationAnswerComponents/editableLlmCall'

const I18N = i18nPath('views.ai_evaluation.answer')

const AiEvaluationAnswerPage = () => {
  const { answerId } = useParams()
  const {
    data, isLoaded,
  } = useFetch<AiEvaluationAnswerType>(API.admin.ai.evaluation.answers.fetch, [answerId])

  const [isAllLLMCallsCollapsed, setIsAllLLMCallsCollapsed] = useState(true)

  if (!isLoaded) return <CirclesLoadingIndicator />

  if (!data) return <div>No data</div>

  const hasEvaluation = [
    data.contextRelevanceScore,
    data.faithfulnessScore,
    data.answerRelevanceScore,
    data.ragasScore,
    data.expectedAnswerScore,
    data.answerConcisenessScore,
  ].some(score => typeof score === 'number')

  return (
    <>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('title', { answerId })}</h3>
      </header>

      <main className='AdminContent'>
        <BackButton url={`/admin/ai_evaluation/${data.run.id}`} className='mb-4' />

        <ClearyCard className='mb-3'>
          <h4>{I18NCommon('question')}</h4>
          <p>{data.question.content}</p>

          <h4>Asked by</h4>
          <div className='mt-2'><UserLink user={data.question.user} /></div>

          <div className='mt-1 mb-4 text-secondary text-small'>
            {data.question.companyLogoUrl ? (
              <img
                src={data.question.companyLogoUrl}
                alt={data.question.companyName}
                className='mr-2'
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
            ) : (
              <div>{data.question.companyName}</div>
            )}
          </div>

          <h4>Evaluation</h4>
          <div className='mb-4 d-flex gap-3'>
            <div>
              <h6>Tokens</h6>
              <p>{data.totalTokens}</p>
            </div>

            <div>
              <h6>Cost</h6>
              <p>${formatFloat(data.totalCost, 6)}</p>
            </div>

            <div>
              <h6>Answer Score</h6>
              <ScoreLabel score={data.expectedAnswerScore} /> {formatFloat(data.expectedAnswerScore || 0, 2)}
            </div>
            <div>
              <h6>Conciseness</h6>
              <ScoreLabel score={data.answerConcisenessScore} /> {formatFloat(data.answerConcisenessScore || 0, 2)}
            </div>

            <div>
              <h6>Metrics</h6>
              {hasEvaluation ? (
                <AiEvaluation
                  contextRelevanceScore={data.contextRelevanceScore}
                  faithfulnessScore={data.faithfulnessScore}
                  ragasScore={data.ragasScore}
                  answerRelevanceScore={data.answerRelevanceScore}
                  expectedAnswerScore={data.expectedAnswerScore}
                  answerConcisenessScore={data.answerConcisenessScore}
                />
              ) : (
                <p>No metrics</p>
              )}
            </div>

          </div>


          <div className='d-flex gap-4 mb-4 align-items-start'>
            <div className='flex-grow-1 flex-shrink-1' style={{ width: 0 }}>
              <h4>{I18NCommon('answer')}</h4>
              <AiAnswerContent content={data.answer} />
              {data.sources && <Sources sources={data.sources} />}
            </div>

            {data.expectedAnswer && (
              <div className='flex-grow-1 flex-shrink-1' style={{ width: 0 }}>
                <h4>{I18NCommon('expected_answer')}</h4>
                <AiAnswerContent content={data.expectedAnswer} />
                {data.question.sources && <Sources sources={data.question.sources} />}
              </div>
            )}
          </div>

          {data.expectedAnswerExplanation && (
            <>
              <h4>{I18NCommon('expected_answer_explanation')}</h4>
              <CollapsableItem
                label={(
                  <button className='btn btn-secondary'>View expected answer explanation</button>
                )}
                defaultIsCollapsed={true}
              >
                <div dangerouslySetInnerHTML={{ __html: data.expectedAnswerExplanation.replace(/\n/g, '<br />') }} />
              </CollapsableItem>
            </>
          )}

          <h4>{I18NCommon('context')}</h4>
          <CollapsableItem
            label={(
              <button className='btn btn-secondary'>View context</button>
            )}
            defaultIsCollapsed={true}
          >
            <pre style={{ whiteSpace: 'pre-wrap' }}>{data.context}</pre>
          </CollapsableItem>
        </ClearyCard>

        <button className='btn btn-primary mb-3' onClick={() => setIsAllLLMCallsCollapsed(value => !value)}>Toggle all LLM calls</button>

        {data.llmCalls.map((llmCall, index) => (
          <EditableLlmCall
            key={`llm-call-${index}-${isAllLLMCallsCollapsed}`}
            llmCall={llmCall}
            defaultIsCollapsed={isAllLLMCallsCollapsed}
          />
        ))}
      </main>
    </>
  )
}

export default AiEvaluationAnswerPage
