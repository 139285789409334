import { Button } from 'components/common/buttons'
import AppModal from 'components/common/modal'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { PDF_TEMPLATE_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'

interface SmartPdfModalProps {
  toggleModal: () => void
  filename: string
  handleSave: () => void
  onChange: (html: string) => void
  value: string
  defaultHtml?: string
  isLoading?: boolean
}

const SmartPdfModal = ({
  toggleModal, filename, handleSave, onChange, value, defaultHtml, isLoading,
}: SmartPdfModalProps) => (
  <AppModal
    visible
    toggle={toggleModal}
    modalContentClassName='SmartPdfModal'
    modalTitleClassName='w-100'
    title={(
      <div className='d-flex justify-content-between align-items-center'>
        <div />
        {/* TODO: come up with a way to define the filename here per action because some actions
          * may not have the `filename` setting. */}
        <h4>{filename}</h4>
        <Button onClick={handleSave} disabled={isLoading} showLoadingSpinner={isLoading}>
          {I18NCommon('save')}
        </Button>
      </div>
    ) as any}
    fullscreen
  >
    <TiptapEditor
      configuration={SIMPLE_EDITOR_CONFIGURATION}
      toolbarItems={PDF_TEMPLATE_TOOLBAR}
      onChange={onChange}
      html={_.isEmpty(value) ? defaultHtml : value}
    />
  </AppModal>
)

export default SmartPdfModal
