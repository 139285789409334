import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import ClearyTooltip from 'components/common/clearyTooltip'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.workflows.actions.action_state')

const ActionStateIcon = ({ action }) => {
  if (action.publishable) {
    return (
      <div className='publishable-icon-success'>
        <ClearyTooltip
          content={<CdnSvg src='/images/illustrations/circleSuccessIcon.svg' />}
          placement='bottom'
        >
          {I18N('ready')}
        </ClearyTooltip>
      </div>
    )
  }

  return (
    <div className='publishable-icon-warning'>
      <ClearyTooltip
        content={<CdnSvg src='/images/illustrations/circleWarningIcon.svg' />}
        placement='bottom'
      >
        {I18N('pending')}
      </ClearyTooltip>
    </div>
  )
}

export default ActionStateIcon
