import React from 'react'
import AppModal from 'components/common/modal'
import Content from 'pages/admin/workflows/workflowRunPage/content'
import { WorkflowRunType, WorkflowType } from 'types/workflow'
import { i18nPath } from 'utils/i18nHelpers'

type Props = {
  workflow: WorkflowType
  run: WorkflowRunType
  toggleModal: () => void
}

const I18N = i18nPath('views.admin.workflows')

const WorkflowRunDetailsModal = ({ workflow, run, toggleModal }: Props) => (
  <AppModal
    visible
    toggle={toggleModal}
    size='lg'
    modalContentClassName='EntryableModal__Content container d-flex flex-column w-100'
  >
    <div className='d-flex flex-column align-items-start justify-content-between mb-3'>
      <h3 className='mb-0'>{I18N('details')}</h3>
    </div>

    <div className='EntryableModal__Content__wrapper bordered'>
      <Content workflow={workflow} run={run} />
    </div>
  </AppModal>
)

export default WorkflowRunDetailsModal
