import React from 'react'
import { useParams, Link } from 'react-router-dom'
import API from 'services/api'
import useFetch from 'components/common/hooks/useFetch'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { TableLoadingContainer } from 'components/common/loadingContainer'
import SmartTable from 'components/common/tables/smartTable'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import RoundedAvatars from 'components/common/roundedAvatars'
import CdnSvg from 'components/common/cdnSvg'
import DropdownMenuContainer from 'components/common/dropdownMenuContainer'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import DataList from 'components/common/dataList'
import TriggerableLabel from 'components/workflows/triggerableLabel'
import { WorkflowRunType, WorkflowType } from 'types/workflow'
import WorkflowRunStatus from 'components/workflows/workflowRunStatus'
import { Breadcrumb } from 'react-bootstrap'
import BackButton from 'components/common/backButton'

const I18N = i18nPath('views.admin.workflows.history_page')
const I18N_BREADCRUMB = i18nPath('views.admin.workflows.breadcrumb')

const columns = [
  {
    header: `${I18N('table.headers.run')} #`,
    accessor: run => (
      <Link to={`/admin/workflows/${run.workflow.id}/runs/${run.id}`}>{run.number}</Link>
    ),
  },
  {
    header: I18N('table.headers.trigger'),
    accessor: run => <TriggerableLabel triggerableType={run.triggerableType} />,
  },
  {
    header: I18N('table.headers.requested_by'),
    accessor: run => (
      run.triggerable?.user ? (
        <RoundedAvatars users={[run.triggerable.user]} avatarSize='30px' />
      ) : (
        <span>{I18NCommon('unknown')}</span>
      )
    ),
  },
  {
    header: I18N('table.headers.started'),
    accessor: run => i18nMoment(run.startedAt).format('lll'),
  },
  {
    header: I18N('table.headers.completed'),
    accessor: run => i18nMoment(run.completedAt).format('lll'),
  },
  {
    header: I18N('table.headers.status'),
    accessor: run => <WorkflowRunStatus status={run.status} />,
  },
]

const WorkflowPage = () => {
  const { workflowId } = useParams()

  const { data: workflow, isLoading } = useFetch<WorkflowType>(API.admin.workflows.fetch, [workflowId])
  const {
    data: runs,
    isLoading: isLoadingRuns,
    paginationData: { page, totalPages },
    callApi: fetchRuns,
  } = useFetch<WorkflowRunType[]>(API.admin.workflows.runs.fetchAll, [workflowId])

  if (isLoading || !workflow) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='WorkflowPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <div>
          <h4 className='mb-0'>{I18N('title')}</h4>
        </div>
      </header>

      <main className='AdminContent'>
        <BackButton />
        <Breadcrumb className='CustomBreadcrumb mt-2 mb-4'>
          <Breadcrumb.Item>
            <Link to='/admin/workflows'>{I18N_BREADCRUMB('workflows')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {workflow.name}
          </Breadcrumb.Item>
        </Breadcrumb>

        <DataList
          data={[
            { dataTitle: I18N('workflow_name'), dataValue: workflow.name },
            { dataTitle: I18N('workflow_description'), dataValue: workflow.description },
          ]}
        />

        <TableLoadingContainer isLoading={isLoadingRuns}>
          <SmartTable
            data={runs}
            showPagination={page !== null && totalPages !== null}
            columns={columns}
            page={page}
            pages={totalPages}
            onPaginationClick={params => fetchRuns(workflowId, params)}
            className='white-bg-table'
          />
        </TableLoadingContainer>
      </main>
    </div>
  )
}

export default WorkflowPage
