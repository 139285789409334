/* eslint-disable import/prefer-default-export */
import {
  MessageType, GeneratingAiMessageType, AiMessageType, UserMessageType
} from 'components/ai/chat/ask_ai_modal/types'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { i18nPath } from 'utils/i18nHelpers'

export const askAiI18n = i18nPath('views.ask_ai')

export const useAskAiTitle = () => {
  const { settings } = useCurrentCompany()
  return !settings.aiChatbot.title.trim() ? askAiI18n('ask_ai') : settings.aiChatbot.title
}


export const isGeneratingAiMessage = (
  message: MessageType
): message is GeneratingAiMessageType => (
  message.id.startsWith('generating')
)

export const isUserMessage = (message: MessageType): message is UserMessageType => message.role === 'user'

export const isAiMessage = (message: MessageType): message is AiMessageType => message.role === 'assistant'
