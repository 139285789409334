import React from 'react'

import { I18NCommon } from 'utils/i18nHelpers'

import classNames from 'classnames'

interface CancelButtonProps {
  onClick: () => void
  className?: string
  children?: any
  isDisabled?: boolean
}

const CancelButton = ({
  onClick, className = 'mr-3', children, isDisabled = false,
}: CancelButtonProps) => (
  <span
    className={classNames('CancelButton text-secondary', isDisabled ? 'cursor-not-allowed' : 'pointer', className)}
    onClick={isDisabled ? undefined : onClick}
  >
    {children || I18NCommon('cancel')}
  </span>
)

export default CancelButton
