import React from 'react'

import { i18nPath } from 'utils/i18nHelpers'

import { Button } from 'components/common/buttons'
import QNAManagerEmptyEventsIcon from 'components/icons/qna/qnaManagerEmptyEventsIcon'
import RegularUserEmptyEventsIcon from 'components/icons/qna/regularUserEmptyEventsIcon'

const I18N = i18nPath('views.qna.events.empty')

const CreateEventCallToAction = ({ onClickCreateEvent }) => (
  <>
    <QNAManagerEmptyEventsIcon className='mb-4' />
    <h4>{I18N('qna_manager_empty_header')}</h4>
    <p className='text-secondary mb-4'>{I18N('qna_manager_empty_subheader')}</p>
    <Button
      onClick={onClickCreateEvent}
    >
      {I18N('qna_manager_empty_create_event')}
    </Button>
  </>
)

const StayTunedCallToAction = () => (
  <>
    <RegularUserEmptyEventsIcon className='mb-4' />
    <h4 className='mb-3'>{I18N('regular_user_empty_header')}</h4>
    {/* at least for english, break text into two lines */}
    <p className='text-secondary text-center' style={{ maxWidth: '400px' }}>
      {I18N('regular_user_empty_subheader')}
    </p>
  </>
)

const EmptyEventsNotice = ({
  onClickCreateEvent,
  isEventCreator,
}) => (
  <div className='EmptyEventsNotice row mt-6 mb-4'>
    <div className='col d-flex justify-content-center mt-4'>
      <div className='d-inline-flex flex-column align-items-center'>
        {isEventCreator ? (
          <CreateEventCallToAction onClickCreateEvent={onClickCreateEvent} />
        ) : (
          <StayTunedCallToAction />
        )}
      </div>
    </div>
  </div>
)

export default EmptyEventsNotice
