import { TicketingMessageType } from 'types/ticketing/message'
import { SimpleUserType, UserType } from 'types/user'
import { WorkflowRunType } from 'types/workflow'

export enum TicketStatusEnum {
  New = 'new',
  InProgress = 'in_progress',
  Closed = 'closed',
}

export enum TicketOriginEnum {
  Web = 'web',
  Admin = 'admin',
  Integration = 'integration',
}

export type TicketType = {
  requesterDetails: {
    name: string
    email: string
  }
  assigneeDetails: {
    name: string
    email: string
  }
  createdAt: string
  id: string
  number: number
  status: TicketStatusEnum
  overdue: boolean
  dueDate: string | null
  description: string
  title: string
  user: SimpleUserType | UserType
  employee?: SimpleUserType
  origin: TicketOriginEnum
  assignee?: SimpleUserType
  creator?: SimpleUserType
  updatedAt: string
  tags: TicketTagType[]
}

export type TicketEntryType = {
  id: string
  ticketId: string
  entryable: TicketingMessageType | WorkflowRunType
  entryableId: string
  entryableType: string
  createdAt: string
  updatedAt: string
}

export type SuggestedMessageType = {
  id: string
  content: string
  messageType: 'current' | 'previous'
  pdfFile?: any
  createdAt: string
  updatedAt: string
}

export type TicketMessageEntryType = TicketEntryType & {
  entryable: TicketingMessageType
}

export type WorkflowRunEntryType = TicketEntryType & {
  entryable: WorkflowRunType
}

export type SuggestedMessageEntryType = TicketEntryType & {
  entryable: SuggestedMessageType
}

export type TicketTagType = {
  id: string
  name: string
  createdAt?: string
  updatedAt?: string
  isNew?: boolean
}
