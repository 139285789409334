import useFetch, { UseFetchOptions } from 'components/common/hooks/useFetch'
import { ApiFunction } from 'components/common/hooks/useApi'
import { useState } from 'react'

function useCursorPagination<T>(apiFunction: ApiFunction, dependencies: any[] = [], options: UseFetchOptions = {}) {
  const [data, setData] = useState<T[]>([])
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined)

  const {
    callApi,
    isLoading,
    isLoaded,
  } = useFetch(apiFunction, dependencies, {
    ...options,
    onSuccess: (data, response) => {
      if (options.onSuccess) {
        options.onSuccess(data, response)
      }

      setNextCursor(response?.headers ? response.headers['next-cursor'] : undefined)

      setData(currentData => _.uniqBy([...currentData, ...data], 'id'))
    },
  })

  const resetCursor = () => {
    setNextCursor(undefined)
  }

  const onLoadMore = () => {
    callApi(...dependencies, { nextCursor })
  }

  return {
    data,
    isLoading,
    isLoaded,
    onLoadMore,
    setData,
    hasMore: !!nextCursor,
    resetCursor,
  }
}

export default useCursorPagination
