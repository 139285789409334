import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'

const SelectField = ({ onChange, value, options }) => (
  <select
    value={value}
    onChange={e => onChange(e.target.value)}
    className='form-control'
  >
    <option value=''>{I18NCommon('select')}...</option>
    {options.map(option => (
      <option key={option} value={option}>
        {I18NCommon(option)}
      </option>
    ))}
  </select>
)

export default SelectField
