import React, { useState, useRef, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import isMobile from 'utils/isMobile'
import { i18nPath, i18nFormat } from 'utils/i18nHelpers'

import { Banner } from 'components/banners/banners'

import CookieManager from 'utils/cookieManager'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'

const I18N = i18nPath('views.golinks_banner')

const GolinksExtensionBanner = (props) => {
  const location = useLocation()
  const history = useHistory()
  const golinksBannerRef = useRef()
  const cookieManager = new CookieManager({ name: 'GolinksInstallationPrompt' })

  const [showInstallGolinksExtensionsBanner, setShowInstallGolinksExtensionsBanner] = useState(false)

  const currentUser = useCurrentUser()
  const currentCompany = useCurrentCompany()

  // If this is present in the DOM, the extension is installed.
  const isGolinksExtensionInstalled = () => document.getElementById('cleary-app-container').querySelector('#cleary-browser-extension')

  const wasGolinksBannerDismissed = () => cookieManager.readCookie()

  const onReadyStateChange = () => {
    // Let's wait for a full page load.
    if (document.readyState === 'complete') {
      // We use requestAnimationFrame to make sure that the injected DOM node is available,
      // Similar to setTimeout(fn, 0), AKA wait for next tick.
      requestAnimationFrame(() => {
        if (isGolinksExtensionInstalled()) {
          if (wasGolinksBannerDismissed()) {
            cookieManager.deleteCookie()
          }
        } else {
          // If we're standing in golinks list and the banner was dismissed, we want to see the banner.
          if (location.pathname.includes('golinks/') && wasGolinksBannerDismissed()) {
            setShowInstallGolinksExtensionsBanner(true)
            return
          }
          if (!wasGolinksBannerDismissed()) {
            setShowInstallGolinksExtensionsBanner(true)
          }
        }
      })
    }
  }

  useEffect(() => {
    document.addEventListener('readystatechange', onReadyStateChange)

    let unlisten = () => {}

    requestAnimationFrame(() => {
      unlisten = history.listen(({ pathname }) => {
        if (pathname.includes('/golinks/') && !isGolinksExtensionInstalled()) {
          setShowInstallGolinksExtensionsBanner(true)
        }

        if (!pathname.includes('/golinks/')) {
          setShowInstallGolinksExtensionsBanner(false)
        }
      })
    })

    window.golinksBannerRef = golinksBannerRef

    return () => {
      document.removeEventListener('readystatechange', onReadyStateChange)
      unlisten()
    }
  }, [])

  const handleExtensionInstallDecline = () => {
    setShowInstallGolinksExtensionsBanner(false)
    cookieManager.createCookie({ expireIn: 8 })
  }

  const shouldHideBanner = () => {
    const isLoggedOut = !currentUser
    const hideGolinksBanner = !_.get(currentCompany.settings, 'golinks.showChromeExtensionBanner')
    const goLinksDisabled = !_.get(currentCompany.settings, 'golinks.enabled')

    return goLinksDisabled || isLoggedOut || hideGolinksBanner || isMobile()
  }

  if (shouldHideBanner()) return null

  return (
    showInstallGolinksExtensionsBanner && (
      <Banner
        showDecline={!location.pathname.includes('/golinks/')}
        banner={{
          text: (
            <p className='m-0 GolinksExtensionBanner' ref={golinksBannerRef}>
              {i18nFormat(
                I18N('text'),
                <a className='font-weight-bold'
                  href='https://chrome.google.com/webstore/detail/cleary-go-links/opldbaajfdlmdiililehillijmbdbhob'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Chrome Web Store
                </a>,
                <a className='font-weight-bold'
                  href='https://addons.mozilla.org/en-US/firefox/addon/cleary/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Firefox Browser Add-Ons
                </a>
              )}
            </p>
          ),
        }}
        handleDecline={() => handleExtensionInstallDecline()}
      />
    )
  )
}

export default GolinksExtensionBanner
