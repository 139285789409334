import AsyncSearchInput from 'components/common/asyncSearchInput'
import CdnSvg from 'components/common/cdnSvg'
import useApi, { PaginationData } from 'components/common/hooks/useApi'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import BaseLayout from 'components/layout/baseLayout'
import TicketListPageSidebar from 'components/admin/ticketing/ticketListPageSidebar'
import TicketStatusSelect from 'components/ticketing/ticketStatusSelect'
import { TicketStatusEnum, TicketType } from 'types/ticketing/ticket'
import React, { useEffect, useState } from 'react'
import API from 'services/api'
import { UserType } from 'types/user'
import { i18nPath } from 'utils/i18nHelpers'
import { useDebounce } from 'usehooks-ts'
import useQueryParams from 'components/common/hooks/useQueryParams'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import ViewTicketSidebar from 'components/ticketing/view_ticket_sidebar/viewTicketSidebar'
import useDebouncedClose from 'hooks/shared/useDebouncedClose'
import TicketTable from 'components/ticketing/ticketTable'
import { useSelector } from 'react-redux'
import entitySlice from 'redux/slices/entities'
import useFetch from 'components/common/hooks/useFetch'
import TicketingContext from 'components/ticketing/ticketingContext'
import TicketSettings from 'components/admin/ticketing/settings/ticketSettings'

const I18N = i18nPath('views.ticketing')

const TicketListPageContent = ({
  isLoading,
  tickets,
  toggleSidebarComponent,
  isSidebarOpen,
  paginationData,
  fetchTickets,
  setSelectedTicketNumber,
}: {
  isLoading: boolean
  tickets: TicketType[]
  toggleSidebarComponent?: React.ReactNode
  isSidebarOpen?: boolean
  paginationData: PaginationData
  fetchTickets: ({ page, title }: { page: number, title?: string }) => void
  setSelectedTicketNumber: (ticketNumber: string) => void
}) => {
  const [status, setStatus] = useQueryParamState<TicketStatusEnum | undefined>({
    param: 'status',
    initialValue: undefined,
  })

  const [searchQuery, setSearchQuery] = useState('')
  const debouncedQuery = useDebounce(searchQuery, 500)
  const [requester, setRequester] = useState<UserType | undefined>(undefined)

  const { selectedTab } = useQueryParams()
  const currentUser = useCurrentUser()

  const searchParams = {
    ...paginationData,
    status: selectedTab === 'closed' ? TicketStatusEnum.Closed : status || [TicketStatusEnum.New, TicketStatusEnum.InProgress],
    title: searchQuery,
    userId: requester?.id,
    unassigned: selectedTab === 'unassigned' || undefined,
    assigneeId: selectedTab === 'assigned_to_you' ? currentUser.id : undefined,
  }

  useEffect(() => {
    fetchTickets({ page: 1, ...searchParams })
  }, [debouncedQuery, status, requester?.id, selectedTab])

  return (
    <div className='TicketListPage'>
      <header className='TicketListPage__header d-flex justify-content-between px-4 py-3'>
        <div className='d-flex align-items-center'>
          {!isSidebarOpen && <div className='ml-n3 mr-2'>{toggleSidebarComponent}</div>}

          <AsyncSearchInput
            placeholder={I18N('search_by_title')}
            className='w-100'
            onKeyUp={setSearchQuery}
            icon={<CdnSvg src='/images/searchIcon.svg' />}
            minCharsToSearch={3}
            inputGroupClassName='flex-grow-1 mr-2'
          />
        </div>

        <div className='d-flex align-items-center'>
          <EmployeeSearch
            onChange={setRequester}
            selectedEmployee={requester}
            className='mr-3'
            placeholder={I18N('filter_by_requester')}
          />

          {selectedTab !== 'closed' && (
            <TicketStatusSelect
              value={status}
              onChange={setStatus}
              optionIds={[TicketStatusEnum.New, TicketStatusEnum.InProgress, 'overdue']}
            />
          )}
        </div>
      </header>

      <main className='AdminContent m-0'>
        <TicketTable
          isLoading={isLoading}
          tickets={tickets}
          paginationData={paginationData}
          onPaginationClick={data => fetchTickets({ ...searchParams, ...data })}
          setSelectedTicketNumber={setSelectedTicketNumber}
        />
      </main>
    </div>
  )
}

const TicketListPage = () => {
  const { selectedTab } = useQueryParams()
  const [ticketIds, setTicketIds] = useState([])
  const [fetchTickets, {
    isLoading, paginationData,
  }] = useApi(API.admin.ticketing.tickets.fetchAll, {
    addEntitySlice: true,
    onSuccess: tickets => setTicketIds(tickets.map(ticket => ticket.id)),
  })

  const tickets = useSelector(entitySlice.selectors.getEntities('ticketingTicket', ticketIds))

  const [selectedTicketNumber, setSelectedTicketNumber] = useQueryParamState<string | undefined>({
    param: 'ticketNumber',
    initialValue: undefined,
  })

  const { data: counts, callApi: fetchCounts } = useFetch(API.admin.ticketing.tickets.counts)

  // we need a debounced removal because a user may click a different ticket (outside the sidebar so calling onClose)
  // and we don't want to remove the newly selected ticket in that case
  const [isClosingSidebar, onCloseSidebar] = useDebouncedClose(() => setSelectedTicketNumber(''))

  const removeTicket = (ticket: TicketType) => {
    setTicketIds(ticketIds.filter(ticketId => ticketId !== ticket.id))
    if (selectedTicketNumber === String(ticket.number)) {
      setSelectedTicketNumber('')
    }
  }

  return (
    <TicketingContext.Provider value={{ fetchCounts, removeTicket }}>
      <BaseLayout sidebar={<TicketListPageSidebar fetchTickets={fetchTickets} counts={counts} />}>
        {selectedTab === 'settings' ? (
          <TicketSettings />
        ) : (
          <TicketListPageContent
            isLoading={isLoading}
            tickets={tickets ?? []}
            paginationData={paginationData}
            fetchTickets={fetchTickets}
            setSelectedTicketNumber={setSelectedTicketNumber}
          />
        )}
      </BaseLayout>
      <ViewTicketSidebar
        ticketNumber={selectedTicketNumber}
        onClose={onCloseSidebar}
        isOpen={!!selectedTicketNumber && !isClosingSidebar}
        isAdmin
      />
    </TicketingContext.Provider>
  )
}

export default TicketListPage
