import React from 'react'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import ReactSelect, { Creatable } from 'components/common/react_select'

type FormSelectFieldProps = {
  fieldName?: string
  label?: string
  isRequired?: boolean
  footNote?: string
  currentValue?: any
  onChange?: (option: any) => void
  className?: string
  placeholder?: string
  options?: any[]
  getOptionValue?: (option: any) => string
  getOptionLabel?: (option: any) => string
  isCreatable?: boolean
  isClearable?: boolean
  isMulti?: boolean
  noOptionsMessage?: () => string
  menuPlacement?: 'bottom' | 'top'
}

const FormSelectField = ({
  fieldName = undefined,
  label = undefined,
  isRequired = undefined,
  footNote = undefined,
  currentValue = undefined,
  onChange = (option) => { },
  className = '',
  placeholder = '',
  options = [],
  getOptionValue = item => item.value,
  getOptionLabel = item => item.label,
  isCreatable = false,
  isClearable = true,
  isMulti = false,
  ...props
}: FormSelectFieldProps) => {
  const Component = isCreatable ? Creatable : ReactSelect

  return (
    <LabeledFormFieldContainer
      isRequired={isRequired}
      footNote={footNote}
      className={className}
      label={label || fieldName}
    >
      <Component
        name={fieldName}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        value={currentValue}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        isClearable={isClearable}
        isMulti={isMulti}
        {...props}
      />
    </LabeledFormFieldContainer>
  )
}


export default FormSelectField
