import React from 'react'
import SVG from 'react-inlinesvg'
import pageVariables from 'utils/pageVariables'

const { cdnDomain } = pageVariables

type Props = {
  src: string
  className?: string
  style?: React.CSSProperties
}

const CdnSvg = ({ src, className, style }: Props) => (
  <SVG
    src={process.env.NODE_ENV !== 'production' ? src : `https://${cdnDomain}${src}`}
    className={className}
    cacheGetRequests
    style={style}
  />
)

export default CdnSvg
