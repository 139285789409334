import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import CdnSvg from 'components/common/cdnSvg'
import { LeftNavItemType } from 'components/navbar/hooks/useLeftNavItems'

interface LeftNavItemProps extends LeftNavItemType {
  isLeftNavOpen: boolean
  defaultOpen?: boolean
}

const LeftNavItem = ({
  label,
  icon,
  to,
  exact = false,
  activePaths = [],
  subItems = [],
  isLeftNavOpen,
  defaultOpen = false,
}: LeftNavItemProps) => {
  const [isItemOpen, setIsItemOpen] = useState(defaultOpen)
  const hasSubItems = subItems.length > 0
  const showSubItems = isLeftNavOpen && isItemOpen

  const isActive = (match: any, location: any) => {
    if (match) return true

    return activePaths.some(path => location.pathname.startsWith(path))
  }

  const onNavItemClick = () => {
    if (hasSubItems && !isItemOpen) {
      setIsItemOpen(true)
    }
  }

  const onToggleSubItems = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setIsItemOpen(!isItemOpen)
  }

  return (
    <div className='LeftNavItem'>
      <NavLink
        to={to}
        className='TopLevelItem Item'
        exact={exact}
        isActive={isActive}
        onClick={onNavItemClick}
      >
        <div className='LeftNavItem-content'>
          {icon && <CdnSvg src={icon} />}
          <span className='LeftNavItem-label'>{label}</span>
        </div>

        {hasSubItems && (
          <div
            className={classNames('ToggleSubItemsButton', { isItemOpen })}
            onClick={onToggleSubItems}
          >
            <CdnSvg src='/images/navbar/toggleIcon.svg' />
          </div>
        )}
      </NavLink>

      {showSubItems && (
        <div className='SubItems-container d-flex flex-column gap-1'>
          {subItems.map(subItem => (
            <NavLink
              to={subItem.to}
              className='SubItem Item'
              key={subItem.label}
            >
              <span className='SubItem-label'>{subItem.label}</span>
            </NavLink>
          ))}
        </div>
      )}
    </div>
  )
}

export default LeftNavItem
