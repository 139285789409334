import React, { useState } from 'react'
import fieldFactory from './fieldFactory'

const ObjectField = ({ onChange, value = {}, properties }) => {
  const [values, setValues] = useState(value)

  return (
    <>
      {Object.keys(properties).map((property) => {
        const Field = fieldFactory(properties[property].type)

        return (
          <Field
            key={property}
            value={values[property]}
            onChange={(value) => {
              setValues({ ...values, [property]: value })
              onChange({ ...values, [property]: value })
            }}
            {...properties[property]}
          />
        )
      })}
    </>
  )
}

export default ObjectField
