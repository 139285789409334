import React from 'react'
import CdnSvg from 'components/common/cdnSvg'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import { I18NCommon } from 'utils/i18nHelpers'
import { TicketType } from 'types/ticketing/ticket'

type Props = {
  ticket: TicketType | null | undefined
  fontWeight?: '400' | '500' | '600'
}

const UserOrUnassigned = ({ ticket, fontWeight = '400' }: Props) => {
  if (!ticket?.assignee && !ticket?.assigneeDetails?.name) {
    return (
      <>
        <CdnSvg src='/images/personIcon.svg' className='UserOrUnassigned-UnassignedIcon mr-1' />
        <span className={`text-small font-weight-${fontWeight}`}>{I18NCommon('unassigned')}</span>
      </>
    )
  }

  return (
    <>
      <EmployeeThumbnailPhoto employee={ticket.assignee} size='22px' className='mr-1' />
      <span className={`text-small font-weight-${fontWeight}`}>
        {ticket.assignee?.preferredFullName || ticket.assigneeDetails?.name}
      </span>
    </>
  )
}

export default UserOrUnassigned
