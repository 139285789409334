import React, {
  useState, Dispatch, SetStateAction, useMemo
} from 'react'
import { useLocation } from 'react-router-dom'
import BackButton from 'components/common/backButton'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import SmartTable from 'components/common/tables/smartTable'
import { formatFloat } from 'components/common/utils'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import AnsweredLabel from 'pages/admin/ai/answeredLabel'
import AiEvaluation from 'components/analytics/ai_answers/aiEvaluation'
import API from 'services/api'
import { AiEvaluationAnswerType, AiEvaluationRunType } from 'types/ai/evaluation'
import { i18nPath } from 'utils/i18nHelpers'
import ClearyCard from 'components/common/card'
import ScoreLabel from 'pages/admin/ai/runScoreLabel'
import UserLink from 'components/common/userLink'

interface RunInformationProps {
  run: AiEvaluationRunType
}

interface LoadRunProps {
  runId: string
  setAnswers: Dispatch<SetStateAction<AiEvaluationAnswerType[]>>
  setRuns: Dispatch<SetStateAction<Record<string, AiEvaluationRunType>>>
}

const LoadRun = ({ runId, setAnswers, setRuns }: LoadRunProps) => {
  const { data: run } = useFetch<AiEvaluationRunType>(
    API.admin.ai.evaluation.runs.fetch,
    [runId]
  )

  const {
    data, isLoading, paginationData, callApi,
  } = useFetch(
    params => API.admin.ai.evaluation.answers.fetchAll(runId, params)
  )

  React.useEffect(() => {
    if (!run) return
    setRuns(prev => ({ ...prev, [runId]: run }))
  }, [run, runId, setRuns])

  React.useEffect(() => {
    if (!data) return
    setAnswers(prev => [...prev, ...data])
  }, [data, setAnswers])

  React.useEffect(() => {
    if (!paginationData?.page || paginationData.page >= (paginationData.totalPages || 0)) return
    callApi({ page: (paginationData.page || 0) + 1 })
  }, [paginationData, callApi])

  if (isLoading && !data) return <CirclesLoadingIndicator className='TableLoadingIndicator' />
  return null
}

const RunInformation = ({ run }: RunInformationProps) => {
  if (!run) return null

  return (
    <div className='mb-4'>
      <h5>Run {run.id}</h5>
      <p>{run.description}</p>

      <div className='d-flex gap-3'>
        <ClearyCard className='flex-grow-1'>
          <h5>Score - Conciseness</h5>
          <div className='d-flex align-items-center gap-2'>
            <p className='d-flex align-items-center gap-2'>
              {formatFloat(run.score || 0)}
              <ScoreLabel score={run.score} />
            </p>
            {!!run.concisenessScore && (
              <p className='d-flex align-items-center gap-2'>
                {formatFloat(run.concisenessScore || 0)}
                <ScoreLabel score={run.concisenessScore} />
              </p>
            )}
          </div>
        </ClearyCard>

        <ClearyCard className='flex-grow-1'>
          <h5>Cost</h5>
          <p>${formatFloat(run.totalCost, 6)}</p>
        </ClearyCard>

        <ClearyCard className='flex-grow-1'>
          <h5>Models Pipeline</h5>
          <p className='text-small text-secondary'>
            {Object.entries(run.modelsPipeline).map(([key, value], i) => (
              <React.Fragment key={key}>
                {i > 0 && <br />}
                {`${key}: ${value}`}
              </React.Fragment>
            ))}
          </p>
        </ClearyCard>
      </div>
    </div>
  )
}

const AiRunComparisonPage = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const runIds = searchParams.get('runIds')?.split(',') || []
  const [answers, setAnswers] = useState<AiEvaluationAnswerType[]>([])
  const [runs, setRuns] = useState<Record<string, AiEvaluationRunType>>({})

  const groupedAnswers = useMemo(() => answers.reduce((acc, answer) => {
    if (!acc[answer.question.id]) {
      acc[answer.question.id] = []
    }

    acc[answer.question.id].push(answer)
    return acc
  }, {} as Record<string, AiEvaluationAnswerType[]>), [answers])

  const openInNewTab = (answer: AiEvaluationAnswerType) => {
    window.open(`/admin/ai_evaluation/answers/${answer.id}`, '_blank')
  }

  const columns = [
    {
      header: 'Question',
      col: 'col-question',
      style: { width: '200px' },
      accessor: (answerGroup: Record<string, AiEvaluationAnswerType>) => {
        const firstAnswer = Object.values(answerGroup)[0]
        return (
          <div>
            <div>{firstAnswer?.question.content}</div>
            <div className='mt-2'><UserLink user={firstAnswer?.question.user} /></div>
            <div className='mt-1 text-secondary text-small'>
              {firstAnswer?.question.companyLogoUrl ? (
                <img
                  src={firstAnswer.question.companyLogoUrl}
                  alt={firstAnswer.question.companyName}
                  className='mr-2'
                  style={{ maxWidth: '100px', maxHeight: '100px' }}
                />
              ) : (
                <div>{firstAnswer?.question.companyName}</div>
              )}
            </div>
          </div>
        )
      },
    },
    {
      header: 'Expected Answer',
      col: 'col-expected-answer',
      accessor: (answers: AiEvaluationAnswerType[]) => {
        const firstAnswer = answers[0]
        return <AiAnswerContent content={firstAnswer.expectedAnswer} />
      },
    },
    ...runIds.map(runId => ({
      header: `Run ${runId}`,
      col: `col-run-${runId}`,
      accessor: (answers: AiEvaluationAnswerType[]) => {
        const answer = answers.find(a => a.run.id === runId)
        if (!answer) return <span>-</span>

        return (
          <div onClick={() => openInNewTab(answer)} style={{ cursor: 'pointer' }}>
            <AiAnswerContent content={answer.answer} />
            <div className='mt-3'>
              <span className='text-small d-block'>
                Answered: <AnsweredLabel expectedAnswerScore={answer.expectedAnswerScore} />
              </span>
              {!!answer.expectedAnswerScore && (
                <span className='text-small d-block'>
                  Score: <ScoreLabel score={answer.expectedAnswerScore} />
                  <span className='ml-2'>{formatFloat(answer.expectedAnswerScore, 2)}</span>
                </span>
              )}
              {!!answer.answerConcisenessScore && (
                <span className='text-small d-block'>
                  Conciseness: <ScoreLabel score={answer.answerConcisenessScore} />
                  <span className='ml-2'>{formatFloat(answer.answerConcisenessScore, 2)}</span>
                </span>
              )}
              <span className='mt-1 text-small d-block'>
                Cost: ${formatFloat(answer.totalCost, 6)}
              </span>
              <AiEvaluation
                contextRelevanceScore={answer.contextRelevanceScore}
                answerRelevanceScore={answer.answerRelevanceScore}
                faithfulnessScore={answer.faithfulnessScore}
                ragasScore={answer.ragasScore}
              />
            </div>
          </div>
        )
      },
    })),
  ]

  const data = Object.values(groupedAnswers)

  return (
    <>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>Run Comparison</h3>
      </header>

      <main className='AdminContent'>
        <BackButton url='/admin/ai_evaluation' className='mb-4' />

        {runIds.map(runId => (
          <RunInformation key={runId} run={runs[runId]} />
        ))}

        {runIds.map(runId => (
          <LoadRun
            key={runId}
            runId={runId}
            setAnswers={setAnswers}
            setRuns={setRuns}
          />
        ))}

        <SmartTable
          columns={columns}
          data={data}
          className='white-bg-table'
          showPagination={false}
        />
      </main>
    </>
  )
}

export default AiRunComparisonPage
