import React, { useContext, useState } from 'react'
import { Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap'
import { I18NCommon, i18nMoment, i18nPath } from 'utils/i18nHelpers'
import PillTabSelector from 'components/common/pillTabSelector'
import CdnSvg from 'components/common/cdnSvg'
import ActionIcon from 'components/workflows/actionIcon'
import { ActionRunType } from 'types/workflow'
import WorkflowRunStatus from 'components/workflows/workflowRunStatus'
import ActionRunOutput from './actionRunOutput'

const I18N = i18nPath('views.admin.workflows.run_page')
const I18N_ACTIONS = i18nPath('views.admin.workflows.actions')

const ActionDetailsHeader = ({ actionRun }) => {
  const toggle = useAccordionToggle(actionRun.id)
  const currentEventKey = useContext(AccordionContext)

  return (
    <div className='d-flex flex-column pointer' key={actionRun.id} onClick={toggle}>
      <div className='w-100 d-flex justify-content-between'>
        <div className='d-flex align-items-center gap-4'>
          <div className='d-flex align-items-center'>
            <ActionIcon action={actionRun.action} />
            <h6 className='mb-0 ml-2'>
              {I18N_ACTIONS(`${actionRun.action.name}.label`)}
            </h6>
          </div>
          <div>
            <div className='mb-0 d-flex gap-2'>
              <div className='d-flex gap-1'>
                <span className='bold text-small'>{I18N('data_list.started')}:</span>
                <span className='text-small'>{i18nMoment(actionRun.startedAt).format('lll')}</span>
              </div>
              <div className='d-flex gap-1'>
                <span className='bold text-small'>{I18N('data_list.completed')}:</span>
                <span className='text-small'>{i18nMoment(actionRun.completedAt).format('lll')}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <WorkflowRunStatus status={actionRun.status} />
          <CdnSvg
            src={
              currentEventKey === actionRun.id
                ? '/images/chevronDownOutline.svg'
                : '/images/chevronRightOutline.svg'
            }
            className='ml-4 arrow-icon'
          />
        </div>
      </div>
    </div>
  )
}

const ActionRunDetails = ({ actionRun }: { actionRun: ActionRunType }) => {
  const [selectedTab, setSelectedTab] = useState('output')

  const tabs = [
    {
      id: 'output',
      text: I18NCommon('output'),
      onClick: () => setSelectedTab('output'),
    },
  ]

  const actionRunLogs = Object
    .entries(actionRun.action.outputMetadata)
    .filter(([_, metadata]) => !(metadata as any).hideFromLogs)

  return (
    <div className='bordered gap-4 py-3 px-3' key={actionRun.id}>
      <ActionDetailsHeader actionRun={actionRun} />

      <Accordion.Collapse eventKey={actionRun.id} className='mt-4'>
        <div className='d-flex flex-column gap-4'>
          <PillTabSelector tabs={tabs} defaultSelectedIndex={0} />

          {selectedTab === 'output' && (
            <div>
              <table>
                <tbody>
                  {actionRunLogs.map(([field, metadata]: [string, any]) => (
                    <tr key={`${actionRun.id}-${field}`}>
                      <td>{I18N_ACTIONS(`${actionRun.action.name}.outputs.${_.snakeCase(field)}`)}</td>
                      <td>
                        <ActionRunOutput
                          key={field}
                          type={metadata.type}
                          field={field}
                          actionRun={actionRun}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Accordion.Collapse>
    </div>
  )
}

export default ActionRunDetails
