import generateCrudRoutes from 'services/generateCrudRoutes'

const buildQuestionPayload = (question) => {
  const questionAttributes: any = _.pick(question, ['content', 'expectedAnswer', 'sources'])

  if (question.user) {
    questionAttributes.userId = question.user.id
  }

  return questionAttributes
}

const aiEvaluationQuestions = generateCrudRoutes(
  '/api/admin/ai/evaluation/questions',
  'question',
  {},
  buildQuestionPayload
)

export default aiEvaluationQuestions
