import React from 'react'
import StyleguideBaseLink from 'pages/admin/styleguide/styleGuideBaseLink'

import AddIcon from 'components/icons/addIcon'
import CdnSvg from 'components/common/cdnSvg'
import BackIcon from 'components/icons/backIcon'
import BadgedTickIcon from 'components/icons/badgedTickIcon'
import BellActive from 'components/icons/bellActive'
import BellInactive from 'components/icons/bellInactive'
import {
  DownBlueChevronIcon,
  DownGrayChevronIcon,
  UpGrayChevronIcon,
  LeftBlueChevronIcon
} from 'components/icons/chevron'
import CircledTickIcon from 'components/icons/circledTickIcon'
import CloseIcon from 'components/icons/closeIcon'
import DeleteIcon from 'components/icons/deleteIcon'
import DocIcon from 'components/icons/docIcon'
import EditIcon from 'components/icons/editIcon'
import GlobeIcon from 'components/icons/globeIcon'
import microsoftLogo from 'assets/microsoftLogo.svg'
import oktaLogo from 'assets/oktaLogo.svg'

const addFileIconPath = '/images/addFileIcon.svg'
const addPersonIconPath = '/images/addPersonIcon.svg'
const addReactionIconPath = '/images/addReactionIcon.svg'
const adpLogoPath = '/images/integrations/adpLogo.svg'
const alertOutlineIcon = '/images/alertOutlineIcon.svg'
const alertTriangleIconPath = '/images/alertTriangleIcon.svg'
const answerIconPath = '/images/answerIcon.svg'
const appLauncherIconPath = '/images/app_launcher/appLauncherIcon.svg'
const archiveBoxIconPath = '/images/archiveBoxIcon.svg'
const audioIconPath = '/images/audioIcon.svg'
const badgeIconPath = '/images/badges/badgeIcon.svg'
const bamboohrLogoPath = '/images/integrations/bamboohrLogo.svg'
const barChartIconPath = '/images/analytics/barChartIcon.svg'
const bellIconPath = '/images/bellIcon.svg'
const blankPageIconPath = '/images/blankPageIcon.svg'
const bookOpenIconPath = '/images/bookOpenIcon.svg'
const briefcaseIconPath = '/images/briefcaseIcon.svg'
const browserPath = '/images/browser.svg'
const calendarIconPath = '/images/calendarIcon.svg'
const calendarPlusIconPath = '/images/calendar.svg'
const camcorderIconPath = '/images/camcorderIcon.svg'
const camcorderPlusCircleIconPath = '/images/camcorderPlusCircleIcon.svg'
const celebrationIcon2Path = '/images/celebrationIcon2.svg'
const celebrationIconPath = '/images/celebrationIcon.svg'
const checkedClipboardIconPath = '/images/checkedClipboardIcon.svg'
const checkIcon = '/images/checkIcon.svg'
const checkListIconPath = '/images/checkListIcon.svg'
const checkmarkIconPath = '/images/checkmarkIcon.svg'
const chevronDownIconPath = '/images/chevronDownOutline.svg'
const chevronLeftIconPath = '/images/chevronLeftOutline.svg'
const chevronRightIconPath = '/images/chevronRightOutline.svg'
const chevronUpIconPath = '/images/chevronUpOutline.svg'
const circledCheckIconPath = '/images/circledCheckIcon.svg'
const circledCrossIconPath = '/images/circledCrossIcon.svg'
const circledPlusIconPath = '/images/circledPlusIcon.svg'
const circledSimpleCheckIconPath = '/images/circledSimpleCheckIcon.svg'
const clipboardOutlinePath = '/images/logos/clipboardOutline.svg'
const clockGoingBackwardsIconPath = '/images/clockGoingBackwardsIcon.svg'
const clockIconPath = '/images/clockIcon.svg'
const codeIconPath = '/images/codeIcon.svg'
const collapseActionIconPath = '/images/collapseActionIcon.svg'
const commentWithEllipsisPath = '/images/commentWithEllipsis.svg'
const computerIconPath = '/images/computerIcon.svg'
const copyIconPath = '/images/copyIcon.svg'
const correspondentIconPath = '/images/correspondentIcon.svg'
const curvedArrowLeftIconPath = '/images/curvedArrowLeft.svg'
const curvedArrowRightIconPath = '/images/curvedArrowRight.svg'
const diskIconPath = '/images/diskIcon.svg'
const duplicateIconPath = '/images/duplicateIcon.svg'
const envelopeCircleIconPath = '/images/envelopeCircleIcon.svg'
const envelopeOutlineIconPath = '/images/envelopeOutlineIcon.svg'
const eyeOpenIconPath = '/images/eyeOpenIcon.svg'
const eyeStrikethroughIconPath = '/images/eyeStrikethroughIcon.svg'
const facebookPath = '/images/logos/facebook.svg'
const facePlusIconPath = '/images/facePlusIcon.svg'
const favoriteStarIconPath = '/images/app_launcher/favoriteStarIcon.svg'
const folderIconPath = '/images/folder.svg'
const forwardArrowIconPath = '/images/forwardArrowIcon.svg'
const funnelIconPath = '/images/funnelIcon.svg'
const gearIconPath = '/images/gearIcon.svg'
const giftIconPath = '/images/giftIcon.svg'
const globeIconPath = '/images/globeIcon.svg'
const gmailLogoPath = '/images/integrations/gmailLogo.svg'
const googleCalendarCircleIcon = '/images/googleCalendarCircleIcon.svg'
const googleCalendarLogoPath = '/images/integrations/googleCalendarLogo.svg'
const googleDriveIconPath = '/images/googleDriveIcon.svg'
const googleLogoPath = '/images/integrations/googleLogo.svg'
const greenhouseLogoPath = '/images/integrations/greenhouseLogo.svg'
const gridIconPath = '/images/app_launcher/gridIcon.svg'
const happyFaceIconPath = '/images/happyFaceIcon.svg'
const hierarchyIconPath = '/images/hierarchyIcon.svg'
const homeIconPath = '/images/homeIcon.svg'
const imageIcon = '/images/imageIcon.svg'
const infoIconPath = '/images/infoIcon.svg'
const leverLogoPath = '/images/integrations/leverLogo.svg'
const lightBulbIconPath = '/images/lightBulbIcon.svg'
const linkedInIconPath = '/images/linkedinIcon.svg'
const linkedinPath = '/images/logos/linkedin.svg'
const linkHorizontalIconPath = '/images/linkHorizontalIcon.svg'
const linkIconPath = '/images/linkIcon.svg'
const listIconPath = '/images/app_launcher/listIcon.svg'
const locationPinIconPath = '/images/locationPinIcon.svg'
const magnifyingGlassZoomInIconPath = '/images/magnifyingGlassZoomInIcon.svg'
const magnifyingGlassZoomOutIconPath = '/images/magnifyingGlassZoomOutIcon.svg'
const megaphoneIconPath = '/images/megaphoneIcon.svg'
const micIconPath = '/images/micIcon.svg'
const mobilePhoneIconPath = '/images/mobilePhoneIcon.svg'
const moveSquareIconPath = '/images/moveSquareIcon.svg'
const namelyLogoPath = '/images/integrations/namelyLogo.svg'
const newspaperIconPath = '/images/newspaperIcon.svg'
const officeBuildingIconPath = '/images/officeBuildingIcon.svg'
const pageIconPath = '/images/pageIcon.svg'
const pagesIconPath = '/images/pagesIcon.svg'
const paperclipIconPath = '/images/paperclipIcon.svg'
const paperPlaneSendIcon2Path = '/images/paperPlaneSendIcon2.svg'
const pencilIconPath = '/images/pencilIcon.svg'
const pencilSquareIconPath = '/images/pencilSquareIcon.svg'
const peopleRoundedSquareIconPath = '/images/peopleRoundedSquareIcon.svg'
const personAddIconPath = '/images/personAddIcon.svg'
const personIconPath = '/images/personIcon.svg'
const personsIconPath = '/images/personsIcon.svg'
const personUnderlineIconPath = '/images/personUnderlineIcon.svg'
const phoneOutlineIconPath = '/images/phoneOutlineIcon.svg'
const playCircleIconPath = '/images/playCircleIcon.svg'
const plusIconPath = '/images/plusIcon.svg'
const presenterIconPath = '/images/presenterIcon.svg'
const questionIconPath = '/images/questionIcon.svg'
const removePersonIconPath = '/images/removePersonIcon.svg'
const rightArrowLogOutIconPath = '/images/rightArrowLogOutIcon.svg'
const rightCornerArrowIconPath = '/images/rightCornerArrowIcon.svg'
const rocketIconPath = '/images/rocketIcon.svg'
const roundedCopyUrlPath = '/images/logos/roundedCopyUrl.svg'
const roundedFacebookPath = '/images/logos/roundedFacebook.svg'
const roundedLinkedinPath = '/images/logos/roundedLinkedin.svg'
const roundedXIconPath = '/images/logos/roundedX.svg'
const searchIconPath = '/images/searchIcon.svg'
const shareIconPath = '/images/shareIcon.svg'
const sidebarIconPath = '/images/sidebarIcon.svg'
const signingPenIcon = '/images/signingPenIcon.svg'
const slackCircleIconPath = '/images/slackCircleIcon.svg'
const slackLogoIconPath = '/images/slackLogoIcon.svg'
const smileOutlineIconPath = '/images/smileOutlineIcon.svg'
const speechBubblesIconPath = '/images/speechBubbles.svg'
const starIconPath = '/images/app_launcher/starIcon.svg'
const stepsIconPath = '/images/stepsIcon.svg'
const swapPeopleIconPath = '/images/swapPeopleIcon.svg'
const syncIconPath = '/images/syncIcon.svg'
const tableIconPath = '/images/tableIcon.svg'
const tabletIconPath = '/images/tabletIcon.svg'
const tagIconPath = '/images/tagIcon.svg'
const teaCupIconPath = '/images/teaCupIcon.svg'
const thinPlusIconPath = '/images/thinPlusIcon.svg'
const threeDotsIconPath = '/images/threeDotsIcon.svg'
const thumbsDownIconPath = '/images/thumbsDownIcon.svg'
const thumbsUpIconPath = '/images/thumbsUpIcon.svg'
const ticketIconPath = '/images/ticket.svg'
const timerIconPath = '/images/timerIcon.svg'
const trashIconPath = '/images/trashIcon.svg'
const triangleDownPath = '/images/triangleDown.svg'
const triangleUpPath = '/images/triangleUp.svg'
const twitterIconPath = '/images/twitterIcon.svg'
const uploadedIconPath = '/images/uploadedIcon.svg'
const uploadIconPath = '/images/uploadIcon.svg'
const upwardCornerArrow = '/images/upward-corner-arrow.svg'
const videoPath = '/images/tiptap/video.svg'
const workdayLogoPath = '/images/integrations/workdayLogo.svg'
const workosLogoPath = '/images/integrations/workosLogo.svg'
const writeIconPath = '/images/writeIcon.svg'
const xIconPath = '/images/logos/x.svg'

const IconsPage = () => {
  const icons = {
    addFileIcon: <CdnSvg src={addFileIconPath} />,
    addIcon: <AddIcon />,
    addPencilIcon: <CdnSvg src='/images/addPencilIcon.svg' />,
    addPersonIcon: <CdnSvg src={addPersonIconPath} />,
    addReactionIcon: <CdnSvg src={addReactionIconPath} />,
    adpLogo: <CdnSvg src={adpLogoPath} />,
    alertCircleIcon: <CdnSvg src='/images/alertCircleIcon.svg' className='IconsPage-alertCircleIcon' />,
    alertOutlineIcon: <CdnSvg src={alertOutlineIcon} />,
    alertTriangleIcon: <CdnSvg src={alertTriangleIconPath} />,
    answerIcon: <CdnSvg src={answerIconPath} />,
    appLauncherIcon: <CdnSvg src={appLauncherIconPath} />,
    archiveBoxIcon: <CdnSvg src={archiveBoxIconPath} />,
    arrowheadLeftIcon: <CdnSvg src='/images/arrowheadLeftIcon.svg' />,
    audioIcon: <CdnSvg src={audioIconPath} />,
    backIcon: <BackIcon />,
    badgedTickIcon: <BadgedTickIcon />,
    badgeIcon: <CdnSvg src={badgeIconPath} />,
    bamboohrLogo: <CdnSvg src={bamboohrLogoPath} />,
    barChartIcon: <CdnSvg src={barChartIconPath} />,
    bellActive: <BellActive />,
    bellIcon: <CdnSvg src={bellIconPath} />,
    bellInactive: <BellInactive />,
    blankPageIcon: <CdnSvg src={blankPageIconPath} />,
    bookOpenIcon: <CdnSvg src={bookOpenIconPath} />,
    briefcaseIcon: <CdnSvg src={briefcaseIconPath} />,
    browser: <CdnSvg src={browserPath} />,
    calendarIcon: <CdnSvg src={calendarIconPath} />,
    calendarPlusIcon: <CdnSvg src={calendarPlusIconPath} />,
    camcorderIcon: <CdnSvg src={camcorderIconPath} />,
    camcorderPlusCircleIcon: <CdnSvg src={camcorderPlusCircleIconPath} />,
    celebrationIcon: <CdnSvg src={celebrationIconPath} />,
    celebrationIcon2: <CdnSvg src={celebrationIcon2Path} />,
    checkedClipboardIcon: <CdnSvg src={checkedClipboardIconPath} />,
    checkIcon: <CdnSvg src={checkIcon} />,
    checkListIcon: <CdnSvg src={checkListIconPath} />,
    checkmarkIcon: <CdnSvg src={checkmarkIconPath} />,
    chevronDownIcon: <CdnSvg src={chevronDownIconPath} />,
    chevronLeftIcon: <CdnSvg src={chevronLeftIconPath} />,
    chevronRightIcon: <CdnSvg src={chevronRightIconPath} />,
    chevronUpIcon: <CdnSvg src={chevronUpIconPath} />,
    circledCheckIcon: <CdnSvg src={circledCheckIconPath} />,
    circledCrossIcon: <CdnSvg src={circledCrossIconPath} />,
    circledPlusIcon: <CdnSvg src={circledPlusIconPath} />,
    circledSimpleCheckIcon: <CdnSvg src={circledSimpleCheckIconPath} />,
    circledTickIcon: <CircledTickIcon />,
    clipboardOutlineIcon: <CdnSvg src={clipboardOutlinePath} />,
    clockGoingBackwardsIconPath: <CdnSvg src={clockGoingBackwardsIconPath} />,
    clockIcon: <CdnSvg src={clockIconPath} />,
    closeIcon: <CloseIcon />,
    codeIcon: <CdnSvg src={codeIconPath} />,
    collapseActionIcon: <CdnSvg src={collapseActionIconPath} />,
    commentIcon: <CdnSvg src='/images/commentIcon.svg' />,
    commentWithEllipsisPath: <CdnSvg src={commentWithEllipsisPath} />,
    computerIcon: <CdnSvg src={computerIconPath} />,
    copyIcon: <CdnSvg src={copyIconPath} />,
    correspondentIcon: <CdnSvg src={correspondentIconPath} />,
    curvedArrowLeftIcon: <CdnSvg src={curvedArrowLeftIconPath} />,
    curvedArrowRightIcon: <CdnSvg src={curvedArrowRightIconPath} />,
    deleteIcon: <DeleteIcon />,
    diskIcon: <CdnSvg src={diskIconPath} />,
    docIcon: <DocIcon />,
    doubleArrowheadLeftIcon: <CdnSvg src='/images/doubleArrowheadLeftIcon.svg' />,
    downBlueChevronIcon: <DownBlueChevronIcon />,
    downGrayChevronIcon: <DownGrayChevronIcon />,
    duplicateIcon: <CdnSvg src={duplicateIconPath} />,
    editIcon: <EditIcon />,
    envelopeCircleIcon: <CdnSvg src={envelopeCircleIconPath} />,
    envelopeOutlineIcon: <CdnSvg src={envelopeOutlineIconPath} />,
    externalLinkIcon: <CdnSvg src='/images/externalLinkIcon.svg' />,
    eyeOpenIcon: <CdnSvg src={eyeOpenIconPath} />,
    eyeStrikethroughIcon: <CdnSvg src={eyeStrikethroughIconPath} />,
    facebook: <CdnSvg src={facebookPath} />,
    facePlusIcon: <CdnSvg src={facePlusIconPath} />,
    favoriteStarIcon: <CdnSvg src={favoriteStarIconPath} />,
    folderIcon: <CdnSvg src={folderIconPath} />,
    forwardArrowIcon: <CdnSvg src={forwardArrowIconPath} />,
    funnelIcon: <CdnSvg src={funnelIconPath} />,
    gearIcon: <CdnSvg src={gearIconPath} />,
    giftIcon: <CdnSvg src={giftIconPath} />,
    globeIcon: <CdnSvg src={globeIconPath} />,
    globeIconComponent: <GlobeIcon />,
    gmailLogo: <CdnSvg src={gmailLogoPath} />,
    googleCalendarCircleIcon: <CdnSvg src={googleCalendarCircleIcon} />,
    googleCalendarLogo: <CdnSvg src={googleCalendarLogoPath} />,
    googleDriveIcon: <CdnSvg src={googleDriveIconPath} />,
    googleLogo: <CdnSvg src={googleLogoPath} />,
    greenhouseLogo: <CdnSvg src={greenhouseLogoPath} />,
    gridIcon: <CdnSvg src={gridIconPath} />,
    happyFaceIcon: <CdnSvg src={happyFaceIconPath} />,
    hierarchyIcon: <CdnSvg src={hierarchyIconPath} />,
    homeIcon: <CdnSvg src={homeIconPath} />,
    imageIcon: <CdnSvg src={imageIcon} />,
    infoIcon: <CdnSvg src={infoIconPath} />,
    leftBlueChevronIcon: <LeftBlueChevronIcon />,
    leverLogo: <CdnSvg src={leverLogoPath} />,
    lightBulbIcon: <CdnSvg src={lightBulbIconPath} />,
    linkedin: <CdnSvg src={linkedinPath} />,
    linkedinIcon: <CdnSvg src={linkedInIconPath} />,
    linkHorizontalIcon: <CdnSvg src={linkHorizontalIconPath} />,
    linkIcon: <CdnSvg src={linkIconPath} />,
    listIcon: <CdnSvg src={listIconPath} />,
    locationPinIcon: <CdnSvg src={locationPinIconPath} />,
    lockIcon: <CdnSvg src='/images/lockIcon.svg' />,
    magnifyingGlassZoomInIcon: <CdnSvg src={magnifyingGlassZoomInIconPath} />,
    magnifyingGlassZoomOutIcon: <CdnSvg src={magnifyingGlassZoomOutIconPath} />,
    megaphoneIcon: <CdnSvg src={megaphoneIconPath} />,
    micIcon: <CdnSvg src={micIconPath} />,
    microsoftLogo: <img src={microsoftLogo} />,
    mobilePhoneIcon: <CdnSvg src={mobilePhoneIconPath} />,
    moveSquareIcon: <CdnSvg src={moveSquareIconPath} />,
    namelyLogo: <CdnSvg src={namelyLogoPath} />,
    newspaperIcon: <CdnSvg src={newspaperIconPath} />,
    officeBuildingIcon: <CdnSvg src={officeBuildingIconPath} />,
    oktaLogo: <img src={oktaLogo} />,
    pageIcon: <CdnSvg src={pageIconPath} />,
    pagesIcon: <CdnSvg src={pagesIconPath} />,
    paperclipIcon: <CdnSvg src={paperclipIconPath} />,
    paperPlaneSendIcon2: <CdnSvg src={paperPlaneSendIcon2Path} />,
    pencilIcon: <CdnSvg src={pencilIconPath} />,
    pencilSquareIcon: <CdnSvg src={pencilSquareIconPath} />,
    peopleRoundedSquareIcon: <CdnSvg src={peopleRoundedSquareIconPath} />,
    personAddIcon: <CdnSvg src={personAddIconPath} />,
    personIcon: <CdnSvg src={personIconPath} />,
    personsIcon: <CdnSvg src={personsIconPath} />,
    personUnderlineIcon: <CdnSvg src={personUnderlineIconPath} />,
    phoneOutlineIcon: <CdnSvg src={phoneOutlineIconPath} />,
    playCircleIcon: <CdnSvg src={playCircleIconPath} />,
    plusIcon: <CdnSvg src={plusIconPath} />,
    preseneterIcon: <CdnSvg src={presenterIconPath} />,
    questionIcon: <CdnSvg src={questionIconPath} />,
    removePersonIcon: <CdnSvg src={removePersonIconPath} />,
    rightArrowLogOutIcon: <CdnSvg src={rightArrowLogOutIconPath} />,
    rightCornerArrowIcon: <CdnSvg src={rightCornerArrowIconPath} />,
    rocketIcon: <CdnSvg src={rocketIconPath} />,
    roundedCopyUrlIcon: <CdnSvg src={roundedCopyUrlPath} />,
    roundedFacebookIcon: <CdnSvg src={roundedFacebookPath} />,
    roundedLinkedinIcon: <CdnSvg src={roundedLinkedinPath} />,
    roundedXIcon: <CdnSvg src={roundedXIconPath} />,
    searchIcon: <CdnSvg src={searchIconPath} />,
    shareIcon: <CdnSvg src={shareIconPath} />,
    signingPenIcon: <CdnSvg src={signingPenIcon} />,
    sidebarIcon: <CdnSvg src={sidebarIconPath} />,
    slackCircleIcon: <CdnSvg src={slackCircleIconPath} />,
    slackLogoIcon: <CdnSvg src={slackLogoIconPath} />,
    smileOutlineIcon: <CdnSvg src={smileOutlineIconPath} />,
    speechBubblesIcon: <CdnSvg src={speechBubblesIconPath} />,
    starIcon: <CdnSvg src={starIconPath} />,
    stepsIcon: <CdnSvg src={stepsIconPath} />,
    swapPeopleIcon: <CdnSvg src={swapPeopleIconPath} />,
    syncIcon: <CdnSvg src={syncIconPath} />,
    tableIcon: <CdnSvg src={tableIconPath} />,
    tabletIcon: <CdnSvg src={tabletIconPath} />,
    tagIcon: <CdnSvg src={tagIconPath} />,
    teaCupIcon: <CdnSvg src={teaCupIconPath} />,
    thinPlusIcon: <CdnSvg src={thinPlusIconPath} />,
    threeDotsIconPath: <CdnSvg src={threeDotsIconPath} />,
    thumbsDownIcon: <CdnSvg src={thumbsDownIconPath} />,
    thumbsUpIcon: <CdnSvg src={thumbsUpIconPath} />,
    ticketIcon: <CdnSvg src={ticketIconPath} />,
    timerIcon: <CdnSvg src={timerIconPath} />,
    trashIcon: <CdnSvg src={trashIconPath} />,
    triangleDownIcon: <CdnSvg src={triangleDownPath} />,
    triangleUpIcon: <CdnSvg src={triangleUpPath} />,
    twitterIcon: <CdnSvg src={twitterIconPath} />,
    upGrayChevronIcon: <UpGrayChevronIcon />,
    uploadedIcon: <CdnSvg src={uploadedIconPath} />,
    uploadIcon: <CdnSvg src={uploadIconPath} />,
    upwardCornerArrow: <CdnSvg src={upwardCornerArrow} />,
    videoIcon: <CdnSvg src={videoPath} />,
    workdayLogo: <CdnSvg src={workdayLogoPath} />,
    workosLogo: <CdnSvg src={workosLogoPath} />,
    writeIcon: <CdnSvg src={writeIconPath} />,
    xCircleOutlineIcon: <CdnSvg src='/images/xCircleOutline.svg' />,
    xIcon: <CdnSvg src={xIconPath} />,
  }

  const makeRows = () => {
    const content = []
    Object.keys(icons).forEach((key) => {
      content.push(
        <tr key={key}>
          <td>
            <h5 className='text-secondary'>{key}</h5>
          </td>
          <td>
            {icons[key]}
          </td>
          <td className='table-dark'>
            {icons[key]}
          </td>
        </tr>
      )
    })
    return content
  }

  return (
    <main className='Styleguide IconsPage container mt-4'>
      <h1><StyleguideBaseLink /> / Icons</h1>
      <section className='mt-6'>
        <h3>Common</h3>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th>File Name</th>
              <th>Icon</th>
              <th className='table-dark'>Icon with dark background</th>
            </tr>
          </thead>
          <tbody>
            {makeRows()}
          </tbody>
        </table>
      </section>
    </main>
  )
}

export default IconsPage
