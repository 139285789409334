import React, { useState } from 'react'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import { SIMPLE_EDITOR_CONFIGURATION } from 'components/common/tiptap/configurations'
import { PDF_TEMPLATE_TOOLBAR } from 'components/common/tiptap/toolbar/toolbarVariations'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import { Button } from 'components/common/buttons'
import AppModal from 'components/common/modal'
import SmartPdfModal from 'components/smartPdfModal'

const I18N = i18nPath('views.admin.workflows.actions')

const defaultHtml = `
<p>Company Name</p>
<p>Company Address 123 street</p>
<p />
<p>{{date_of_submission}}</p>
<p />
<p>Employment Verification for {{employee_name}}</p>
<p />
<p>To who it may concern,</p>
<p />
<p>This letter confirms {{employee_name}}’s employment as a {{job_tittle}}. They started work on {{start_date}} and are currently {{employement_status}}.</p>
<p />
<p>Their compensation is {{employee_salary}} per {{work_period}}.</p>
<p />
<p>If you have further questions, please contact us at 000-000-0000 or company@company.com.</p>
`

const ClearyRichTextInput = ({
  onChange, value, settings, onSave, field, action,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleSave = () => {
    onSave()
    toggleModal()
  }

  return (
    <>
      <div>
        <Button variant='secondary' onClick={toggleModal}>
          {I18NCommon('edit')} {I18N(`${action.name}.setup.${_.snakeCase(field)}`)}
        </Button>
      </div>

      {isModalOpen && (
        <SmartPdfModal
          toggleModal={toggleModal}
          filename={settings.filename}
          handleSave={handleSave}
          onChange={onChange}
          value={value}
          defaultHtml={defaultHtml}
        />
      )}
    </>
  )
}

export default ClearyRichTextInput
