import React, { useState } from 'react'

import useApi from 'components/common/hooks/useApi'
import CloseIcon from 'components/icons/closeIcon'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'
import { trackAmplitudeEvent } from 'services/tracker'

const HAS_TRIED_NEW_UI_KEY = 'hasTriedNewUi'
const NEW_UI_USER_PREVIEW = 'new_ui.user_preview'

const I18N = i18nPath('views.navbar.try_new_ui_banner')

const TryNewUiBanner = () => {
  const [showBanner, setShowBanner] = useState(localStorage.getItem(HAS_TRIED_NEW_UI_KEY) !== 'true')
  const [updateUserSetting] = useApi(API.settings.update, {
    onSuccess: () => window.location.reload(),
  })

  const onTryNow = () => {
    updateUserSetting({
      name: NEW_UI_USER_PREVIEW,
      value: { enabled: true },
    })
    localStorage.setItem(HAS_TRIED_NEW_UI_KEY, 'true')
    localStorage.setItem('openProfileDropdown', 'true')

    trackAmplitudeEvent('new_nav_opt_in', {
      source: 'banner',
    })
  }

  const onClose = () => {
    setShowBanner(false)
    localStorage.setItem(HAS_TRIED_NEW_UI_KEY, 'true')
  }

  if (!showBanner) {
    return null
  }

  return (
    <div className='TryNewUiBanner d-flex align-items-center position-relative w-100 justify-content-center'>
      <span className='Banner-text'>
        {I18N('new_look')}
      </span>
      <div className='Banner-text pointer ml-2 font-weight-600' onClick={onTryNow}>
        {I18N('try_now')}
      </div>

      <div className='Banner-close position-absolute d-flex' onClick={onClose}>
        <CloseIcon />
      </div>
    </div>
  )
}

export default TryNewUiBanner
