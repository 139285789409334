import { Button } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import ClearyTooltip from 'components/common/clearyTooltip'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.platform_analytics.ai_answers')

type EvaluationProps = {
  confidenceScore?: number
  answerRelevanceScore?: number
  contextRelevanceScore?: number
  faithfulnessScore?: number
  ragasScore?: number
  expectedAnswerScore?: number
  answerConcisenessScore?: number
  onEvaluate?: () => void
}

const formatFloat = (value: number) => value.toFixed(2)


const AiEvaluation = ({
  confidenceScore,
  answerRelevanceScore,
  contextRelevanceScore,
  faithfulnessScore,
  ragasScore,
  expectedAnswerScore,
  answerConcisenessScore,
  onEvaluate,
}: EvaluationProps) => {
  const evaluations: string[] = []

  const hasConfidenceScore = typeof confidenceScore === 'number'
  const hasRagasScore = typeof ragasScore === 'number'
  const hasFaithfulnessScore = typeof faithfulnessScore === 'number'
  const hasAnswerRelevanceScore = typeof answerRelevanceScore === 'number'
  const hasContextRelevanceScore = typeof contextRelevanceScore === 'number'
  const hasExpectedAnswerScore = typeof expectedAnswerScore === 'number'
  const hasAnswerConcisenessScore = typeof answerConcisenessScore === 'number'

  if (hasExpectedAnswerScore) {
    evaluations.push(`EAS: ${formatFloat(expectedAnswerScore)}`)
  }

  if (hasAnswerConcisenessScore) {
    evaluations.push(`ACS: ${formatFloat(answerConcisenessScore)}`)
  }

  if (hasConfidenceScore) {
    evaluations.push(`S: ${formatFloat(confidenceScore / 10)}`)
  }

  if (hasRagasScore) {
    evaluations.push(`RS: ${formatFloat(ragasScore)}`)
  }

  if (hasFaithfulnessScore) {
    evaluations.push(`FS: ${formatFloat(faithfulnessScore)}`)
  }

  if (hasAnswerRelevanceScore) {
    evaluations.push(`AR: ${formatFloat(answerRelevanceScore)}`)
  }

  if (hasContextRelevanceScore) {
    evaluations.push(`CR: ${formatFloat(contextRelevanceScore)}`)
  }

  const syncButton = onEvaluate ? (
    <Button
      className='mx-2 p-0'
      onClick={onEvaluate}
      variant='link'
    >
      <ClearyTooltip
        placement='bottom'
        content={<CdnSvg src='/images/syncIcon.svg' className='AiEvaluationSyncIcon' />}
      >
        {I18N('evaluation.evaluate')}
      </ClearyTooltip>
    </Button>
  ) : null

  if (evaluations.length === 0) return syncButton

  return (
    <div className='text-small'>
      <span className='text-small'>
        {evaluations.join(', ')} {syncButton}

        <ClearyTooltip placement='bottom'>
          <div className='text-small text-white'>
            {hasExpectedAnswerScore && (
              <span className='d-block mb-2'>
                EAS: {I18N('evaluation.expected_answer_score_help_text')}
              </span>
            )}
            {hasAnswerConcisenessScore && (
              <span className='d-block mb-2'>
                ACS: {I18N('evaluation.answer_conciseness_score_help_text')}
              </span>
            )}
            {hasConfidenceScore && (
              <span className='d-block mb-2'>
                S: {I18N('evaluation.score_help_text')}
              </span>
            )}
            {hasRagasScore && (
              <span className='d-block mb-2'>
                RS: {I18N('evaluation.ragas_score_help_text')}
              </span>
            )}
            {hasFaithfulnessScore && (
              <span className='d-block mb-2'>
                FS: {I18N('evaluation.faithfulness_score_help_text')}
              </span>
            )}
            {hasAnswerRelevanceScore && (
              <span className='d-block mb-2'>
                AR: {I18N('evaluation.answer_relevance_score_help_text')}
              </span>
            )}
            {hasContextRelevanceScore && (
              <span className='d-block mb-2'>
                CR: {I18N('evaluation.context_relevance_score_help_text')}
              </span>
            )}
          </div>
        </ClearyTooltip>
      </span>
    </div>
  )
}

export default AiEvaluation
