import React, { useRef, useState } from 'react'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import SmartTable from 'components/common/tables/smartTable'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { VersionsList, WhoDidIt } from 'components/common/versionsTable'
import ReactSelect from 'components/common/react_select'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import DatePicker from 'components/form_fields/datePicker'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'

const I18N = i18nPath('views.admin.audit_logs')

const date180DaysAgo = i18nMoment().subtract(180, 'days')

const ACTION_OPTIONS = [
  { value: '', label: I18N('all_actions') },
  { value: 'create', label: I18N('create') },
  { value: 'update', label: I18N('update') },
  { value: 'destroy', label: I18N('destroy') },
]

const AuditLogsPage = () => {
  const ref = useRef<string>('')
  const [action, setAction] = useQueryParamState({ param: 'action', initialValue: '', ref })
  const [userId, setUserId] = useQueryParamState({ param: 'userId', initialValue: null, ref })
  const [prevCursor, setPrevCursor] = useQueryParamState<string | null>({ param: 'prevCursor', initialValue: null, ref })
  const [nextCursor, setNextCursor] = useQueryParamState<string | null>({ param: 'nextCursor', initialValue: null, ref })
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null)
  const [startTime, setStartTime] = useQueryParamState<any>({ param: 'startTime', initialValue: '', ref })
  const [selectedStartDate, setSelectedStartDate] = useState<any>(startTime ? i18nMoment(startTime) : i18nMoment().subtract(1, 'days'))
  const [endTime, setEndTime] = useQueryParamState<any>({ param: 'endTime', initialValue: null, ref })
  const [selectedEndDate, setSelectedEndDate] = useState<any>(endTime ? i18nMoment(endTime) : null)
  const [itemId, setItemId] = useQueryParamState<string>({ param: 'itemId', initialValue: '', ref })

  const {
    isLoading, data: versions = [], paginationData,
  } = useFetch(() => API.admin.versions.fetchAll({
    event: action,
    user_id: userId,
    start_time: selectedStartDate?.toISOString(),
    end_time: selectedEndDate?.toISOString(),
    item_id: itemId,
    prev_cursor: prevCursor,
    next_cursor: nextCursor,
  }), [prevCursor, nextCursor, action, userId, selectedStartDate, selectedEndDate, itemId])


  useFetch(() => API.users.simpleFetchAll([userId]), [userId], {
    onSuccess: data => setSelectedEmployee(data),
    load: !!userId,
  })

  const columns = [
    {
      header: I18N('responsible'),
      accessor: version => <WhoDidIt version={version} />,
    },
    {
      header: I18N('date'),
      accessor: version => i18nMoment(version.createdAt).format('lll'),
    },
    {
      header: I18N('item'),
      accessor: version => `${version.itemType?.split('::')?.pop()} (${version.itemId})`,
    },
    {
      header: I18N('action'),
      accessor: version => version.event,
    },
    {
      header: I18N('changes'),
      accessor: (version) => {
        const changeSet = Object.entries(version.changeset)

        return <VersionsList changeSet={changeSet} ignoredFields={[]} isCreateEvent={version.event === 'create'} />
      },
    },
  ]

  const resetPagination = () => {
    setNextCursor(null)
    setPrevCursor(null)
  }

  const handleEmployeeChange = (employee) => {
    setUserId(employee?.id)
    setSelectedEmployee(employee)
    resetPagination()
  }

  const handleStartDateChange = (date) => {
    setStartTime(date?.toISOString())
    setSelectedStartDate(date)
    resetPagination()
  }

  const handleEndDateChange = (date) => {
    setEndTime(date?.toISOString())
    setSelectedEndDate(date)
    resetPagination()
  }

  const handleItemIdChange = (e) => {
    setItemId(e.target.value)
    resetPagination()
  }

  const handleActionChange = (newAction) => {
    setAction(newAction.value)
    resetPagination()
  }

  const onNextCursorPaginationClick = () => {
    setNextCursor(paginationData.nextCursor)
    setPrevCursor(null)
  }

  const onPrevCursorPaginationClick = () => {
    setPrevCursor(paginationData.prevCursor)
    setNextCursor(null)
  }

  return (
    <div className='AuditLogsPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('title')}</h3>
      </header>

      <main className='AdminContent'>
        <div className='d-flex justify-content-start mb-2 gap-2'>
          <EmployeeSearch
            onChange={handleEmployeeChange}
            selectedEmployee={selectedEmployee}
            placeholder={I18N('user_filter_placeholder')}
            includeHiddenUsers
            includeInactiveUsers
          />
          <DatePicker
            className='DateTimePicker'
            selected={selectedStartDate && i18nMoment(selectedStartDate)}
            onChange={handleStartDateChange}
            showTimeSelect
            isClearable
            dateFormat='LLL'
            placeholderText={I18N('start_time')}
            minDate={date180DaysAgo}
          />
          <DatePicker
            className='DateTimePicker'
            selected={selectedEndDate && i18nMoment(selectedEndDate)}
            onChange={handleEndDateChange}
            showTimeSelect
            isClearable
            dateFormat='LLL'
            placeholderText={I18N('end_time')}
            minDate={date180DaysAgo}
          />
          <input
            type='text'
            className=''
            placeholder={I18N('item_filter_placeholder')}
            onChange={handleItemIdChange}
            value={itemId}
          />
          <ReactSelect
            name='action_filter'
            value={ACTION_OPTIONS.find(option => action === option.value)}
            options={ACTION_OPTIONS}
            onChange={newAction => handleActionChange(newAction)}
            placeholder={I18N('action_filter_placeholder')}
          />
        </div>

        {isLoading ? (
          <CirclesLoadingIndicator />
        ) : (
          <SmartTable
            columns={columns}
            data={versions}
            className='white-bg-table'
            showCursorPagination
            nextCursor={paginationData.nextCursor}
            prevCursor={paginationData.prevCursor}
            onNextCursorPaginationClick={onNextCursorPaginationClick}
            onPrevCursorPaginationClick={onPrevCursorPaginationClick}
          />
        )}
      </main>
    </div>
  )
}

export default AuditLogsPage
