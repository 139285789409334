import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import ToggleLeftNavButton from 'components/common/toggleLeftNavButton'
import useLeftNavItems from 'components/navbar/hooks/useLeftNavItems'
import LeftNavItem from 'components/navbar/leftNavItem'
import useWindowResize from 'components/common/hooks/useWindowResize'
import useAdminLeftNavItems from 'components/navbar/hooks/useAdminLeftNavItems'
import { i18nPath } from 'utils/i18nHelpers'
import ConfigureNavbar from 'components/navbar/configureNavbar'
import navbarSlice from 'redux/slices/navbarSlice'

const I18N = i18nPath('views.navbar')

const LEFT_NAV_STORAGE_KEY = 'isLeftNavbarOpen'

const LeftNavbar = () => {
  const localStorageIsLeftNavbarOpen = localStorage.getItem(LEFT_NAV_STORAGE_KEY) === 'true'
  const [isOpen, setIsOpen] = useState(localStorageIsLeftNavbarOpen)
  const [isHovered, setIsHovered] = useState(false)
  const navItems = useLeftNavItems()
  const { adminNavItems, hasAdminAccess } = useAdminLeftNavItems()
  const { isXXLScreen, isUpToMediumScreen } = useWindowResize()
  const isHidden = useSelector(navbarSlice.selectors.isNavbarHidden())

  const isExpanded = isOpen || isHovered

  useEffect(() => {
    if (!isXXLScreen && isOpen) {
      setIsOpen(false)
    }
  }, [isXXLScreen])

  const onToggleLeftNavbar = () => {
    const newIsOpen = !isOpen
    setIsOpen(newIsOpen)
    localStorage.setItem(LEFT_NAV_STORAGE_KEY, newIsOpen.toString())
  }

  if (isHidden || isUpToMediumScreen) {
    return null
  }

  return (
    <>
      {isXXLScreen && (
        <ToggleLeftNavButton
          isOpen={isOpen}
          onClick={onToggleLeftNavbar}
          useNewUi
          className={classNames({ hidden: isHovered })}
        />
      )}

      <div
        className={classNames('LeftNavbar', { isOpen: isExpanded, isHovered })}
        onMouseEnter={() => !isOpen && setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {navItems.map(item => (
          <LeftNavItem key={item.label} {...item} isLeftNavOpen={isExpanded} />
        ))}

        {hasAdminAccess && (
          <>
            <hr />

            <span className='AdminLabel text-smallest'>{I18N('admin').toUpperCase()}</span>

            {adminNavItems.map(item => (
              <LeftNavItem key={item.label} {...item} isLeftNavOpen={isExpanded} />
            ))}
          </>
        )}
      </div>
      <ConfigureNavbar />
    </>
  )
}

export default LeftNavbar
