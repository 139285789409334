import React from 'react'
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  type EdgeProps
} from '@xyflow/react'
import CdnSvg from 'components/common/cdnSvg'
import ClearyTooltip from 'components/common/clearyTooltip'
import { i18nPath } from 'utils/i18nHelpers'

type Props = EdgeProps & {
  data: {
    onEdgeClick: () => void
  }
}

const I18N = i18nPath('views.admin.workflows.actions')

const AddActionEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}: Props) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          className='button-edge__label nodrag nopan'
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
        >
          <button className='button-edge__button' onClick={data.onEdgeClick}>
            <ClearyTooltip
              content={<CdnSvg src='/images/plusIcon.svg' />}
              placement='bottom'
            >
              <span>{I18N('add_action_tooltip')}</span>
            </ClearyTooltip>
          </button>
        </div>
      </EdgeLabelRenderer>
    </>
  )
}

export default AddActionEdge
