import React, { useState } from 'react'
import AppModal from 'components/common/modal'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import ReactSelect from 'components/common/react_select'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import CancelButton from 'components/common/cancelButton'
import { ButtonNarrow } from 'components/common/buttons'
import { i18nPath } from 'utils/i18nHelpers'
import ActionIcon from 'components/workflows/actionIcon'

type Props = {
  workflowId: string
  onCancel: () => void
  onCreate: (action: any) => void
  position: number | 'last'
}

const I18N = i18nPath('views.admin.workflows.actions')

const CreateActionModal = ({
  workflowId,
  onCancel,
  onCreate,
  position,
}: Props) => {
  const [actionType, setActionType] = useState<any>(null)

  const { data: availableActions, isLoading, isLoaded } = useFetch(
    API.admin.workflows.availableActions.fetchAll
  )

  const [createAction, { isLoading: isCreatingAction }] = useApi(
    API.admin.workflows.actions.create,
    {
      onSuccess: onCreate,
    }
  )

  const handleSelectActionType = (actionType) => {
    setActionType(actionType.value)
  }

  const handleSave = () => {
    createAction(workflowId, { type: actionType, positionPosition: position })
  }

  return (
    <AppModal
      visible
      modalContentClassName='CreateActionModal container d-flex flex-column w-100'
      toggle={onCancel}
    >
      <>
        <div className='d-flex flex-column align-items-start justify-content-between mb-3'>
          <h3 className='mb-0'>Choose an action</h3>
        </div>
        <section className='mb-5'>
          <label className='text-smallest text-secondary'>Action type</label>
          {isLoading || !isLoaded ? (
            <CirclesLoadingIndicator />
          ) : (
            <ReactSelect
              options={availableActions?.map(action => ({
                label: (
                  <div className='d-flex align-items-center gap-2'>
                    <ActionIcon action={action} />
                    {I18N(`${action.name}.label`)}
                  </div>
                ),
                value: action.id,
              }))}
              onChange={handleSelectActionType}
            />
          )}
        </section>

        <div className='d-flex justify-content-end align-items-center'>
          <CancelButton onClick={onCancel} />
          <ButtonNarrow
            onClick={handleSave}
            disabled={isCreatingAction}
            showLoadingSpinner={isCreatingAction}
          >
            Save
          </ButtonNarrow>
        </div>
      </>
    </AppModal>
  )
}

export default CreateActionModal
