import AppModal from 'components/common/modal'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.admin.workflows.actions')

const JsonOutput = ({ field, actionRun }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  let jsonResponse

  try {
    jsonResponse = JSON.parse(actionRun.output[field])
  } catch (e) {
    jsonResponse = actionRun.output[field]
  }

  return (
    <>
      <Button variant='secondary' onClick={toggleModal}>
        {I18NCommon('view')} {I18N(`${actionRun.action.name}.outputs.${_.snakeCase(field)}`)}
      </Button>
      {isModalOpen && (
        <AppModal
          visible
          toggle={toggleModal}
          modalContentClassName='WorkflowRunPage__Output__JsonOutput__Modal'
          modalTitleClassName='w-100'
          title={(
            <div className='d-flex justify-content-center align-items-center'>
              <h4>{I18N(`${actionRun.action.name}.outputs.${_.snakeCase(field)}`)}</h4>
            </div>
          ) as any}
        >
          <div className='TiptapEditorContentWrapper'>
            <pre>
              <code>{JSON.stringify(jsonResponse, null, 2)}</code>
            </pre>
          </div>
        </AppModal>
      )}
    </>
  )
}

export default JsonOutput
