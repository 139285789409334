import React from 'react'
import { Accordion } from 'react-bootstrap'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import { WorkflowRunType, WorkflowType } from 'types/workflow'
import DataList from 'components/common/dataList'
import TriggerableLabel from 'components/workflows/triggerableLabel'
import WorkflowRunStatus from 'components/workflows/workflowRunStatus'
import EmployeeSidebarCard from 'components/analytics/ai_answers/employeeSidebarCard'
import ActionRunDetails from './actionRunDetails'

const I18N = i18nPath('views.admin.workflows.run_page')

type Props = {
  workflow: WorkflowType
  run: WorkflowRunType
}

const Content = ({ workflow, run }: Props) => (
  <div className='WorkflowRunPage__Content'>
    <DataList
      data={[
        { dataTitle: I18N('data_list.workflow'), dataValue: workflow.name },
        { dataTitle: I18N('data_list.trigger'), dataValue: <TriggerableLabel triggerableType={run.triggerableType} /> },
        { dataTitle: I18N('data_list.started'), dataValue: i18nMoment(run.startedAt).format('lll') },
        { dataTitle: I18N('data_list.completed'), dataValue: i18nMoment(run.completedAt).format('lll') },
        { dataTitle: I18N('data_list.status'), dataValue: <WorkflowRunStatus status={run.status} /> },
      ]}
    />

    {(run.triggerable.employee || run.triggerable.user) && (
      <DataList
        data={[
          { dataTitle: I18N('data_list.requested_by'), dataValue: <EmployeeSidebarCard user={run.triggerable.employee || run.triggerable.user} /> },
        ]}
      />
    )}

    <h5 className='text-small text-primary mb-2 bold'>{I18N('data_list.actions_overview')}</h5>

    <Accordion className='d-flex flex-column gap-4'>
      {run.actionRuns.map(actionRun => (
        <ActionRunDetails key={actionRun.id} actionRun={actionRun} />
      ))}
    </Accordion>
  </div>
)

export default Content
