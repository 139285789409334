import React, { useMemo, useState } from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import ReactSelect from 'components/common/react_select'
import ActionIcon from 'components/workflows/actionIcon'
import CdnSvg from 'components/common/cdnSvg'
import classNames from 'classnames'

const I18N = i18nPath('views.admin.workflows.actions')

const VariablesSelect = ({
  action,
  field,
  value,
  onChange,
}) => {
  const [collapsedGroups, setCollapsedGroups] = useState<Set<string>>(new Set())

  const filteredAvailableInputs = useMemo(
    () => action
      .availableConfigurationInputs
      .filter(input => input.outputMetadata.type === action.configurationMetadata[field].type),
    [action.availableConfigurationInputs, action.configurationMetadata, field]
  )

  const groupedAvailableInputs = useMemo(
    () => _.groupBy(filteredAvailableInputs, 'actionId'),
    [filteredAvailableInputs]
  )

  const groupedOptions = useMemo(() => Object.entries(groupedAvailableInputs).map(([_actionId, inputs], index) => ({
    label: inputs[0].name,
    options: inputs,
    index,
  })), [groupedAvailableInputs])

  const visibleOptions = useMemo(() => groupedOptions.map(group => ({
    label: group.label,
    // We need to show a dummy option to make the group collapsible because react-select won't
    // show the group label if the group is empty.
    options: collapsedGroups.has(group.label)
      ? [{
        isHidden: true,
        actionId: group.label,
        outputKey: '',
        name: '',
      }]
      : group.options,
    index: group.index,
  })), [groupedOptions, collapsedGroups])

  const handleGroupClick = (groupLabel: string) => {
    setCollapsedGroups((prevGroups) => {
      const nextGroups = new Set(prevGroups)

      if (nextGroups.has(groupLabel)) {
        nextGroups.delete(groupLabel)
      } else {
        nextGroups.add(groupLabel)
      }

      return nextGroups
    })
  }

  return (
    <div className='VariablesSelect'>
      <ReactSelect
        getOptionLabel={option => (
          <>
            {option.outputKey}
            <span className='ml-2 text-secondary text-small'>
              {I18N(`${option.name}.label`)}
            </span>
          </>
        )}
        getOptionValue={option => `${option.actionId}-${option.outputKey}`}
        formatGroupLabel={group => (
          <div
            className='d-flex align-items-center gap-1'
            onClick={(e) => {
              e.stopPropagation()
              handleGroupClick(group.label)
            }}
            style={{ cursor: 'pointer' }}
          >
            <ActionIcon action={{ name: group.label }} />
            <span className='text-small font-weight-bold'>
              {group.index + 1}. {I18N(`${group.label}.label`)}
            </span>
            <span className='ml-auto'>
              {collapsedGroups.has(group.label) ? (
                <CdnSvg src='/images/chevronDownOutline.svg' />
              ) : (
                <CdnSvg src='/images/chevronUpOutline.svg' />
              )}
            </span>
          </div>
        )}
        options={visibleOptions}
        value={value}
        onChange={onChange}
        components={{
          Option: ({ data, ...props }) => {
            if (data.isHidden) return null

            return (
              <div
                className={classNames('py-1', 'pointer', { selected: props.isSelected })}
                {...props.innerProps}
              >
                <div className='pl-5'>
                  {data.outputKey}
                </div>
              </div>
            )
          },
        }}
      />
    </div>
  )
}

export default VariablesSelect
