import React from 'react'
import { useParams, Link } from 'react-router-dom'
import API from 'services/api'
import { WorkflowRunType, WorkflowType } from 'types/workflow'
import useFetch from 'components/common/hooks/useFetch'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import { i18nPath } from 'utils/i18nHelpers'
import BackButton from 'components/common/backButton'
import { Breadcrumb } from 'react-bootstrap'
import Content from './workflowRunPage/content'

const I18N = i18nPath('views.admin.workflows.run_page')
const I18N_BREADCRUMB = i18nPath('views.admin.workflows.breadcrumb')

const WorkflowRunPage = () => {
  const { workflowId, runId } = useParams()

  const { data: workflow, isLoading: isLoadingWorkflow } = useFetch<WorkflowType>(
    API.admin.workflows.fetch,
    [workflowId]
  )

  const { data: run, isLoading } = useFetch<WorkflowRunType>(
    API.admin.workflows.runs.fetch,
    [workflowId, runId]
  )

  if (isLoadingWorkflow || !workflow || isLoading || !run) {
    return <CirclesLoadingIndicator />
  }

  return (
    <div className='WorkflowRunPage'>
      <header className='AdminHeader d-flex justify-content-between'>
        <div>
          <h4 className='mb-0'>{I18N('title')}</h4>
        </div>
      </header>

      <main className='AdminContent'>
        <BackButton />
        <Breadcrumb className='CustomBreadcrumb mt-2 mb-4'>
          <Breadcrumb.Item>
            <Link to='/admin/workflows'>{I18N_BREADCRUMB('workflows')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/admin/workflows/${workflow.id}`}>{workflow.name}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {I18N_BREADCRUMB('run', { number: run.number })}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Content workflow={workflow} run={run} />
      </main>
    </div>
  )
}

export default WorkflowRunPage
