import { CompanyType } from 'types/company'

export type SourceType = {
  title: string
  iconUrl: string
  url: string
  type: string
  id: string
}

export enum AssistantAnswerStatuses {
  NotAnswered = 'not_answered',
  AnsweredNoFeedback = 'answered_no_feedback',
  AnsweredCorrect = 'answered_correct',
  AnsweredIncorrect = 'answered_incorrect',
}

export interface AssistantAnswerType {
  id: string
  sources: SourceType[]
  answer: string
  confidenceScore: number
  status: AssistantAnswerStatuses
  company?: CompanyType
  answerRelevanceScore?: number
  contextRelevanceScore?: number
  faithfulnessScore?: number
  ragasScore?: number
  evaluable?: boolean
}
