import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CardWithTable from 'components/analytics/common/cardWithTable'
import EmptyStateCard from 'components/analytics/common/emptyStateCard'
import RoundedAvatars from 'components/common/roundedAvatars'
import { Link } from 'react-router-dom'
import newsAnalyticsSlice from 'redux/slices/analytics/news'
import { EngagingArticleType } from 'types/analytics/news'
import { toPercentage } from 'utils/analytics'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import usePathReusingQueryParams from 'components/common/hooks/usePathReusingQueryParams'
import { TAB_HEADER_QUERY_PARAMS } from 'components/analytics/helpers/useTabHeader'
import TargetingRulesType from 'types/audience/targetingRules'

const I18N = i18nPath('views.platform_analytics.news.most_engaging_articles')
const womanWithComputerPath = '/images/analytics/womanWithComputerIcon.svg'

interface MostEngagingArticlesProps {
  periodStart: string
  periodEnd?: string
  targetingRules?: TargetingRulesType
}

const MostEngagingArticles = ({ periodStart, periodEnd, targetingRules }: MostEngagingArticlesProps) => {
  const dispatch = useDispatch()

  const { data, totalItems, totalPages } = useSelector(newsAnalyticsSlice.selectors.getMostEngagingArticles())
  const { mostEngagingArticles: { isLoading } } = useSelector(newsAnalyticsSlice.selectors.getMetaData())

  useEffect(() => {
    dispatch(newsAnalyticsSlice.asyncActions.admin.fetchMostEngagingArticles(
      {
        periodStart, periodEnd, page: 1, perPage: 10, targetingRules,
      }
    ))
  }, [periodStart, periodEnd, targetingRules])

  const isEmpty = totalItems === 0 && !isLoading

  const columns = [
    {
      header: I18NCommon('title'),
      col: 'col-title',
      accessor: (article: EngagingArticleType) => <Link to={`/admin/articles/${article.id}/stats`} className='text-truncate w-100 d-block' target='_blank'>{article.title}</Link>,
    },
    {
      header: I18NCommon('authors'),
      style: { width: '20%' },
      col: 'col-author',
      accessor: (article: EngagingArticleType) => (
        <div className='d-flex flex-row align-items-center'>
          <RoundedAvatars
            users={article.authors}
            avatarSize='25px'
            className='mr-2'
            shownAvatars={1}
          />
        </div>
      ),
    },
    {
      header: I18NCommon('audience_reach'),
      col: 'col-audience-reach',
      style: { width: '20%' },
      accessor: (article: EngagingArticleType) => (
        toPercentage(article.reach)
      ),
    },
  ]

  const path = usePathReusingQueryParams({
    path: '/platform_analytics/news/most_engaging_articles',
    paramsToReuse: TAB_HEADER_QUERY_PARAMS,
  })

  if (isEmpty) {
    return (
      <EmptyStateCard
        title={I18N('title')}
        iconPath={womanWithComputerPath}
        text={I18N('empty_state_text')}
        classname='ArticlesTables'
      />
    )
  }

  return (
    <CardWithTable
      title={I18N('title')}
      tableColumns={columns}
      tableData={data}
      classname='ArticlesTables'
      isLoading={isLoading}
      showSeeMoreLink
      seeMorePath={path}
    />
  )
}

export default MostEngagingArticles
