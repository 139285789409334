import React from 'react'
import { SimpleUserType } from 'types/user'
import { i18nMoment } from 'utils/i18nHelpers'
import UserLink from 'components/common/userLink'
import TipTapRichTextView from 'components/common/tiptap/tiptapRichTextView'

const Message = ({
  user,
  date,
  html,
  userDetails,
}: {
  user?: SimpleUserType
  date: string
  html: string
  userDetails?: {
    name: string
    email: string
  }
}) => (
  <div className='Message bordered px-3 py-2'>
    <div className='d-flex justify-content-between align-items-center mb-3'>
      <div>
        <UserLink
          user={user}
          avatarSize='24px'
          customClassName='text-normal text-primary'
          unknownUserText={userDetails?.name}
        />
      </div>
      <div className='text-small font-weight-600'>{i18nMoment(date).fromNow()}</div>
    </div>
    <div className='mt-2'>
      <TipTapRichTextView html={html} />
    </div>
  </div>
)

export default Message
