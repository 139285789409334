import React from 'react'
import CdnSvg from 'components/common/cdnSvg'

const ActionStepStateIcon = ({ action, step }) => {
  if (action[`${step}Ready`]) {
    return <CdnSvg src='/images/illustrations/circleSuccessIcon.svg' />
  }

  return <CdnSvg src='/images/illustrations/circleWarningIcon.svg' />
}

export default ActionStepStateIcon
