import React from 'react'
import { Handle, Position, NodeProps } from '@xyflow/react'
import CdnSvg from 'components/common/cdnSvg'
import classNames from 'classnames'
import { i18nPath } from 'utils/i18nHelpers'
import ActionIcon from 'components/workflows/actionIcon'
import ActionStateIcon from 'components/workflows/actionStateIcon'

const I18N = i18nPath('views.admin.workflows.actions')

interface ActionNodeProps extends NodeProps {
  selected: boolean
  data: {
    index: number
    workflowAction: any
    isFirst: boolean
    onDelete: () => void
  }
}

const ActionNode = ({ data, selected }: ActionNodeProps) => (
  <div
    className={classNames(
      'WorkflowEditPage__Actions__ActionNode',
      {
        'WorkflowEditPage__Actions__ActionNode--first': data.isFirst,
        'WorkflowEditPage__Actions__ActionNode--selected': selected,
      }
    )}
  >
    {!data.isFirst && (
      <Handle
        type='target'
        position={Position.Top}
        style={{
          backgroundColor: 'transparent',
          border: '0',
        }}
      />
    )}
    <div className='d-flex align-items-center justify-content-between'>
      <div className='d-flex align-items-center gap-2'>
        <div>
          <ActionStateIcon action={data.workflowAction} />
        </div>
        <div className='d-flex align-items-center gap-1 WorkflowEditPage__Actions__ActionNode__action-name bordered'>
          <ActionIcon action={data.workflowAction} />
          <div className='text-smallest'>
            {I18N(`${data.workflowAction.name}.label`)}
          </div>
        </div>
      </div>
      <div>
        <div
          className='WorkflowEditPage__Actions__ActionNode__delete-icon'
          onClick={data.onDelete}
          data-testid={`rf__node-delete-action-${data.workflowAction.id}`}
        >
          <CdnSvg src='/images/trashIcon.svg' />
        </div>
      </div>
    </div>
    <hr />
    <div className='WorkflowEditPage__Actions__ActionNode__action-description'>
      <div className='text-smallest text-secondary'>
        {data.index + 1}. {I18N(`${data.workflowAction.name}.description`)}
      </div>
    </div>
    <Handle
      type='source'
      position={Position.Bottom}
      style={{
        backgroundColor: 'transparent',
        border: '0',
      }}
    />
  </div>
)

export default ActionNode
