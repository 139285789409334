import React, { useState } from 'react'
import API from 'services/api'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'

import { ButtonSmallNarrow } from 'components/common/buttons'
import useApi from 'components/common/hooks/useApi'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import useSystemDefaultValues from 'pages/admin/user_settings/hooks/useSystemDefaultValues'

const I18N = i18nPath('views.settings.default_user_setting')

interface Props {
  name: string
  inputComponent: React.ComponentType<any>
  showDivider?: boolean
}

const Setting = ({
  name,
  inputComponent: InputComponent,
  showDivider = true,
}: Props) => {
  // At this point, the data should be fetched from the API
  // Since we're fetching in UserSettingsPage
  // So there is no need to check if the data is loading
  const { data: systemDefaultValues } = useSystemDefaultValues()
  const camelCasedName = _.camelCase(name.replace('.', '_'))
  const snakedCaseName = _.snakeCase(camelCasedName)
  const defaultValue = systemDefaultValues[camelCasedName]

  const { settings } = useCurrentCompany()
  const [workingCopy, setWorkingCopy] = useState(
    settings.defaultUserSettings[camelCasedName] || defaultValue || {}
  )

  const [updateSetting, { isLoading: isUpdating }] = useApi(API.admin.companyConfigurations.update, {
    toastSuccessMessage: I18NCommon('updated_successfully'),
    toastErrorMessage: I18NCommon('something_went_wrong'),
  })

  const [updateSettingWithoutToast, { isLoading: isUpdatingWithoutToast }] = useApi(
    API.admin.companyConfigurations.update)

  const [updateDefaultUserSettings, { isLoading: isUpdatingDefaultUserSettings }] = useApi(
    API.admin.defaultUserSettings.applySettingsToAllUser, {
      toastSuccessMessage: I18NCommon('updated_successfully'),
      toastErrorMessage: I18NCommon('something_went_wrong'),
    })


  const onUpdateClicked = () => {
    updateSetting({
      id: 'default_user_settings',
      value: {
        // this will merge the default_user_settings updating only the specific "name" key
        [camelCasedName]: workingCopy,
      },
    })
  }
  const onSaveAndUpdateUsersClicked = async () => {
    await updateSettingWithoutToast({
      id: 'default_user_settings',
      value: {
        // this will merge the default_user_settings updating only the specific "name" key
        [camelCasedName]: workingCopy,
      },
    })

    updateDefaultUserSettings([name])
  }

  return (
    <div className='Setting mb-4'>
      <h6>{I18N(`${snakedCaseName}.title`)}</h6>
      <div>{I18N(`${snakedCaseName}.help_text`)}</div>

      <InputComponent value={workingCopy} setValue={setWorkingCopy} name={camelCasedName} />

      <div className='flex mt-2'>
        <ButtonSmallNarrow
          onClick={onUpdateClicked}
          showLoadingSpinner={isUpdating}
          className='mr-2'
        >
          {I18NCommon('save')}
        </ButtonSmallNarrow>

        <ButtonSmallNarrow
          variant='secondary'
          onClick={onSaveAndUpdateUsersClicked}
          showLoadingSpinner={isUpdatingWithoutToast || isUpdatingDefaultUserSettings}
          confirm
          className='mr-2'
        >
          {I18N('save_and_update_all_users')}
        </ButtonSmallNarrow>
      </div>
      {showDivider && <hr />}
    </div>
  )
}

export default Setting
