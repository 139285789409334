import React from 'react'
import { useCustomStyles, useVariables } from 'pages/admin/custom_styles/context'
import { getPropertyListWithValues, sortArrayBy } from 'components/admin/customStyles/utils'
import ColorItem from 'components/admin/customStyles/colorItem'
import Card from 'components/common/card'

const ColorsSection = () => {
  const { handlePropertyChanged } = useCustomStyles()
  const colors = useVariables('colors')
  const colorVariables = getPropertyListWithValues({ variables: colors, type: 'colors' })

  const variablesInColorsSection = [
    'highlightColor',
    'backgroundColor',
    'dangerColor',
    'successColor',
    'textColorPrimary',
    'textColorSecondary',
    'textColorMuted',
    'linkColor',
    'calendarLinkColor',
    'btnPrimaryColor',
    'askAiStartColor',
    'askAiEndColor',
    'btnMutedColor',
  ]

  const sectionVariables = colorVariables.filter(([variableName]) => variablesInColorsSection.includes(variableName))

  sectionVariables.sort(sortArrayBy(variablesInColorsSection))

  return (
    <Card>
      <ColorItem colorVariables={sectionVariables} handlePropertyChanged={handlePropertyChanged} />
    </Card>
  )
}

export default ColorsSection
