import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import useConfigureNavItems from 'components/navbar/hooks/useConfigureNavItems'
import LeftNavItem from 'components/navbar/leftNavItem'
import ToggleMenuButton from 'components/common/toggleMenuButton'
import CdnSvg from 'components/common/cdnSvg'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.navbar')

const ConfigureNavbar = () => {
  const [isOpen, setIsOpen] = useState(true)
  const { configureNavItems } = useConfigureNavItems()
  const location = useLocation()

  const configureActivePaths = useMemo(() => configureNavItems.flatMap(item => [
    ...(item.to ? [item.to] : []),
    ...(item.subItems?.map(subItem => subItem.to).filter(Boolean) || []),
  ]), [])

  const isConfigurePage = configureActivePaths.some(path => location.pathname.startsWith(path))

  if (!isConfigurePage) return null

  return (
    <div className='ConfigureNavbarContainer'>
      <div className={classNames('ConfigureNavbar LeftNavbar', { isOpen })}>
        <div className='d-flex align-items-center mb-2'>
          <CdnSvg src='/images/gearIcon.svg' className='GearIcon mr-2' />
          <h5 className='mb-0'>{I18N('configure')}</h5>
        </div>
        {configureNavItems.map(item => (
          <LeftNavItem key={item.label} {...item} isLeftNavOpen={isOpen} defaultOpen />
        ))}
      </div>
      <ToggleMenuButton
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />
    </div>
  )
}

export default ConfigureNavbar
