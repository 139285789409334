import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'
import TicketStatusLabel from 'components/ticketing/ticketStatusLabel'
import { TicketType } from 'types/ticketing/ticket'
import UserOrUnassigned from 'components/ticketing/userOrUnassigned'
import TicketActivity from 'components/ticketing/view_ticket_sidebar/ticketActivity'
import TicketDropdown from 'components/ticketing/view_ticket_sidebar/ticketDropdown'

type Props = {
  ticket: TicketType
}

const I18N = i18nPath('views.ticketing.view_ticket_sidebar')

const TicketDetailsPanel = ({ ticket }: Props) => (
  <div className='overflow-y-auto'>
    <div className='TicketDetails position-relative d-flex flex-column gap-3'>
      <div className='text-small font-weight-600 mb-2'>{I18N('ticket_details')}</div>
      <div>
        <label className='text-small text-secondary'>{I18N('assigned_to')}</label>
        <div className='bordered py-2 px-3 w-fit'>
          <UserOrUnassigned ticket={ticket} fontWeight='500' />
        </div>
      </div>
      <div>
        <label className='text-small text-secondary'>{I18N('status')}</label>
        <div>
          <TicketStatusLabel status={ticket.status} />
        </div>
      </div>
      <div>
        <label className='text-small text-secondary'>{I18N('id')}</label>
        <div>#{ticket.number}</div>
      </div>
    </div>
    <hr className='w-100' />
    <TicketActivity ticket={ticket} />
    <hr className='w-100' />
  </div>
)

export default TicketDetailsPanel
