import React from 'react'
import { I18NCommon } from 'utils/i18nHelpers'

// red: null or < 0.7
// yellow: 0.7 - 0.85
// green: 0.85 - 1
export const ScoreLabel = ({ score }: { score?: number }) => {
  let label = 'bad'
  if (score) {
    if (score >= 0.85) {
      label = 'good'
    } else if (score >= 0.7) {
      label = 'medium'
    }
  }

  const classes = {
    bad: 'bg-danger',
    medium: 'bg-warning',
    good: 'bg-success',
  }

  return <span className={`badge text-white ${classes[label]}`}>{I18NCommon(label)}</span>
}

export default ScoreLabel
