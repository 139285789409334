import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import groupSlice from 'redux/slices/groups'
import groupMembershipSlice from 'redux/slices/groupMemberships'
import { i18nPath } from 'utils/i18nHelpers'

import { Button, ButtonNarrow } from 'components/common/buttons'
import { DropdownButton, DropdownItem } from 'components/common/dropdownButton'
import BellActive from 'components/icons/bellActive'
import LeaveGroupButtonAndModal from 'components/groups/leaveGroupButtonAndModal'
import BellInactive from 'components/icons/bellInactive'
import Tooltip from 'components/common/tooltip'
import useCurrentUser from 'components/common/hooks/useCurrentUser'

const I18N = i18nPath('views.groups.group_page')

const MembershipActions = ({ group }) => {
  const dispatch = useDispatch()

  const currentCompany = useSelector(({ currentCompany }) => currentCompany)
  const currentUser = useCurrentUser()
  const { isLoading: isLoadingGroup } = useSelector(groupSlice.selectors.getMetaData())
  const { isLoading: isLoadingMembership, notificationSettingIsLoading } = useSelector(
    groupMembershipSlice.selectors.getMetaData()
  )
  const isLoading = isLoadingGroup || isLoadingMembership

  const userMembership = useSelector(groupMembershipSlice.selectors.getUserMembership(currentUser.id, group.id))

  const {
    groupType,
    isCurrentUserMember,
    isCurrentUserFollower,
    isCurrentUserMemberWithNotifications,
    isCurrentUserFollowerWithNotifications,
    permittedActions,
  } = group
  const groupTypeLabels = groupType?.groupTypeLabels || []
  const useGroupTypeLabels = groupType?.useLabelsToCategorizeMembers && groupTypeLabels.length > 0
  const isJoinButtonVisible = permittedActions.join && !isCurrentUserMember && !isCurrentUserFollower
  const isFollowButtonVisible = permittedActions.follow && !isCurrentUserMember && !isCurrentUserFollower
  const isFollowingButtonVisible = isCurrentUserFollower && !isCurrentUserMember
  const isBellIconVisible = currentCompany.teamPostsEnabled && (isCurrentUserMember || isCurrentUserFollower)

  const defaultNotificationsEnabled = isCurrentUserFollowerWithNotifications || isCurrentUserMemberWithNotifications

  const [areNotificationsEnabled, setAreNotificationsEnabled] = useState(defaultNotificationsEnabled)
  const [isBellHovered, setIsBellHovered] = useState(false)

  const isBellActive = (areNotificationsEnabled && !isBellHovered) || (!areNotificationsEnabled && isBellHovered)
  const bellIcon = isBellActive ? <BellActive /> : <BellInactive />

  const handleJoinButtonClick = async (groupTypeLabelId = null) => {
    await dispatch(groupMembershipSlice.asyncActions.addPerson(group, currentUser, groupTypeLabelId))
    dispatch(groupSlice.asyncActions.fetch(group.id))
  }

  const handleFollowButtonClick = async () => {
    await dispatch(groupMembershipSlice.asyncActions.follow(group))
    dispatch(groupSlice.asyncActions.fetch(group.id))
  }

  const handleUnfollowButtonClick = async () => {
    await dispatch(groupMembershipSlice.asyncActions.unfollow(group))
    dispatch(groupSlice.asyncActions.fetch(group.id))
  }

  const handleNotificationsButtonClick = async () => {
    if (areNotificationsEnabled) {
      await dispatch(groupMembershipSlice.asyncActions.disableNotifications(group))
      setAreNotificationsEnabled(false)
      setIsBellHovered(false)
    } else {
      await dispatch(groupMembershipSlice.asyncActions.enableNotifications(group))
      setAreNotificationsEnabled(true)
      setIsBellHovered(false)
    }
  }

  const handleLeaveConfirmation = async () => {
    await dispatch(groupMembershipSlice.asyncActions.removePerson(userMembership))
    dispatch(groupSlice.asyncActions.fetch(group.id))
  }

  return (
    <section className='GroupMembershipActions mb-3'>
      {isJoinButtonVisible && !useGroupTypeLabels && (
        <ButtonNarrow onClick={() => handleJoinButtonClick()} showLoadingSpinner={isLoading}>
          {I18N('join')}
        </ButtonNarrow>
      )}

      {isJoinButtonVisible && useGroupTypeLabels && (
        <DropdownButton title={I18N('join')}>
          {groupTypeLabels.map(label => (
            <DropdownItem key={label.id} onClick={() => handleJoinButtonClick(label.id)} showIconOnHover={false}>
              {label.name}
            </DropdownItem>
          ))}
        </DropdownButton>
      )}

      {isCurrentUserMember && userMembership && (
        <LeaveGroupButtonAndModal
          group={group}
          isLoading={isLoading}
          handleLeaveConfirmation={handleLeaveConfirmation}
        />
      )}

      {isFollowButtonVisible && (
        <ButtonNarrow onClick={handleFollowButtonClick} showLoadingSpinner={isLoading} variant='secondary'>
          {I18N('follow')}
        </ButtonNarrow>
      )}

      {isFollowingButtonVisible && (
        <DropdownButton title={I18N('is_following')}>
          {permittedActions.join && (
            <DropdownItem onClick={() => handleJoinButtonClick()} showIconOnHover={false}>
              {I18N('join_team')}
            </DropdownItem>
          )}
          <DropdownItem onClick={handleUnfollowButtonClick} showIconOnHover={false} className='text-danger'>
            {I18N('unfollow_team')}
          </DropdownItem>
        </DropdownButton>
      )}

      {isBellIconVisible && (
        <Tooltip
          id='group_notifications_id'
          icon={() => (
            <Button
              variant='secondary'
              dataTestId='group-notifications-button'
              className='notifications-button p-2'
              onClick={handleNotificationsButtonClick}
              disabled={notificationSettingIsLoading}
              onMouseEnter={() => setIsBellHovered(true)}
              onMouseLeave={() => setIsBellHovered(false)}
            >
              {bellIcon}
            </Button>
          )}
        >
          {areNotificationsEnabled ? I18N('notifications_disable_tooltip') : I18N('notifications_enable_tooltip')}
        </Tooltip>
      )}
    </section>
  )
}

export default MembershipActions
