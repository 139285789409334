import React, { ReactElement } from 'react'
import { useCustomStyles, useVariables } from 'pages/admin/custom_styles/context'
import { getPropertyListWithValues, sortArrayBy } from 'components/admin/customStyles/utils'
import ColorItem from 'components/admin/customStyles/colorItem'
import Card from 'components/common/card'

const FeedSection = (): ReactElement => {
  const { handlePropertyChanged } = useCustomStyles()
  const colors = useVariables('colors')
  const colorVariables = getPropertyListWithValues({ variables: colors, type: 'colors' })

  const variablesInFirstGroup = [
    'cardBackgroundColor',
    'birthdayCardBackgroundColor',
    'upcomingBirthdayColor',
    'workAnniversaryBackgroundColor',
    'upcomingWorkAnniversaryColor',
    'newEmployeeWelcomeColor',
    'upcomingNewEmployeeWelcomeColor',
    'farewellCardBackgroundColor',
    'achievementCardBackgroundColor',
    'newParentCardBackgroundColor',
    'birthdayCardLabelColor',
    'workAnniversaryLabelColor',
    'newEmployeeWelcomeLabelColor',
    'customCelebrationLabelColor',
  ]

  const variablesInSecondGroup = ['shoutoutCardBackgroundColor', 'awardedBadgeCardHeroColor']
  const sectionVariablesFirstGroup = colorVariables.filter(([name]) => variablesInFirstGroup.includes(name))
  const sectionVariablesSecondGroup = colorVariables.filter(([name]) => variablesInSecondGroup.includes(name))

  sectionVariablesFirstGroup.sort(sortArrayBy(variablesInFirstGroup))
  sectionVariablesSecondGroup.sort(sortArrayBy(variablesInSecondGroup))

  return (
    <Card>
      <ColorItem
        colorVariables={sectionVariablesFirstGroup}
        handlePropertyChanged={handlePropertyChanged}
      />

      <ColorItem
        colorVariables={sectionVariablesSecondGroup}
        handlePropertyChanged={handlePropertyChanged}
      />
    </Card>
  )
}

export default FeedSection
