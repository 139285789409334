import * as _ from 'lodash'
import React, { useCallback, useState } from 'react'

export interface NodeDataType {
  i: number,
  u: string,
  c: number,
  children?: NodeDataType[],
}

interface NodeType {
  children?: NodeType[],
  data: NodeDataType,
}

export const isOrgChartNodeHighlighted = (node: NodeType, selectedUsername: string): boolean => {
  // highlight this node if it's the selected user, or
  // if any of the children are the selected user
  if (node.data.u === selectedUsername) {
    return true
  }

  if (_.isEmpty(node.children)) {
    // not the username, no children, return false
    return false
  }

  const result = node.children!.map(n => isOrgChartNodeHighlighted(n, selectedUsername))
  return result.reduce((acc, value) => acc || value)
}

export const findNodeWithUsername = (node: NodeDataType, username: string): NodeDataType | null => {
  if (node.u === username) {
    return node
  }

  if (_.isEmpty(node.children)) {
    return null
  }

  return _.compact(node.children!.map(c => findNodeWithUsername(c, username)))[0] || null
}

interface TranslateCoordinatesType {
  x: number,
  y: number,
}

interface DimensionsType {
  width: number,
  height: number,
}

export const useCenteredTree = () => {
  const [translate, setTranslate] = useState<TranslateCoordinatesType>({ x: 0, y: 0 })
  const [dimensions, setDimensions] = useState<DimensionsType | null>(null)

  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect()
      // We use 75% of the width so it is slightly off center and uses more of the screen to the left.
      setTranslate({ x: (width * 0.75) / 2, y: height / 2 })
      setDimensions({ width: width * 0.75, height })
    }
  }, [])

  return [translate, dimensions, containerRef]
}

// in SVG, we cannot control the order of elements in CSS (ie. z-index doesn't work)
// we need the highlighted lines/paths between nodes to be drawn on top of the
// ones that aren't highlighted - and the only way to do that is to reorder the lines
// so the highlighted ones come after the non-highlighted ones. (ie. drawn on top)
export const reorderHighlightedLines = () => {
  const frag = document.createDocumentFragment()
  const list = document.querySelector('g.rd3t-g')

  if (list) {
    const lines = list.querySelectorAll('path.rd3t-link')
    const nodes = list.querySelectorAll('g.rd3t-node,g.rd3t-leaf-node')
    const isActiveNode = node => node.classList.value.includes('link-active')
    const sortedLines = Array.from(lines).sort((a, b) => {
      if (isActiveNode(a) && !isActiveNode(b)) {
        return 1
      }

      if (!isActiveNode(a) && isActiveNode(b)) {
        return -1
      }

      return 0
    })
    sortedLines.forEach(line => frag.appendChild(line))
    nodes.forEach(node => frag.appendChild(node))
    list.appendChild(frag)
  }
}

// given a node and username, trim unrelated nodes so that they don't have grandchildren
export const trimOrgChartForUser = (node: NodeDataType, username: string) : NodeDataType | null => {
  if (node.u === username) {
    return node
  }

  const childrenResult = node.children!.map(c => trimOrgChartForUser(c, username))
  const isNodeFoundInChildren = !_.isEmpty(_.compact(childrenResult))

  // if the node is not any of the children, we haven't found it, so we prune the whole thing
  if (!isNodeFoundInChildren) {
    return null
  }

  // the node we're looking for is in children, so for the children that don't have the node
  // just include the child with none of its children
  // (this is how we get the siblings)
  const newChildren: NodeDataType[] = []
  childrenResult.forEach((result, i) => {
    if (_.isEmpty(result)) {
      newChildren.push({ ...node.children![i], children: [] })
    } else {
      newChildren.push(result!)
    }
  })

  return { ...node, children: newChildren }
}
