import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

import Setting from 'pages/admin/user_settings/setting'
import ExternalAlertsInput from 'pages/admin/user_settings/inputs/externalAlertsInput'
import Card from 'components/common/card'

const I18N = i18nPath('views.settings.default_user_setting')

const UserSettingsExternalAlertsTab = () => (
  <Card>
    <h3>{I18N('external_alerts')}</h3>

    <Setting
      name='external_alert_type'
      inputComponent={ExternalAlertsInput}
      showDivider={false}
    />
  </Card>
)

export default UserSettingsExternalAlertsTab
