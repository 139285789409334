import React, { useEffect } from 'react'

import Session from 'services/session'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import DropdownMenuContainer, { NavLinkItem } from 'components/common/dropdownMenuContainer'
import EmployeeThumbnailPhoto from 'components/common/employeeThumbnailPhoto'
import CdnSvg from 'components/common/cdnSvg'
import CardDropdownMenuItem from 'components/common/cardDropdownMenuItem'
import ToggleSwitch from 'components/common/toggleSwitch'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import useNewUiDesign, { NEW_UI_USER_PREVIEW } from 'components/common/hooks/useNewUiDesign'
import { trackAmplitudeEvent } from 'services/tracker'


const PersonIcon = '/images/personIcon.svg'

const I18N = i18nPath('views.navbar')

const ProfileDropdown = ({
  inPreboardingExperience,
}) => {
  const currentCompany = useCurrentCompany()
  const currentUser = useCurrentUser()

  const useNewUi = useNewUiDesign()
  const showTryNewUiToggle = currentCompany.settings.newUi.allowUserPreview && !currentCompany.settings.newUi.enabled

  const [updateUserSetting] = useApi(API.settings.update, {
    onSuccess: () => window.location.reload(),
  })

  const onToggleNewUi = () => {
    const newValue = !useNewUi
    updateUserSetting({
      name: NEW_UI_USER_PREVIEW,
      value: {
        enabled: newValue,
      },
    })

    trackAmplitudeEvent(
      newValue ? 'new_nav_opt_in' : 'new_nav_opt_out',
      { source: 'dropdown' }
    )
  }

  const isInitiallyOpen = localStorage.getItem('openProfileDropdown') === 'true'
  useEffect(() => {
    if (isInitiallyOpen) {
      setTimeout(() => {
        localStorage.removeItem('openProfileDropdown')
      }, 1000)
    }
  }, [])

  const { hasEditableGroups, permissions } = currentUser
  const showAuditLogsLink = permissions.clearyAdmin
  const showMyPostsLink = currentCompany.teamPostsEnabled && hasEditableGroups
  const showMyTasksLink = currentCompany.settings?.journeys?.enabled
    && currentCompany.settings?.journeys?.stepTypes?.tasks?.enabled

  const isAiPlatform = currentCompany.type === 'ai_platform'
  const isTicketingEnabled = currentCompany.settings?.ticketing?.enabled
  const showPlatformAnalytics = !useNewUi && permissions?.analyticsAdmin
  const showHelpLink = Object.entries(permissions).some(([key, value]) => key.includes('Manager') && value === true)

  const handleLogout = (e) => {
    e.preventDefault()
    Session.logout()
  }

  const profileImage = (
    <div className='Navbar-navItemContainer' data-testid='cy_navbar_profile_image'>
      {currentUser.primaryPhotoThumbnailUrl.includes('images/profile_small-missing') ? (
        <CdnSvg className='PersonIcon' src={PersonIcon} />
      ) : (
        <EmployeeThumbnailPhoto className='Navbar-profileImage border-0 ' employee={currentUser} />
      )}
    </div>
  )

  const preboardingUserDropdownLinks = [
    showMyTasksLink && { linkTo: '/my_tasks', title: I18N('my_tasks') },
    { linkTo: '#', title: I18N('logout'), onClick: handleLogout },
  ].filter(Boolean)

  const userDropdownLinks = [
    !isAiPlatform && { linkTo: `/profile/${currentUser.username}`, title: I18N('profile') },
    showMyPostsLink && { linkTo: '/my_posts', title: I18N('my_posts') },
    showMyTasksLink && { linkTo: '/my_tasks', title: I18N('my_tasks') },
    isTicketingEnabled && { linkTo: '/tickets', title: I18NCommon('my_tickets') },
    showPlatformAnalytics && { linkTo: '/platform_analytics', title: I18N('analytics') },
    !isAiPlatform && { linkTo: '/settings', title: I18N('settings') },
    showHelpLink && { linkTo: 'https://gocleary.zendesk.com/hc/en-us', title: I18N('help'), externalUrl: true },
    showAuditLogsLink && { linkTo: '/admin/audit_logs', title: I18N('audit_logs') },
    { linkTo: '#', title: I18N('logout'), onClick: handleLogout },
  ].filter(Boolean)

  const userLinks = () => {
    if (inPreboardingExperience) {
      return preboardingUserDropdownLinks
    }

    return userDropdownLinks
  }

  return (
    <DropdownMenuContainer
      className='Navbar-profileDropdown'
      toggleContent={profileImage}
      dropdownOffsetPx={-1}
      closeMenuOnItemClick
      defaultOpen={isInitiallyOpen}
    >
      {userLinks().map((link, i) => (
        <NavLinkItem key={i} navLink={link} onClick={link.onClick || (() => {})} />
      ))}

      {showTryNewUiToggle && (
        <>
          <div className='w-100 line-break my-1' />
          <CardDropdownMenuItem
            onClick={onToggleNewUi}
            primaryText={(
              <ToggleSwitch
                checked={useNewUi || false}
                text={I18N('try_new_ui')}
                className='w-100 justify-content-between gap-3'
              />
            )}
            className='TryNewUiToggle Navbar-dropdownMenuItem px-3 py-2 w-100'
          />
        </>
      )}

    </DropdownMenuContainer>
  )
}
export default ProfileDropdown
