import React from 'react'
import CollapsibleCard from 'components/common/collapsibleCard'
import ConnectedExternalSource from 'components/admin/integrations/external_sync/connectedExternalSource'
import AddExternalSource from 'components/admin/integrations/external_sync/addExternalSource'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import { i18nPath } from 'utils/i18nHelpers'
import { AvailableExternalSyncSource, ExternalSyncSource } from 'components/admin/integrations/external_sync/types'
import useApi from 'components/common/hooks/useApi'
import useCurrentUser from 'components/common/hooks/useCurrentUser'
import useQueryParams from 'components/common/hooks/useQueryParams'

const I18N = i18nPath('views.integrations_page.external_knowledge_section')

export const sourceHelpText = (source: ExternalSyncSource, currentUser: any) => {
  if (source.user.id === currentUser.id) {
    return `${I18N('document_count_label', { count: source.documentsCount })} ${I18N('click_to_import')}`
  }

  return I18N('document_count_label', { count: source.documentsCount })
}

const ExternalKnowledgeSection = () => {
  const currentUser = useCurrentUser()
  const { expandedTab } = useQueryParams()
  const { data: connectedSources, removeItem } = useFetch<ExternalSyncSource[]>(API.admin.externalSync.sources.fetchAll)
  const {
    data: availableSources,
    callApi: fetchAvailableSources,
  } = useFetch<AvailableExternalSyncSource[]>(API.admin.externalSync.sources.availableTypes)

  const [destroySource] = useApi(API.admin.externalSync.sources.destroy, {
    toastSuccessMessage: I18N('source_destroyed'),
  })

  const onRemove = async (sourceId: string) => {
    await destroySource(sourceId)
    removeItem(sourceId)
    fetchAvailableSources()
  }

  return (
    <CollapsibleCard title={I18N('title')} className='mb-3' initialIsOpen={expandedTab === 'external_knowledge'}>
      <section className='ExternalKnowledgeSection pt-2'>
        <p className='text-secondary text-small'>{I18N('label')}</p>

        {connectedSources && connectedSources.length > 0 && (
          <>
            <p className='mt-1'>{I18N('connected_sources')}</p>

            {connectedSources.map(source => (
              <ConnectedExternalSource
                key={source.id}
                className='mb-4'
                source={source}
                helpText={sourceHelpText(source, currentUser)}
                onRemove={onRemove}
              />
            ))}
          </>
        )}

        {availableSources && availableSources.length > 0 && (
          <>
            <p className='mt-1'>{I18N('available_sources')}</p>

            {availableSources.map(source => (
              <AddExternalSource
                key={source.type}
                className='mb-3'
                availableSource={source}
              />
            ))}
          </>
        )}
      </section>
    </CollapsibleCard>
  )
}

export default ExternalKnowledgeSection
