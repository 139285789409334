import React from 'react'

type DataListProps = {
  data: { dataTitle: React.ReactNode; dataValue: React.ReactNode }[]
}

const DataList = ({ data }: DataListProps) => (
  <div className='d-flex gap-4 mb-4'>
    {data.map((d, index) => (
      <div key={index}>
        <p className='text-small text-primary mb-2 bold'>{d.dataTitle}</p>
        <p className='text-small text-primary'>{d.dataValue}</p>
      </div>
    ))}
  </div>
)

export default DataList
