import AiEvaluation from 'components/analytics/ai_answers/aiEvaluation'
import BackButton from 'components/common/backButton'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import SmartTable from 'components/common/tables/smartTable'
import { formatFloat } from 'components/common/utils'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import AnsweredLabel from 'pages/admin/ai/answeredLabel'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import API from 'services/api'
import { AiEvaluationAnswerType, AiEvaluationRunType } from 'types/ai/evaluation'
import { i18nPath } from 'utils/i18nHelpers'
import ClearyCard from 'components/common/card'
import ScoreLabel from 'pages/admin/ai/runScoreLabel'
import UserLink from 'components/common/userLink'

const I18N = i18nPath('views.ai_evaluation.run')

interface RunInformationProps {
  runId: string
}

const RunInformation = ({ runId }: RunInformationProps) => {
  const { data: run, isLoaded } = useFetch<AiEvaluationRunType>(
    API.admin.ai.evaluation.runs.fetch,
    [runId]
  )

  if (!isLoaded) return <CirclesLoadingIndicator className='TableLoadingIndicator' />
  if (!run) return null

  return (
    <>
      <h5>Description</h5>
      <p>{run.description}</p>

      <div className='mb-4 d-flex gap-3'>
        <ClearyCard className='flex-grow-1'>
          <h5>Score - Conciseness</h5>
          <div className='d-flex align-items-center gap-2'>
            <p className='d-flex align-items-center gap-2'>
              {formatFloat(run.score || 0)}
              <ScoreLabel score={run.score} />
            </p>
            {!!run.concisenessScore && (
              <p className='d-flex align-items-center gap-2'>
                {formatFloat(run.concisenessScore || 0)}
                <ScoreLabel score={run.concisenessScore} />
              </p>
            )}
          </div>
        </ClearyCard>

        <ClearyCard className='flex-grow-1'>
          <h5>Cost</h5>
          <p>${formatFloat(run.totalCost, 6)}</p>
        </ClearyCard>

        <ClearyCard className='flex-grow-1'>
          <h5>Models Pipeline</h5>
          <p className='text-small text-secondary'>
            {Object.entries(run.modelsPipeline).map(([key, value], i) => (
              <React.Fragment key={key}>
                {i > 0 && <br />}
                {`${key}: ${value}`}
              </React.Fragment>
            ))}
          </p>
        </ClearyCard>
      </div>
    </>
  )
}

const AiEvaluationRunPage = () => {
  const { runId } = useParams()
  const {
    data: answers, isLoading, paginationData, callApi, isLoaded,
  } = useFetch(
    params => API.admin.ai.evaluation.answers.fetchAll(runId, params)
  )
  const history = useHistory()

  const columns = [
    {
      header: I18N('table_header.question'),
      col: 'col-question',
      accessor: (answer: AiEvaluationAnswerType) => (
        <div>
          <div>{answer.question.content}</div>
          <div className='mt-2'><UserLink user={answer.question.user} /></div>
          <div className='mt-1 text-secondary text-small'>
            {answer.question.companyLogoUrl ? (
              <img
                src={answer.question.companyLogoUrl}
                alt={answer.question.companyName}
                className='mr-2'
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
            ) : (
              <div>{answer.question.companyName}</div>
            )}
          </div>
        </div>
      ),
    },
    {
      header: I18N('table_header.answer'),
      col: 'col-answer',
      accessor: (answer: AiEvaluationAnswerType) => <AiAnswerContent content={answer.answer} />,
    },
    {
      header: I18N('table_header.evaluation'),
      col: 'col-evaluation',
      accessor: (answer: AiEvaluationAnswerType) => (
        <div>
          <div className='mb-2'>
            Answered: <AnsweredLabel expectedAnswerScore={answer.expectedAnswerScore} />
          </div>
          <div className='mb-2'>
            Cost: ${formatFloat(answer.totalCost, 6)}
          </div>
          <AiEvaluation
            contextRelevanceScore={answer.contextRelevanceScore}
            answerRelevanceScore={answer.answerRelevanceScore}
            faithfulnessScore={answer.faithfulnessScore}
            ragasScore={answer.ragasScore}
            expectedAnswerScore={answer.expectedAnswerScore}
            answerConcisenessScore={answer.answerConcisenessScore}
          />
        </div>
      ),
    },
  ]

  const onRowClick = (answer: AiEvaluationAnswerType) => {
    history.push(`/admin/ai_evaluation/answers/${answer.id}`)
  }

  if (!isLoaded) return <CirclesLoadingIndicator className='TableLoadingIndicator' />

  return (
    <>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('title', { runId })}</h3>
      </header>

      <main className='AdminContent'>
        <BackButton url='/admin/ai_evaluation' className='mb-4' />

        <RunInformation runId={runId} />

        <SmartTable
          isLoading={isLoading}
          LoadingComponent={<CirclesLoadingIndicator className='TableLoadingIndicator' />}
          columns={columns}
          data={answers}
          className='white-bg-table table-with-row-hover'
          showPagination
          page={paginationData.page}
          pages={paginationData.totalPages}
          onPaginationClick={callApi}
          onSortChange={() => {}}
          sortKey='created_at'
          sortedAsc={false}
          onRowClick={onRowClick}
        />
      </main>
    </>
  )
}

export default AiEvaluationRunPage
