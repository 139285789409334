import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import TagsSection from 'components/admin/ticketing/settings/tagsSection'
import removeQueryParam from 'utils/removeQueryParam'
import useQueryParams from 'components/common/hooks/useQueryParams'

const TicketSettings = () => {
  const { settingsSection } = useQueryParams()
  const history = useHistory()

  useEffect(() => () => {
    removeQueryParam(history, 'settingsSection')
  }, [])

  return (
    <div>
      {settingsSection === 'tags' && <TagsSection />}
    </div>
  )
}

export default TicketSettings
