import React, { useState, useMemo } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import ClearyCard from 'components/common/card'
import CollapsableItem from 'components/common/collapsableItem'
import { ButtonSmallNarrow } from 'components/common/buttons'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import { formatFloat } from 'components/common/utils'
import MessageEditor, { MessageType } from './messageEditor'

type Props = {
  llmCall: any
  defaultIsCollapsed: boolean
}

const EditableLlmCall = ({ llmCall, defaultIsCollapsed }: Props) => {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(JSON.stringify(llmCall, null, 2))
  const [response, setResponse] = useState(llmCall.response)
  const [toolCalls, setToolCalls] = useState(llmCall.tool_calls)

  const parsedValue = useMemo(() => {
    try {
      const parsed = JSON.parse(value)

      if (parsed.messages) {
        parsed.messages.forEach((message) => {
          if (Array.isArray(message.content)) {
            message.content = message.content[0].text
          }
        })
      }

      return parsed
    } catch {
      return {}
    }
  }, [value])

  const [callLLM, { isLoading }] = useApi(API.admin.ai.callLLM, {
    onSuccess(data) {
      setToolCalls(data.toolCalls)

      if (typeof data.response === 'object') {
        setResponse(JSON.stringify(data.response, null, 2))
      } else {
        setResponse(data.response)
      }
    },
  })

  const messages = parsedValue.messages

  const setMessage = (index: number, message: MessageType) => {
    setValue((prev) => {
      const parsed = JSON.parse(prev)
      parsed.messages[index] = message
      return JSON.stringify(parsed, null, 2)
    })
  }

  const handleModelChange = (model: string) => {
    setValue((prev) => {
      const parsed = JSON.parse(prev)
      parsed.model = model
      return JSON.stringify(parsed, null, 2)
    })
  }

  const onCallLLM = () => {
    const parsedValue = JSON.parse(value)
    setToolCalls([])
    setResponse('')
    const deepSnakeCase = (obj: any): any => {
      if (Array.isArray(obj)) {
        return obj.map(deepSnakeCase)
      }
      if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
          acc[_.snakeCase(key)] = deepSnakeCase(obj[key])
          return acc
        }, {} as any)
      }
      return obj
    }

    const args = JSON.stringify({
      ...deepSnakeCase(parsedValue),
      type: 'langchain_client',
      method: 'chat',
    })
    callLLM({ args })
  }

  const renderResponse = () => {
    if (Array.isArray(response)) {
      return <pre>{JSON.stringify(response, null, 2)}</pre>
    }

    if (parsedValue.response_format?.type === 'json_schema') {
      return <pre>{response}</pre>
    }

    return <AiAnswerContent content={response} />
  }

  return (
    <ClearyCard className='mb-3'>
      <CollapsableItem
        defaultIsCollapsed={defaultIsCollapsed}
        label={(
          <div className='d-flex gap-4'>
            <div>
              <div className='text-secondary text-small'>Model</div>
              <div className='text-small'>{llmCall.model}</div>
            </div>
            <div>
              <div className='text-secondary text-small'>Prompt tokens - cost</div>
              <div className='text-small'>{llmCall.usage.promptTokens} - ${formatFloat(llmCall.usage.promptCost, 6)}</div>
            </div>
            <div>
              <div className='text-secondary text-small'>Completion tokens - cost</div>
              <div className='text-small'>{llmCall.usage.completionTokens} - ${formatFloat(llmCall.usage.completionCost, 6)}</div>
            </div>
            <div>
              <div className='text-secondary text-small'>Total tokens - cost</div>
              <div className='text-small'>{llmCall.usage.totalTokens} - ${formatFloat(llmCall.usage.totalCost, 6)}</div>
            </div>
          </div>
        )}
      >
        <div className='mb-3'>
          <ButtonSmallNarrow onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? 'Cancel Edit' : 'Edit'}
          </ButtonSmallNarrow>
        </div>

        {isEditing ? (
          <>
            {messages?.length > 0 && (
              <div className='mb-3'>
                <h4>Messages:</h4>
                {messages.map((message, index) => (
                  <MessageEditor
                    key={index}
                    value={message}
                    onChange={message => setMessage(index, message)}
                  />
                ))}
              </div>
            )}

            <div className='mb-3'>
              <h4>Model:</h4>
              <select
                value={parsedValue.model}
                onChange={e => handleModelChange(e.target.value)}
                className='form-select'
              >
                <option value='gpt-4o'>GPT-4o</option>
                <option value='gpt-4o-mini'>GPT-4o Mini</option>
              </select>
            </div>

            {parsedValue.tools && (
              <div className='mb-3'>
                <h4>Tools:</h4>
                <textarea
                  className={classNames('w-100 p-2', { 'is-invalid': typeof parsedValue.tools === 'string' })}
                  value={typeof parsedValue.tools === 'string' ? parsedValue.tools : JSON.stringify(parsedValue.tools, null, 2)}
                  onChange={(e) => {
                    setValue((prev) => {
                      const parsed = JSON.parse(prev)
                      try {
                        parsed.tools = JSON.parse(e.target.value)
                      } catch {
                        parsed.tools = e.target.value
                      }
                      return JSON.stringify(parsed, null, 2)
                    })
                  }}
                  rows={10}
                />
                {typeof parsedValue.tools === 'string' && (
                  <div className='text-danger mb-2'>Error: Tools must be a valid JSON object</div>
                )}
              </div>
            )}

            <div className='mb-3'>
              <h4>Full payload:</h4>
              <textarea
                className='w-100 p-2'
                value={value}
                onChange={e => setValue(e.target.value)}
                rows={10}
              />
            </div>

            <ButtonSmallNarrow onClick={onCallLLM} showLoadingSpinner={isLoading}>
              Run
            </ButtonSmallNarrow>
          </>
        ) : (
          <pre style={{ whiteSpace: 'pre-wrap' }} className='text-small'>
            {JSON.stringify(_.pick(llmCall, ['messages', 'tools', 'response', 'toolCalls']), null, 2)}
          </pre>
        )}

        {!_.isEmpty(response) && isEditing && (
          <div className='mt-4'>
            <h4>Response:</h4>
            {renderResponse()}
          </div>
        )}

        {!_.isEmpty(toolCalls) && isEditing && (
          <div className='mt-4'>
            <h4>Tool Calls:</h4>
            <pre>{JSON.stringify(toolCalls, null, 2)}</pre>
          </div>
        )}
      </CollapsableItem>
    </ClearyCard>
  )
}

export default EditableLlmCall
