import { Button } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import React from 'react'

const FileOutput = ({ field, actionRun }) => (
  <div className='WorkflowRunPage__Output__FileOutput'>
    <Button variant='secondary' size='sm' onClick={() => window.open(actionRun.output[`${field}Url`], '_blank')}>
      <CdnSvg src='/images/fileIcon.svg' className='mr-1' />
      {actionRun.output[`${field}Name`]}
    </Button>
  </div>
)

export default FileOutput
