import { AiMessageType, AiMessageTypeEnum } from 'components/ai/chat/ask_ai_modal/types'
import CdnSvg from 'components/common/cdnSvg'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ask_ai')

const AiMessagePrefix = ({ message }: { message: AiMessageType }) => {
  if (message.type === AiMessageTypeEnum.TICKET_CREATED) {
    return (
      <div className='d-flex gap-3 mb-3'>
        <CdnSvg src='/images/circledSimpleCheck.svg' className='text-success' />
        <div className='text-small text-secondary'>{I18N('ticket_created', { number: message.ticketingTicket?.number })}</div>
      </div>
    )
  }

  return null
}

export default AiMessagePrefix
