import React from 'react'
import fieldFactory from './fields/fieldFactory'

const Field = (props) => {
  const Component = fieldFactory(props.type)

  return <Component {...props} />
}

export default Field
