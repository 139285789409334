import React from 'react'
import TextOutput from './outputs/textOutput'
import FileOutput from './outputs/fileOutput'
import ClearyRichTextOutput from './outputs/clearyRichTextOutput'
import SimpleClearyRichTextOutput from './outputs/simpleClearyRichTextOutput'
import JsonOutput from './outputs/jsonOutput'

const getOutputComponent = (type: string) => {
  const map = {
    text: TextOutput,
    file: FileOutput,
    cleary_rich_text: ClearyRichTextOutput,
    simple_cleary_rich_text: SimpleClearyRichTextOutput,
    json: JsonOutput,
  }

  if (!map[type]) {
    throw new Error(`No output component found for type: ${type}`)
  }

  return map[type]
}

const ActionRunOutput = ({ type, field, actionRun }) => {
  const Component = getOutputComponent(type)

  if (actionRun.status !== 'completed') return null

  return <Component field={field} actionRun={actionRun} />
}

export default ActionRunOutput
