import { createSlice } from '@reduxjs/toolkit'
import build from 'redux-object'

import API from 'services/api'
import entitySlice from 'redux/slices/entities'
import { showToastMessage } from 'redux/slices/toasts'
import { checkForError, getResponseOrThrow } from 'utils/errorHandling'
import queryParamsFromHeaders, { defaultPaginationParams } from 'utils/queryParamsFromHeaders'
import buildByIdOrSlugFromEntitiesStore from 'redux/slices/utils/buildByIdOrSlugFromEntitiesStore'

import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.groups.group_page')

// Must have ID + ONLY fields included on the allowed strong parameters on the backend
export const buildMembershipPayload = groupMembership => (
  {
    ..._.pick(groupMembership, ['id', 'lead', 'role']),
    groupTypeLabelId: groupMembership.groupTypeLabel?.id || null,
    groupId: groupMembership.group.id,
    userId: groupMembership.user.id,
  }
)

export const defaultQueryParams = { ...defaultPaginationParams, perPage: 12, groupTypeLabelId: null }

export const initialState = {
  teamId: null,
  memberUserIds: [],
  membershipIds: [],
  leadUserIds: [],
  followerUserIds: [],
  meta: {
    isLoading: false,
    teamFollowsActionIsLoading: false,
    notificationSettingIsLoading: false,
    queryParams: defaultQueryParams,
  },
}

const groupMembershipSlice = createSlice({
  name: 'groupMemberships',
  initialState,
  reducers: {
    clear(state) {
      state.teamId = null
      state.memberUserIds = []
      state.membershipIds = []
      state.leadUserIds = []
      state.followerUserIds = []
      state.meta.queryParams = { ...defaultQueryParams }
    },
    setIsLoading(state, action) {
      state.meta.isLoading = action.payload
    },
    setTeamId(state, action) {
      state.teamId = action.payload
    },
    addMembershipIds(state, action) {
      state.membershipIds = Array.from(new Set([...state.membershipIds, ...action.payload]))
    },
    removeMembershipId(state, action) {
      const groupMembershipId = action.payload
      state.membershipIds = _.reject(state.membershipIds, id => id === groupMembershipId)
    },
    setMemberUserIds(state, action) {
      if (state.teamId === action.payload.teamId) state.memberUserIds = action.payload.userIds
    },
    addMemberUserIds(state, action) {
      state.memberUserIds = Array.from(new Set([...state.memberUserIds, ...action.payload]))
    },
    removeMemberUserId(state, action) {
      const userId = action.payload
      state.memberUserIds = _.reject(state.memberUserIds, id => id === userId)
    },
    setLeadUserIds(state, action) {
      if (state.teamId === action.payload.teamId) state.leadUserIds = action.payload.userIds
    },
    addLeadUserIds(state, action) {
      state.leadUserIds = Array.from(new Set([...state.leadUserIds, ...action.payload]))
    },
    removeLeadUserId(state, action) {
      const userId = action.payload
      state.leadUserIds = _.reject(state.leadUserIds, id => id === userId)
    },
    setQueryParams(state, action) {
      state.meta.queryParams = action.payload
    },
    setFollowerUserIds(state, action) {
      if (state.teamId === action.payload.teamId) state.followerUserIds = action.payload.userIds
    },
    addFollowerUserIds(state, action) {
      state.followerUserIds = Array.from(new Set([...state.followerUserIds, ...action.payload]))
    },
    removeFollowerUserId(state, action) {
      const userId = action.payload
      state.followerUserIds = _.reject(state.followerUserIds, id => id === userId)
    },
    setTeamFollowsActionIsLoading(state, action) {
      state.meta.teamFollowsActionIsLoading = action.payload
    },
    setNotificationSettingIsLoading(state, action) {
      state.meta.notificationSettingIsLoading = action.payload
    },
  },
})

const asyncActions = {
  fetchAll: (teamIdOrSlug, queryParams = null) => async (dispatch, getState) => {
    const team = buildByIdOrSlugFromEntitiesStore(teamIdOrSlug, 'group', getState())

    if (team?.groupMemberships) {
      const currentMemberIds = team.groupMemberships.map(gm => gm.user?.id)
      dispatch(groupMembershipSlice.actions.setMemberUserIds({ teamId: team.id, userIds: currentMemberIds }))
    } else {
      dispatch(groupMembershipSlice.actions.setIsLoading(true))
    }

    try {
      const response = await API.admin.groupMemberships.fetchAll(teamIdOrSlug, queryParams)
      const userIds = response.data.data.map(groupMembership => groupMembership.relationships.user.data.id)
      const membershipIds = response.data.data.map(groupMembership => groupMembership.id)
      //
      // in case the team isn't already in state, fetch team id from the first membership relationship
      const teamIds = response.data.data.map(groupMembership => groupMembership.relationships.group.data.id)
      const teamId = teamIds[0]
      if (teamId) {
        dispatch(groupMembershipSlice.actions.setTeamId(teamId))
        dispatch(groupMembershipSlice.actions.setMemberUserIds({ teamId, userIds }))
      }

      dispatch(groupMembershipSlice.actions.addMembershipIds(membershipIds))
      dispatch(entitySlice.actions.add({ data: response.data }))
      if (queryParams) {
        const newQueryParams = { ...queryParams, ...queryParamsFromHeaders(response) }
        dispatch(groupMembershipSlice.actions.setQueryParams(newQueryParams))
      }
    } finally {
      dispatch(groupMembershipSlice.actions.setIsLoading(false))
    }
  },
  addPerson: (team, user, groupTypeLabelId = null, lead = false, role = '') => async (dispatch) => {
    dispatch(groupMembershipSlice.actions.setIsLoading(true))
    try {
      const response = await API.admin.groupMemberships.create(
        {
          groupId: team.id,
          userId: user.id,
          groupTypeLabelId,
          role,
          lead,
        },
        true
      )
      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(groupMembershipSlice.actions.addMemberUserIds([user.id]))
      dispatch(groupMembershipSlice.actions.addMembershipIds([response.data.data.id]))
      dispatch(showToastMessage({ message: I18N('team_members_updated'), type: 'success' }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))
      dispatch(showToastMessage({ message: error, type: 'error' }))
    } finally {
      dispatch(groupMembershipSlice.actions.setIsLoading(false))
    }
  },

  removePerson: groupMembership => async (dispatch, getState) => {
    dispatch(groupMembershipSlice.actions.setIsLoading(true))

    try {
      await API.admin.groupMemberships.destroy(groupMembership)
      dispatch(groupMembershipSlice.actions.removeMemberUserId(groupMembership.user.id))
      dispatch(groupMembershipSlice.actions.removeMembershipId(groupMembership.id))
      dispatch(showToastMessage({ message: I18N('team_members_updated'), type: 'success' }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))
      dispatch(showToastMessage({ message: error, type: 'error' }))
    } finally {
      dispatch(groupMembershipSlice.actions.setIsLoading(false))
    }
  },

  update: (groupMembership, onSuccess = () => {}) => async (dispatch) => {
    dispatch(groupMembershipSlice.actions.setIsLoading(true))
    try {
      const response = await API.admin.groupMemberships.update(groupMembership)
      dispatch(entitySlice.actions.add({ data: response.data }))
      dispatch(showToastMessage({ message: I18N('team_members_updated'), type: 'success' }))
      onSuccess()
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))
      dispatch(showToastMessage({ message: error, type: 'error' }))
    } finally {
      dispatch(groupMembershipSlice.actions.setIsLoading(false))
    }
  },

  unfollow: team => async (dispatch, getState) => {
    const currentUser = getState().currentUser
    dispatch(groupMembershipSlice.actions.setTeamFollowsActionIsLoading(true))
    try {
      const response = await API.groups.follows.unfollow(team.id)
      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(groupMembershipSlice.actions.removeFollowerUserId(currentUser.id))
      dispatch(showToastMessage({ message: I18N('you_are_not_following'), type: 'success' }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))
      dispatch(showToastMessage({ message: error, type: 'error' }))
    } finally {
      dispatch(groupMembershipSlice.actions.setTeamFollowsActionIsLoading(false))
    }
  },

  follow: team => async (dispatch, getState) => {
    const currentUser = getState().currentUser
    dispatch(groupMembershipSlice.actions.setTeamFollowsActionIsLoading(true))
    try {
      const response = await API.groups.follows.follow(team.id)
      dispatch(entitySlice.actions.update({ data: response.data }))
      dispatch(groupMembershipSlice.actions.addFollowerUserIds([currentUser.id]))
      dispatch(showToastMessage({ message: I18N('you_are_following'), type: 'success' }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))
      dispatch(showToastMessage({ message: error, type: 'error' }))
    } finally {
      dispatch(groupMembershipSlice.actions.setTeamFollowsActionIsLoading(false))
    }
  },

  updateFollowers: (team, userIds, groupIds) => async (dispatch, getState) => {
    try {
      const response = await API.groups.follows.bulkUpdate(team.id, userIds, groupIds)
      dispatch(entitySlice.actions.update({ data: response.data }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))
      dispatch(showToastMessage({ message: error, type: 'error' }))
    }
  },

  disableNotifications: team => async (dispatch) => {
    dispatch(groupMembershipSlice.actions.setNotificationSettingIsLoading(true))
    try {
      await API.groups.notifications.disable(team.id)
      dispatch(showToastMessage({ message: I18N('notifications_disabled'), type: 'success' }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))
      dispatch(showToastMessage({ message: error, type: 'error' }))
    } finally {
      dispatch(groupMembershipSlice.actions.setNotificationSettingIsLoading(false))
    }
  },

  enableNotifications: team => async (dispatch) => {
    dispatch(groupMembershipSlice.actions.setNotificationSettingIsLoading(true))
    try {
      await API.groups.notifications.enable(team.id)
      dispatch(showToastMessage({ message: I18N('notifications_enabled'), type: 'success' }))
    } catch (e) {
      const { error } = checkForError(getResponseOrThrow(e))
      dispatch(showToastMessage({ message: error, type: 'error' }))
    } finally {
      dispatch(groupMembershipSlice.actions.setNotificationSettingIsLoading(false))
    }
  },
}

const selectors = {
  getMetaData: () => state => state.groupMemberships.meta,

  getMembers: () => state => state.groupMemberships.memberUserIds.map(id => build(state.entities, 'user', id)).filter(Boolean),

  getLeads: () => state => state.groupMemberships.leadUserIds.map(id => build(state.entities, 'user', id)).filter(Boolean),

  getFollowers: () => state => state.groupMemberships.followerUserIds.map(id => build(state.entities, 'user', id)).filter(Boolean),

  getMemberships: () => state => state.groupMemberships.membershipIds.map(id => build(state.entities, 'groupMembership', id)).filter(Boolean),

  getUserMembership: (userId, groupId) => (state) => {
    const memberships = state.groupMemberships.membershipIds.map(id => build(state.entities, 'groupMembership', id)).filter(Boolean)

    return memberships.find(m => m.user.id === userId && m.group.id === groupId)
  },
}

export default {
  ...groupMembershipSlice,
  asyncActions,
  selectors,
}
