import React from 'react'
import CdnSvg from 'components/common/cdnSvg'

const ACTION_ICONS = {
  ticket_message: '/images/tiptap/pencil.svg',
  generate_pdf: '/images/illustrations/pdfIcon.svg',
  hris_salary_information: '/images/integrations/workdayLogo.svg',
  http_request: '/images/globeIcon.svg',
  context: '/images/globeIcon.svg',
  search_and_respond: '/images/chatInfoIcon.svg',
}

const ActionIcon = ({ action }) => <CdnSvg src={ACTION_ICONS[action.name]} />

export default ActionIcon
