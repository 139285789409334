import { AssistantAnswerType } from 'types/ai/assistantAnswer'
import TargetingRulesType from 'types/audience/targetingRules'
import { CompanyType } from 'types/company'
import { TicketType } from 'types/ticketing/ticket'
import { SimpleUserType } from 'types/user'

export enum AiMessageTypeEnum {
  REGULAR = 'RegularMessage',
  OFFER_TICKET_ESCALATION = 'OfferTicketEscalationMessage',
  TICKET_FORM = 'TicketFormMessage',
  TICKET_CREATED = 'TicketCreatedMessage',
}

export interface AiMessageType {
  id: string
  order: number
  content: string
  role: 'assistant'
  aiAssistantAnswer?: AssistantAnswerType
  ticketingTicket?: TicketType
  createdAt: string
  type: AiMessageTypeEnum
}

export interface GeneratingAiMessageType {
  id: string
  content?: string
  message?: string
  role: 'assistant'
}

export interface UserMessageType {
  id: string
  order: number
  content: string
  role: 'user'
  user: SimpleUserType
  createdAt: string
}

export enum AskAiTab {
  CHAT = 'chat',
  HISTORY = 'history',
}

export type ConversationType = {
  id: string
  title: string
  createdAt: string
  user?: SimpleUserType
  company?: CompanyType
}

export type PromptCollectionType = {
  id: string
  name: string
  prompts: string[]
  targetingRules: TargetingRulesType
}

export type MessageType = AiMessageType | GeneratingAiMessageType | UserMessageType

export enum NewConversationOrigin {
  ASK_AI_BUTTON = 'ask_ai_button',
  NEW_CONVERSATION_BUTTON = 'new_conversation_button',
  SEARCH_RESULT_PROMPT = 'search_result_prompt',
}

export type NewConversationTrackingType = {
  origin: NewConversationOrigin
  searchQuery?: string
  prompt?: string
}
