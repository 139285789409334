import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import CollapsibleCard from 'components/common/collapsibleCard'
import IntegrationCard from 'components/admin/integrations/integrationCard'
import itAdminSlice from 'redux/slices/itAdmin'
import useCurrentCompany from 'components/common/hooks/useCurrentCompany'
import AtlassianUninstallationComponent from 'components/admin/integrations/atlassianUninstallationComponent'
import ReactSelect from 'components/common/react_select'
import { ButtonSmallNarrow } from 'components/common/buttons'
import BloomfireInstallationComponent from 'components/admin/integrations/bloomfireInstallationComponent'
import CdnSvg from 'components/common/cdnSvg'
import { Link } from 'react-router-dom'
import useIsCleary from 'components/common/hooks/useIsCleary'
import useQueryParams from 'components/common/hooks/useQueryParams'

const I18N = i18nPath('views.integrations_page.ticketing_section')

const zohoLogoPath = '/images/integrations/zohoLogo.svg'

const InstallationComponent = ({ installUrl, testId }) => (
  <a className='btn btn-primary btn-sm btn-narrow my-1' href={installUrl} data-testid={testId}>
    {I18NCommon('install')}
  </a>
)

const TicketingSection = () => {
  const dispatch = useDispatch()
  const isCleary = useIsCleary()
  const { opened } = useQueryParams()

  const { zohoEnabled, zohoInstallUrl } = useSelector(itAdminSlice.selectors.getData())

  const uninstallIntegration = (name) => {
    dispatch(itAdminSlice.asyncActions.admin.uninstallIntegration(name))
  }

  if (!isCleary) return null

  return (
    <CollapsibleCard title={I18N('ticketing')} className='mb-3' initialIsOpen={opened === 'ticketing'}>
      <section className='SearchSection mb-3'>
        <IntegrationCard
          id='zoho'
          logo={<CdnSvg src={zohoLogoPath} />}
          isInstalled={zohoEnabled}
          installationComponent={<InstallationComponent installUrl={zohoInstallUrl} testId='zoho-link' />}
          onUninstall={() => uninstallIntegration('zoho')}
        />
      </section>
    </CollapsibleCard>
  )
}

export default TicketingSection
