import React, { useState } from 'react'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import { Button } from 'components/common/buttons'
import { AiEvaluationRunType } from 'types/ai/evaluation'

type RunFormData = Partial<AiEvaluationRunType> & {
  jobsCount: number
}

type RunFormProps = {
  onSubmit: (data: RunFormData) => void
  isSaving: boolean
  initialWorkingCopy?: RunFormData
}

const defaultWorkingCopy: RunFormData = {
  description: '',
  skipRagasScore: true,
  jobsCount: 1,
  modelsPipeline: {
    convertQueryToQuestion: 'gpt-4o-mini',
    extractTermsFromQuestion: 'gpt-4o-mini',
    generateAnswer: 'gpt-4o',
    agentRouter: 'gpt-4o',
    userAssistant: 'gpt-4o-mini',
  },
}

const RunForm = ({
  onSubmit, isSaving, initialWorkingCopy,
}: RunFormProps) => {
  const [workingCopy, setWorkingCopy] = useState<RunFormData>(
    initialWorkingCopy || defaultWorkingCopy
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      name, value, type, checked,
    } = e.target
    setWorkingCopy(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleModelChange = (model: string, type: string) => {
    setWorkingCopy(prev => ({
      ...prev,
      modelsPipeline: {
        ...prev.modelsPipeline,
        [type]: model,
      },
    }))
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(workingCopy)
  }

  const isValid = workingCopy.description?.trim() !== ''

  return (
    <form onSubmit={handleSubmit} className='RunForm'>
      <LabeledFormFieldContainer
        label='Description'
        isRequired
      >
        <input
          name='description'
          value={workingCopy.description}
          onChange={handleChange}
          placeholder='Enter run description'
          className='form-control'
        />
      </LabeledFormFieldContainer>

      <LabeledFormFieldContainer label='Skip Ragas Score'>
        <input
          type='checkbox'
          style={{ height: '1rem', width: 'auto' }}
          name='skipRagasScore'
          checked={workingCopy.skipRagasScore}
          onChange={handleChange}
        />
      </LabeledFormFieldContainer>

      <LabeledFormFieldContainer label='Parallel Jobs Count'>
        <input
          type='number'
          name='jobsCount'
          value={workingCopy.jobsCount}
          onChange={handleChange}
          min={1}
          max={30}
          className='form-control'
        />
      </LabeledFormFieldContainer>

      <h4 className='mt-4'>Models Pipeline</h4>
      {Object.keys(workingCopy.modelsPipeline || {}).map(type => (
        <LabeledFormFieldContainer label={type} key={type}>
          <select
            value={workingCopy.modelsPipeline?.[type]}
            onChange={e => handleModelChange(e.target.value, type)}
            className='form-select ml-2'
          >
            <option value='gpt-4o'>GPT-4o</option>
            <option value='gpt-4o-mini'>GPT-4o Mini</option>
          </select>
        </LabeledFormFieldContainer>
      ))}

      <Button
        type='submit'
        disabled={isSaving || !isValid}
        showLoadingSpinner={isSaving}
      >
        {isSaving ? 'Creating...' : 'Create Run'}
      </Button>
    </form>
  )
}

export default RunForm
